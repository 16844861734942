import React, {useEffect, useState, useRef, useCallback} from 'react';
import {PfButton} from '@profabric/react-components';

import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import i18n from '@app/utils/i18n';
import {ContentHeader, MenuItem} from '@components';

import {useSelector} from 'react-redux';
import {FullScreen, useFullScreenHandle} from 'react-full-screen';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faArrowsAlt,
  faExpandArrowsAlt
} from '@fortawesome/free-solid-svg-icons';

import BasicSingle from '@app/pages/common/selectBox/BasicSingle';
import Multi from '@app/pages/common/selectBox/Multi';
import {toast} from 'react-toastify';
import Input from './Input';

import InputReal from './InputReal';
import './Cmpc100i.css';

import {getAllMenu, updateMenuFormJson} from '../../../utils/APIUtils';
// import SelectInput from '@material-ui/core/Select/SelectInput';

export function i18f(m: string) {
  const {t} = i18n;
  return t(m);
}

const Cmpc100i = () => {
  const user = useSelector((state: any) => state.auth.currentUser);
  const [values, setValues] = useState({});

  useEffect(() => {
    //2 parameter is program eng name
    getAllMenu(user, '')
        .then((response) => {
          const arr = response.data;
          console.log(
              'Cmpc100i_getAllMenu_response_data=====================================',
              response.data
          );
          setSelectOptions(response.data);
        })
        .catch((error) => {
          if (error.status === 401) {
            // console.error('Error loading sidebar menu!');
          }
        });
  }, [values]);

  const [selectOptions, setSelectOptions] = useState([]);

  const {state} = useLocation();
  const title = i18n.t(`menusidebar.label.${state.name}`);
  const [formValues, setFormValues] = useState([]);

  console.log( 'PAGE_LOAD=============',formValues, ' state========',state );
  // const [movePage, setMovePage] = useState(false);
  const [toggle, setToggle] = useState(false);
  const inputRef = useRef();
  const selectRef = useRef();
  const selectMenuRef = useRef(null);

  //drag start
  const dragItem = useRef();
  const dragOverItem = useRef();
  const dragStart = (e, position) => {
    dragItem.current = position;
    console.log(e.target.innerHTML);
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    console.log(e.target.innerHTML);
  };

  const drop = (e) => {
    const copyListItems = [...formValues];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setFormValues(copyListItems);
  };
  //drag end


  let navigate = useNavigate();
  const saveHandleSubmit = (e: {preventDefault: () => void}) => {
    e.preventDefault();

    let menuId = '';
    if (selectMenuRef.current) {
      menuId = selectMenuRef.current.props.value[0].menu_nm_eng;

      const fv = JSON.stringify(formValues);

      updateMenuFormJson(menuId, fv, user)
          .then((response) => {
            const arr = response.data;
            toast.success('저장 하였습니다.');

            navigate('/CM/PC/CMPC100I',{
              state: {...state}
            });

          })
          .catch((error) => {
            if (error.status === 401) {
            }
            toast.error('저장에 실패 하였습니다.');
          });
    }
  };

  function isJsonString(str) {
    try {
      if (str === '') return false;

      JSON.parse(str);
    } catch (e) {
      // console.log('str_is_not_jsonFormat__', str, e);
      return false;
    }
    return true;
  }


  let lastUpdate;
  const onChangeForm = (e: any, index: number) => {
    // console.log('onChangeForm_e____________', e, 'index___', index);

    lastUpdate = Date.now();
    const timer = setTimeout(function () {
      const diff = Date.now() - lastUpdate;

      if (diff > 1000) {
        let values = null;
        values = [...formValues];

        if (e.target) {
          console.log(' e.target.value___', e.target.value);
          if (e.target.value) {
            values[index].value = e.target.value;
          } else {
            values[index].value = '';
          }
        }

/*
        const numAscending = [...values].sort((a, b) => {
          try {
            let bb = {order: 0};
            let aa = {order: 0};
            if (a.value === '') {
              aa.order = 0;
            } else if (a.value.includes('gridId')) {
              aa.order = eval('(' + a.value + ')').order;
            } else if (isJsonString(a.value)) {
              aa = JSON.parse(a.value);
            } else {
              // console.log('a.value------------------------', a.value);
            }
            if (b.value === '') {
              bb.order = 0;
            } else if (b.value.includes('gridId')) {
              bb.order = eval('(' + b.value + ')').order;
            } else if (isJsonString(b.value)) {
              bb = JSON.parse(b.value);
              // console.log('bb------------------------', bb);
            } else {
              // console.log('b.value------------------------', b.value);
            }

            // console.log('aa.order------', aa.order, 'bb.order------', bb.order);
            if (aa.order > bb.order) return 1;
            if (aa.order === bb.order) return 0;
            if (aa.order < bb.order) return -1;
          } catch (err) {
            console.log('errr=', err);
            toast.error(`Cmpc100i_ERR=${err}${a.value}`);
          }
        });
*/

         setFormValues(values);

      }
    }, 1000);

    return () => {
      clearTimeout(timer);

      console.log('_________clearTimeout_Formsortinig_____________');
    };
  };

  const onChangeFormReal = (e: any) => {
    console.log(' onChangeFormReal___e.target______________', e);

    if ((e && e.id !== undefined) || (e.target && e.target.id !== undefined)) {
      const name = e.id !== undefined? e.id : e.target.id;
      const value = e.value !== undefined ? e.value: e.target.value;

      setValues({
        ...values,
        [name]: value
      });

      console.log('valuesonChangeFormReal========', values);
    }

  };

  const handleChange = (e: any, index: number) => {
    if (e.form_json) setFormValues(eval(e.form_json));
    else setFormValues([]);
  };

  const handleAddField = (e: {preventDefault: () => void}) => {
    e.preventDefault();

    const values = [...formValues];
    values.push({
      label: inputRef.current.value || 'label',
      type: selectRef.current.value || 'text',
      value: ''
    });
    // values.sort((a, b) => (a.order > b.order ? 1 : -1));
    setFormValues(values);
    setToggle(false);
  };

  const addBtnClick = (e: {preventDefault: () => void}) => {
    e.preventDefault();
    setToggle(true);
  };

  const handleDeleteField = (e: any, index: number) => {
    const values = [...formValues];
    values.splice(index, 1);
    setFormValues(values);
  };

  const screen10 = useFullScreenHandle();
  const screen20 = useFullScreenHandle();

  const reportChange = useCallback((state, handle) => {
    if (handle === screen10) {
      console.log('Screen 1 went to state= ', state, ' handle=', handle);
    }
    if (handle === screen20) {
      console.log('Screen 2 went to state= ', state, ' handle=', handle);
    }
  }, [screen10, screen20]);


  return (

      <div  >
        <ContentHeader title={title} />
        <section className="content">
          <div className="card-deck container-fluid ">


            <div className="card col-sm-6"  style={{height: 800 + 'em'}}>
              <div className="card-header">
                <h3 className="card-title">Title</h3>

                <div className="card-tools">
                  <button
                      type="button"
                      className="btn btn-tool"
                      title="Expand screen"
                      onClick={screen10.enter}
                  >
                    <FontAwesomeIcon icon={faExpandArrowsAlt} />
                  </button>
                </div>
              </div>
              <div className="card-body">


                <FullScreen handle={screen10}  onChange={reportChange}>
                  {screen10.active && (
                      <button type="button" onClick={screen10.exit}>
                        <FontAwesomeIcon icon={faArrowsAlt} />
                      </button>
                  )}

                  <form className="form-horizontal">
                    <div className="form-group">
                      <div className="input-group">
                        <label htmlFor="Name" className="col-sm-2 col-form-label">
                          Menu Name
                        </label>
                        <div className="col-sm-9">
                          <BasicSingle
                              options={selectOptions}
                              handleChange={handleChange}
                              defaultValue=""
                              selectMenuRef={selectMenuRef}
                          />

                        </div>
                      </div>
                    </div>

                    {
                      formValues.map((obj, index) => (
                          <Input
                              key={crypto.randomUUID()}
                              objValue={obj}
                              onChange={onChangeForm}
                              index={index}
                              deleteField={handleDeleteField}

                              draggable
                              onDragStart={(e) => dragStart(e, index)}
                              onDragEnter={(e) => dragEnter(e, index)}
                              onDragEnd={drop}
                              onDragOver={(e) => e.preventDefault()}
                          />
                      ))}

                    {!toggle ? (
                        <div className="offset-sm-6">
                          <button
                              type="button"
                              className="btn btn-primary"
                              onClick={addBtnClick}
                          >
                            Add new
                          </button>
                        </div>
                    ) : (
                        <div className="dialog-box">
                          <input type="text" placeholder="label" ref={inputRef} />
                          <select ref={selectRef} className="form-control">
                            <option value="approval">approval</option>
                            <option value="button">button</option>
                            <option value="cardProps">cardProps</option>
                            <option value="calendar">calendar</option>
                            <option value="comment">comment</option>
                            <option value="date">date</option>
                            <option value="dept">dept</option>
                            <option value="deptMulti">deptMulti</option>
                            <option value="editor">editor</option>
                            <option value="email">email</option>
                            <option value="file">file</option>
                            <option value="grid">grid</option>
                            <option value="hidden">hidden</option>
                            <option value="includePage">includePage</option>
                            <option value="number">number</option>
                            <option value="password">password</option>
                            <option value="radio">radio</option>
                            <option value="selectSingle">selectSingle</option>
                            <option value="selectMulti">SelectMulti</option>
                            <option value="taskGantt">taskGantt</option>
                            <option value="text">text</option>
                            <option value="textarea">textarea</option>
                            <option value="user">user</option>
                          </select>

                          <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleAddField}
                          >
                            Add
                          </button>
                        </div>
                    )}

                    <div className="offset-sm-10 col-sm-1">
                      <button
                          type="submit"
                          onClick={saveHandleSubmit}
                          className="btn btn-danger"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </FullScreen>

              </div>
            </div>

            <div className="card col-sm-6">
              <div className="card-header">
                <h3 className="card-title">View</h3>
                <div className="card-tools">
                  <button
                      type="button"
                      className="btn btn-tool"
                      title="Expand screen"
                      onClick={screen20.enter}
                  >
                    <FontAwesomeIcon icon={faExpandArrowsAlt} />
                  </button>
                </div>
              </div>


              <div className="card-body">
                <FullScreen handle={screen20}  onChange={reportChange}>
                  {screen20.active && (
                      <button type="button" onClick={screen20.exit}>
                        <FontAwesomeIcon icon={faArrowsAlt} />
                      </button>
                  )}

                  {formValues.map((obj, index) => (
                      <InputReal
                        key={index}
                        objValue={obj}
                        handleChange={onChangeFormReal}
                        index={index}
                        deleteField={handleDeleteField} dbValue={undefined}                    
                        />
                  ))}
                </FullScreen>

              </div>
            </div>
          </div>
        </section>
      </div>
  );
};
export default Cmpc100i;

export function getMultiSelectUrl() {
}


export function gw(m: any) {

}

function customRowNumber() {

}


function setAppvOpt(elem){

}