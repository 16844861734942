import React, {
    useImperativeHandle,
    forwardRef,
    useState,
    useEffect,
    useRef,
    useCallback, ChangeEvent
} from 'react';
import * as XLSX from 'xlsx';

import {useNavigate, Link} from 'react-router-dom';
import {useFormContext} from 'react-hook-form';
import i18n from '@app/utils/i18n';
import readXlsxFile from 'read-excel-file';

import {toast} from 'react-toastify';
import {API_BASE_URL} from '@app/constants';
import useDidMountEffect from '@app/pages/common/useDidMountEffect';
import {ErrorMessage} from '../ErrorMessage';
import {CustomInputProps} from '../inputs/types';
import './jqGrid.css';
import {gridSearch, makeApproval} from '../../../utils/APIUtils';
import '../../../../public/common';
import {useScript} from 'usehooks-ts';
import {useSelector} from "react-redux";
import {useWindowSize} from "@app/hooks/useWindowSize";

let $ = null;

type jqItem = {
    jqItems?: Array<jqItem>;
};

let lastsel: any;
let url: any;
let postParams: any;
let $grid: any;
let $gridPager: any;
let gridPager: any;
let gridWrapper: any;
let handleChange: any;
let readonly: any;

/*

export const JqGridForm = ({
  type,
  label,
  dbValue,
  ...props
}: CustomInputProps) => {
  const {
    register,
    formState: {errors}
  } = useFormContext();
*/


export const JqGridForm = forwardRef((props, ref) => {

        const user = useSelector((state: any) => state.auth.currentUser);
        const windowSize = useWindowSize();
        console.log('JqGridForm_props####################', props);
        const label = props.label;
        const dbValue = props.dbValue;
    readonly
        = props.readonly;

        const navigate = useNavigate();

        // const[compoTbl, setCompoTbl] = useState();
        const [postParams, setPostParams] = useState(props.p || '');

        const [defaultValue, setDefaultValue] = useState([]);
        const [upperCode, setUpperCode] = useState();

        let tmpWidth = '';
        const [commonModuleOption, SetCommonModuleOption] = useState({
            colModel: [{}]
        });

        useEffect(() => {
            $ = window.$;
        });

        let gridInfo = props.gridInfo === "" ? '{"value":[{"url":"", "edit":false, "del":false}]}' : props.gridInfo;
        // let gridInfo = props.gridInfo === "" ? '': props.gridInfo;
        let jg = "";

        try {
            // useEffect(() => {
            console.log('gridInfo0______________', gridInfo);
            // if(gridInfo ==='') return;

            gridInfo = eval('(' + gridInfo + ')');
            jg = gridInfo === undefined ? "" : gridInfo;
            jg.url = jg.url === null ? '' : jg.url;
            // });

        } catch (e) {
            console.log('__________gridInfo_IsNotJsonFormat________________', e);
        }

        const appvongoing = props.appvongoing;
        const role1_cd = user.role1_cd;
        // const role1_cd = props.p.userInfo.role1_cd;

        if (appvongoing || readonly) {
            jg.edit = false;
            jg.add = false;
            jg.del = false;
            jg.search = false;

        } else if (role1_cd === "USER_ROLE_SYS_ADMIN") {
            jg.edit = jg && jg.edit === true ? true : false;
            jg.add = jg && jg.add === true ? true : false;
            jg.del = jg && jg.del === true ? true : false;
            jg.search = jg && jg.search === true ? true : false;
        }

        const [colModel, setColModel] = useState(jg.colModel);
        const [gridNum, setGridNum] = useState(jg.gridId);
        const constGridId = useCallback(() => setGridNum(jg.gridId), [jg.gridId]);


        useEffect(() => {
            constGridId();
        }, [constGridId]);

        const constDefaultValue = useCallback((defaultValue) => {
            // console.log('constDefaultValue---------------', defaultValue);
            setDefaultValue(defaultValue);
        }, [props.defaultValue]);

        useEffect(() => {
            if (!(
                props.defaultValue === undefined
                || props.defaultValue === []
                || props.defaultValue === "")) {
                try {
                    const jv = JSON.parse(props.defaultValue);
                    // const jv = eval(props.defaultValue);
                    if (gridNum !== 'grid1') {
                        constDefaultValue(jv);
                    }
                } catch (e) {
                    // console.log('---------------CONSTDEFAULTVALUE_EXCEPTION----------------', e, props.defaultValue);
                    constDefaultValue(props.defaultValue);
                }
            } else {
                // console.log('---------------constDefaultValue_empty----------------', gridNum, props.defaultValue);
                constDefaultValue([]);
            }
        }, [constDefaultValue]);

        gridPager = `${gridNum}Pager`;
        gridWrapper = `${gridNum}_wrapper`;
        handleChange = props.handleChange;

        useImperativeHandle(ref, () => ({
            async setFilter() {
                setPostParams(props.p);
                const gobj = await gridInit(gridNum);
                await gridSearchData(gridNum);
            }
        }));

        const id = `${label}`;
        const [rowDatas, setRowDatas] = useState([]);

        useDidMountEffect(() => {
            if (props.handleChange) {
                console.log("grid_handleChange_______", label, rowDatas);
                handleChange({id: label, value: rowDatas});
            }
        }, [rowDatas]);

        useEffect(() => {
            if (jg.beforeProcessing !== undefined) {
                if (jg.beforeProcessing.action == 'getCode') {
                    const clm_pty = $(`#${gridNum}`).jqGrid('getColProp', jg.beforeProcessing.column);
                    clm_pty.edittype = 'select';
                    clm_pty.formatter = 'select';
                    clm_pty.editoptions = {value: "1:dummy1;2:dummy1"};

                    getCodeString({"upper_code_cd": jg.beforeProcessing.upper_code_cd, "corp_cd": "0"})
                        .then((data) => {
                            setUpperCode(data);
                        })
                        .catch((err) => {
                            console.log("operation failed, error: ", err)
                        })
                }
            }

        }, [gridNum]);

        console.log("defaultValue_length__", defaultValue.length, " rowDatas.length_____", rowDatas.length, " postParams_____", postParams);
        useEffect(() => {
            setPostParams(props.p);

            //need to initialze in compo setup
            if (defaultValue.length === 0 && rowDatas.length === 0) {
                gridInit(gridNum);
            }

            const prog_id = postParams === undefined ? "" : postParams.prog_id;

            if ((
                defaultValue.length > 0 && rowDatas.length === 0 &&  //compo setup
                gridInfo !== '' && gridNum !== 'grid1' && prog_id !== 'MAIN' ||    //double click value will change its value
                defaultValue.length > 0 && rowDatas.length === 0) ||
                (defaultValue.length > 0 && jg.editModal !== undefined) // pjrg200 proj gantt schedule chart
            ) {
                setGridDataNoReload(defaultValue);
            } else if (rowDatas.length !== 0) {
                setGridDataNoReload(rowDatas);
            } else {
                gridSearchData(gridNum);
            }
        }, [gridNum, rowDatas, defaultValue, windowSize]);

        async function setGridDataNoReload(rows: any) {
            console.log("setGridDataNoReload_______________", rows);
            await gridInit(gridNum);
            await $(`#${gridNum}`)
                .jqGrid('setGridParam', {
                    datatype: 'jsonstring', // important
                    datastr: rows // important
                })
                .trigger('reloadGrid');
        }

        function gridSearchData(param_grid: string) {
            if (jg.url !== '' && jg.url !== undefined) {
                gridSearch(postParams, jg.url)
                    .then((response) => {
                        console.log("gridSearchData_____________", response,jg)
                        const rows = response.rows;
                        let colnms = [];
                        rows.map((item, index) => {
                            colnms = [...colnms, ...Object.keys(item)]

                        });
                        const set = new Set(colnms);
                        const uqColnms = [...set];

                        //add hidden cols, beside of user input cols
                        uqColnms.map((item, index) => {
                            if (!JSON.stringify(jg.colModel).includes("\"" + uqColnms[index] + "\"")) {
                                jg.colModel.push({'name': uqColnms[index], 'hidden': true});
                            }
                            {
                                // jg.colModel.push(...{'label': i18f(label)});
                                jg.colModel[index].label = i18f(jg.colModel[index].label);
                                jg.colModel[index].width = gw(jg.colModel[index].width === undefined ? 0 : jg.colModel[index].width);

                                // console.log("jg_colModel3333_____________",jg.colModel[index].label)
                                // console.log("jg_colModel4444_____________",jg.colModel[index].width)
                            }
                        });
                        console.log("jg_colModel_____________", jg.colModel)
                        setColModel(jg.colModel);
                        setGridDataNoReload(rows);
                        if (rows.length > 0) setRowDatas(rows); // to deliver rows to parent
                    })
                    .catch((error: any) => {
                        toast.error(error.message || 'searchGrid Error');
                    });
            }
        }


        async function getCodeString(obj) {
            let params = {"upper_code_cd": obj.upper_code_cd, "corp_cd": obj.corp_cd};
            let rtn_str = "";
            await $.ajax({
                url: `${API_BASE_URL}/common/getCodeString`,
                dataType: 'json',
                type: 'POST',
                contentType: 'application/json; charset=utf-8',
                data: JSON.stringify(params),
                success: function (data) {
                    rtn_str = data.value_str;
                },
                error: function (request, status, error) {
                    console.log('getCode_ERROR=', error);
                }
            });
            return rtn_str;
        }

        function gridInit(param_grid: string) {
            try {

                $grid = $(`#${param_grid}`);
                $gridPager = $(`#${param_grid}Pager`);

                if ($.jgrid) {
                    $(`#${param_grid}`).GridUnload(`#${param_grid}`);
                }
                $(`#${param_grid}`).jqGrid($.extend(true, {},
                    {
                        curRowNum: 1,  //for custom rownumber
                        colModel: colModel ? colModel : false,
                        autoencode: false,
                        // shrinkToFit :false,
                        autowidth: true,
                        cmTemplate: {width: 80, autoResizable: true},
                        datatype: 'local',
                        width: '100%',
                        // width: jg.width ? jg.width : '100%',
                        height: jg.height ? jg.height : 'auto',
                        scrollOffset: 0,
                        // rownumbers: jg.rownumbers ? jg.rownumbers : false,
                        // rownumWidth:jg.rownumbers ? 50 : false,
                        // url: `${API_BASE_URL}${url}`,
                        rowNum: jg.rowNum ? jg.rowNum : false,
                        rowList: [100, 500, 1000, 10000],
                        postData: postParams,
                        // datatype: 'local',
                        treedatatype: 'jsonstring',
                        toppager: jg.toppager ? jg.toppager : false,
                        treeGrid: !!jg.treeGrid,
                        treeReader: jg.treeReader ? jg.treeReader : false,
                        treeGridModel: 'adjacency',
                        ExpandColumn: jg.ExpandColumn ? jg.ExpandColumn : false,
                        ExpandColClick: true,
                        fromServer: true,
                        // shrinkToFit: jg.shrinkToFit ||  true,
                        shrinkToFit: jg.shrinkToFit,

                        treeIcons: {
                            plus: 'ui-icon-triangle-1-e',
                            minus: 'ui-icon-triangle-1-s',
                            leaf: 'ui-icon-radio-off'
                        },
                        // cmTemplate: {align: 'center', editable: true},
                        cellEdit: false,
                        cellsubmit: 'clientArray',
                        // contentType: 'text/html; charset=utf-8',
                        contentType: 'application/json; charset=utf-8',
                        // autowidth: jg.autowidth || true,
                        altRows: true,
                        threeStateSort: true,
                        guiStyle: 'bootstrap4',
                        iconSet: 'fontAwesome',
                        // idPrefix: 'gb1_',
                        ignoreCase: true,
                        loadonce: jg.loadonce ? jg.loadonce : true,
                        // loadonce: false,
                        pager: $gridPager,
                        gridview: false,
                        // sortname: 'proj_sche_id',
                        viewrecords: true,

                        jsonReader: {
                            repeatitems: false,
                            page: 'page',
                            total: 'total',
                            root: 'rows',
                            records: 'records'
                        },
                        loadComplete(e) {
                            // console.log('_____loadComplete____', e, ' jg.colModel____',jg.colModel  );
                            if (e !== undefined && e !== null && e.length !== undefined) {
                                var cssUnderline = {'color': '', 'cursor': 'pointer', 'text-decoration': 'underline'};
                                $(`#${param_grid}`).find(`${param_grid}_record_nm`).addClass(cssUnderline);
                                const ids = $(`#${param_grid}`).jqGrid('getDataIDs');
                                const gridData = $(`#${param_grid}`).jqGrid('getRowData');
                                // const user_id = props.p.user_id;
                                const user_id = user.user_id;

                                for (var i = 0; i < gridData.length; i++) {
                                    var idx = ids[i];
                                    //$(`#${id}tr[id=' + idx + ']'`).addClass('grid-test');
                                    let customer_id = "";
                                    let provider_ids = "";

                                    try {
                                        customer_id = gridData[i].customer_id || '';
                                    } catch (err) {
                                        console.log("________writer_not_exist_exception_________")
                                    }

                                    try {
                                        provider_ids = gridData[i].provider_ids || '';
                                    } catch (err) {
                                        console.log("________provider_ids_not_exist_exception________")
                                    }

                                    const auth_ids = provider_ids + ',' + customer_id;

                                    // console.log('auth_ids======', auth_ids);
                                    if (auth_ids.indexOf(user_id) > -1
                                        // || props.p.role1_cd === 'USER_ROLE_ADMIN'
                                        // || props.p.role1_cd === 'USER_ROLE_SYS_ADMIN'
                                        || user.role1_cd === 'USER_ROLE_ADMIN'
                                        || user.role1_cd === 'USER_ROLE_SYS_ADMIN'
                                    ) {
                                        $(`#${param_grid}`).jqGrid('setCell', idx, 'record_nm', '', cssUnderline);
                                    }
                                }
                                expandAllClick(`${param_grid}`);
                            }

                            if (jg.beforeProcessing !== undefined) {
                                if (jg.beforeProcessing.action == 'getCode') {
                                    const clm_pty = $(`#${param_grid}`).jqGrid('getColProp', jg.beforeProcessing.column);
                                    clm_pty.edittype = 'select';
                                    clm_pty.formatter = 'select';
                                    clm_pty.editoptions = {value: upperCode};
                                }
                            }

                        },
                        loadError(xhr, status, error) {
                            console.log('______loadError_______', error);
                        },
                        afterEditCell() {
                            console.log('______afterEditCell___________');
                        },
                        afterSubmitCell() {
                            console.log('_______afterSubmitCell____________');
                        },
                        beforeProcessing(data) {
                            console.log('____beforeProcessing_____', data, jg.beforeProcessing);
                        },
                        beforeSelectRow() {
                            console.log('______beforeSelectRow_______');
                        },
                        onSelectRow(rowId) {
                            console.log('______onSelectRow_______');
                        },
                        ondblClickRow:
                            jg.ondblClickRow === undefined
                                ? function (id: any) {
                                    if (id && id !== lastsel) {
                                        lastsel = id;
                                    }
                                }
                                : function (id: any) {
                                    // let rowData = jQuery(this).getRowData(id);
                                    let rowData = $(`#${param_grid}`).getRowData(id);

                                    // const cellval = jQuery(this).getCell(id,'record_nm');
                                    const cellval = $(`#${param_grid}`).getCell(id, 'record_nm');
                                    // const userAuth = props.p.userInfo.role1_cd;
                                    const userAuth = user.role1_cd;

                                    // const selected = document.querySelector(id);
                                    // const record_nm_css = $(`#${id}`)[0] === undefined? '':  $(`#${id}`)[0].innerHTML;   //.attributes.style.nodeValue
                                    // const record_nm_css = selected === undefined? '':  selected.innerHTML;   //.attributes.style.nodeValue
                                    // console.log('jg.ondblClickRow.action===', jg.ondblClickRow.action);

                                    //set comment key value
                                    rowData['prog_no'] = rowData[props.p.key_col];
                                    rowData['hide'] = "";  //remove hide grid data to send only required save data
                                    if (jg.ondblClickRow.action === "goPage") {
                                        // const chkstr= "text-decoration: underline";

                                        // set local storage item like proj_cd
                                        if (jg.ondblClickRow.setItem) localStorage.setItem(jg.ondblClickRow.setItem, rowData[`${jg.ondblClickRow.setItem}`]);

                                        const program_url = props.p.program_url;
                                        const nav_prms = {...rowData, 'program_url': program_url};
                                        // const nav_prms = {...rowData, ...props.p};
                                        const nextPage = jg.ondblClickRow.url !== undefined ? jg.ondblClickRow.url : program_url;
                                        console.log("nav_prms_gopage____", nav_prms);
                                        navigate(`${nextPage}`, {
                                            // navigate(`${program_url}`, {
                                            state: {name: `${props.p.menu_nm_short}`, params: nav_prms}
                                        });
                                    } else if (jg.ondblClickRow.action === "setData") {
                                        const data = $(`#${param_grid}`).jqGrid('getRowData');
// console.log('dblClick_defaultValue===', {[`${label}`]: data, ...rowData});

                                        const program_url = props.p.program_url;
                                        // console.log('program_url===', program_url,' props.p.menu_nm_short=',props.p.menu_nm_short, ' rowData==',rowData);
                                        // console.log("label==",{[`${label}`]: data, ...rowData})
                                        const nav_prms = {[`${label}`]: data, ...rowData, 'program_url': program_url};
                                        console.log("nav_prms_setdata____", nav_prms);
                                        navigate(`${program_url}`, {
                                            state: {name: `${props.p.menu_nm_short}`, params: nav_prms}
                                        });
                                    }

                                    if (id && id !== lastsel) {
                                        lastsel = id;
                                    }
                                }
                    }));

                // .trigger('reloadGrid');
                // if(jg.rownumbers){
                //   $(`#${param_grid}`).jqGrid("setLabel", "rn", "No");
                // }

                const updateDialogDefault = {
                    type: 'POST', //
                    top: '200',
                    width: '450',
                    left: '300',
                    display: 'block',
                    checkOnUpdate: true,
                    closeAfterAdd: false,
                    closeAfterEdit: true,
                    closeOnEscape: true,
                    recreateForm: true,
                    reloadAfterSubmit: true,
                    modal: false, //
                    jqModal: false,
                    toTop: false,

                    beforeInitData: async function (formid) {
                        // console.log("==========beforeInitData============",formid)
                    }
                    , afterShowForm: function (formid) {
                        if (param_grid === 'grid9') {
                            const selId = $(`#${param_grid}`).jqGrid('getGridParam', "selrow");
                            const selRow = $(`#${param_grid}`).getRowData(selId);
                            const ar = $(`#${param_grid}`).getGridParam("reccount");
                            const selseq = selRow.appv_seq === undefined ? (ar === 0 ? '1' : toString(ar)) : selRow.appv_seq;
                            const allrows = $(`#${param_grid}`).getRowData();

                            if (selseq === '1') $("#appv_kind", formid[0]).prop('disabled', 'disabled');
                            if (selseq !== undefined && selseq !== '1') {
                                $("#appv_kind", formid[0]).find('option').filter(function () {
                                    return (this.value === 'I');
                                }).remove();
                            } else if (selseq === undefined && ar !== 0) {
                                if (ar === 1) {
                                    $("#appv_kind", formid[0]).find('option').filter(function () {
                                        return (this.value === 'I');
                                    }).remove();
                                }
                            }
                        }
                    }
                    , beforeShowForm: async function (formid) {
                    }
                };

                let addModal = null;
                let editModal = null;
                let delModal = null;

                if (jg.editModal === undefined) {
                    addModal = $.extend(updateDialogDefault, {
                        onclickSubmit: onclickSubmitLocal
                    });
                    editModal = $.extend(updateDialogDefault, {
                        onclickSubmit: onclickSubmitLocal
                    });
                    delModal = $.extend(updateDialogDefault, {
                        onclickSubmit: onclickSubmitLocal
                    });
                } else {
                    addModal = $.extend(
                        {
                            url: `${API_BASE_URL}${jg.editModal.trim()}`,
                            type: 'POST', //
                            contentType: 'application/json',
                            zIndex: 950,
                            serializeEditData(postData: any) {
                                // console.log('postdata=add=' + JSON.stringify(postData));
                                let jdata = {
                                    ...postData,
                                    oper: 'add',
                                    // parent_col : '',
                                    post_params: JSON.stringify(postParams)
                                };

                                return (jdata);
                            },
                            afterComplete(e) {
                                gridSearchData(param_grid);
                            }
                        },
                        updateDialogDefault
                    );

                    editModal = $.extend(
                        {
                            url: `${API_BASE_URL}${jg.editModal.trim()}`,
                            type: 'POST', //
                            contentType: 'application/json',
                            zIndex: 950,
                            // position: 'relative',
                            dataType: 'json'
                            // method: 'POST',

                            , serializeEditData(postData: any) {
                                let jdata = {
                                    ...postData,
                                    oper: 'edit',
                                    parent_col: '',
                                    post_params: JSON.stringify(postParams)
                                };

                                return (jdata);

                            }
                            , afterComplete(e) {
                                gridSearchData(param_grid);
                            }
                        },
                        updateDialogDefault
                    );

                    delModal = $.extend(
                        {
                            url: `${API_BASE_URL}${jg.editModal.trim()}`,
                            type: 'POST',
                            contentType: 'application/json',
                            zIndex: 950

                            , serializeDelData(postdata: any) {
                                const selRow = $(`#${param_grid}`).getRowData(postdata.id);
                                return $.extend(true, {}, postdata, {
                                    oper: 'del',
                                    ...selRow,
                                    post_params: JSON.stringify(postParams)
                                });
                            }

                            , onclickSubmit(options, postdata, formOper) {
                            },
                            afterComplete(e) {
                                console.log('delRow_e.responseJSON=', e.responseJSON);
                                // gridSearchData();
                            }
                        },
                        updateDialogDefault
                    );
                }

                $grid.navGrid(
                    `#${gridPager}`,
                    {
                        cloneToTop: true,
                        edit: !!jg.edit,
                        add: !!jg.add,
                        del: !!jg.del,
                        search: !!jg.search,
                        refresh: false
                    },
                    editModal,
                    addModal,
                    delModal,
                    {
                        multipleSearch: true,
                        multipleGroup: true,
                        overlay: 0,
                        showQuery: true,
                        // sopt : [ 'eq', 'ne', 'lt', 'le', 'gt', 'ge', 'bw', 'bn', 'in', 'ni', 'ew', 'en', 'cn', 'nc' ],
                        sopt: ['eq', 'ne', 'lt', 'gt', 'ge', 'bw', 'bn', 'ew', 'en', 'cn', 'nc'],
                        //searchoptions:,
                        //odata : ['equal', 'not equal', 'less', 'less or equal','greater','greater or equal', 'begins with','does not begin with','is in','is not in','ends with','does not end with','contains','does not contain'],

                        top: '200',
                        left: 'auto',
                        jqModal: false,
                        onSearch() {
                            let i;
                            let rules;
                            let rule;
                            const postData = $grid.jqGrid('getGridParam', 'postData');
                            const pr = postData.filters === undefined ? "{}" : postData.filters;
                            const filters = $.parseJSON(pr);
                            if (
                                filters &&
                                typeof filters.rules !== 'undefined' &&
                                filters.rules.length > 0
                            ) {
                                rules = filters.rules;
                                for (let i = 0; i < rules.length; i++) {
                                    rule = rules[i];
                                    if (rule.field === 'name') {
                                        rule.field = 'amount';
                                    }
                                }

                                const $filter = $(`#${$.jgrid.jqID(`fbox_${param_grid}`)}`);
                                let sql = $filter.jqFilter('toSQLString');
                                sql = sql.replace(/\"/g, "'");

                                const data = {sql};
                                $.ajax({
                                    url: `${API_BASE_URL}/common/selectCommon`,
                                    dataType: 'json',
                                    type: 'POST',
                                    contentType: 'application/json; charset=utf-8',
                                    data: JSON.stringify({...props.p, sql}),
                                    success: function (data) {
                                        console.log('data======', data);
                                        // $(elem).empty().attr('name','role1_cd').append(data.option_str);
                                        const list = data.rows;
                                        setGridDataNoReload(list);
                                    },
                                    error: function (request, status, error) {
                                        console.log('getCode_ERROR=', error);
                                    }
                                });

                                $grid.setGridParam({
                                    //postData:{filters:'{"groupOp":"AND","rules":[{"field":"","op":"cn","data":""}]}'},
                                    postData: {
                                        filters: ''
                                    },
                                });


                            }
                        }
                    }
                );

                if (jg.upload) {
                    $grid.navButtonAdd(`#${param_grid}_toppager_left`, {
                        caption: '',
                        buttonicon: 'fas fa-solid fa-upload btn-default',
                        onClickButton() {
                            // var fileupload = document.getElementById('fileupload');
                            // if (fileupload) {
                            //   $('#fileupload').trigger('click');
                            // }
                            handleUploadClick();

                            // const input = document.getElementById('input');
                            // input.addEventListener('change', () => {
                            //   readXlsxFile(input.files[0]).then((rows) => {
                            //     // `rows` is an array of rows
                            //     // each row being an array of cells.
                            //     console.log('readXlsxFile_rows==', rows);
                            //   });
                            // });
                        },
                        position: 'last',
                        id: 'excelUpload',
                        title: 'Excel Upload',
                        cursor: 'pointer'
                    });
                }
                if (!props.approval) {
                    $grid.navButtonAdd(`#${param_grid}_toppager_left`, {
                        caption: '',
                        buttonicon: 'fas fa-solid fa-download btn-default',
                        onClickButton() {
                            const data = $grid.jqGrid('getRowData');
                            const cm = $(`#${param_grid}`).jqGrid('getGridParam', 'colModel');

                            const cmarr: any[] = [];
                            let txt = '';
                            Array.from(cm).map(function (v) {
                                console.log('v___________________', v);
                                typeof v['label'] != 'undefined' && v.editable
                                    ? cmarr.push(v['label'])
                                    : (txt = txt + v['name'] + ',');
                            });

                            const arr = [];
                            arr.push(cmarr);

                            const txtarr = txt.split(',');
                            Array.from(data).map(function (v) {
                                for (const y in txtarr) {
                                    delete v[`${txtarr[y]}`];
                                }
                                const arrsub = [];
                                for (const x in v) {
                                    arrsub.push(v[x]);
                                }
                                arr.push(arrsub);
                            });

                            const wsName = 'SheetJS';
                            const filename = 'list.xlsx';
                            const wb = XLSX.utils.book_new();
                            const ws = XLSX.utils.aoa_to_sheet(arr);
                            XLSX.utils.book_append_sheet(wb, ws, wsName);
                            XLSX.writeFile(wb, filename);
                        },
                        position: 'last',
                        id: 'excelDown',
                        title: 'Excel Download',
                        cursor: 'pointer'
                    });
                }

                /*
                //approval through popup modal make this useless
                                if (props.approval) {
                                    let is_approver = false;
                                    const epv = eval(props.defaultValue);
                                    console.log("props.defaultValue_____________________", props.defaultValue)
                                    if (epv !== undefined && epv.length > 0) {
                                        for (let i = 0; i < epv.length; i++) {
                                            if (epv[i].appv_dtm === "" && epv[i].approver_id === props.p.userInfo.user_id) {
                                                is_approver=true;
                                            }
                                        }
                                    }

                                    if(is_approver) {
                                        $grid.navButtonAdd(`#${param_grid}_toppager_left`, {
                                            caption: '',
                                            buttonicon: 'fas fa-solid fa-pencil-alt btn-default',
                                            onClickButton() {
                                                const appv_array = $(`#${param_grid}`).jqGrid('getRowData');
                                                const cm = $(`#${param_grid}`).jqGrid('getGridParam', 'colModel');

                                                console.log(`appv_array_____`, appv_array);
                                                console.log('postParams______', postParams);
                                                const appv_data = {...postParams, ...{appv_array: appv_array}}

                                                console.log('appv_data______', appv_data);
                                                makeApproval(appv_data)
                                                    .then((response) => {
                                                        const rows = response.rows;
                                                        // console.log(`gridSearchData_rows=`, rows);

                                                        // setGridDataNoReload(rows);
                                                        console.info('_________________setRowDatas_____________', rows);
                                                        if (rows.length > 0) setRowDatas(rows); // to deliver rows to parent
                                                    })
                                                    .catch((error: any) => {
                                                        toast.error(error.message || 'searchGrid Error');
                                                        //console.info('searchGrid Error', error);
                                                    });
                                            },
                                            position: 'last',
                                            id: 'approval',
                                            title: 'Make approval',
                                            cursor: 'pointer'
                                        });
                                    }
                                }
                */

                if (jg.reorder) {
                    $grid.navButtonAdd(`#${param_grid}_toppager_left`, {
                        caption: '',
                        title: 'Reorder Columns',
                        buttonicon: 'fas fa-solid fa-list-ol btn-default',
                        // jqModal: false,
                        // modal: true,
                        // overlay: 100,

                        onClickButton() {
                            $(`#${param_grid}`).jqGrid('columnChooser');
                        }
                    });
                }

                const topPagerDiv = $(`#${param_grid}_toppager`)[0];
                $(`#${param_grid}_top`, topPagerDiv).remove();
                $(`#${param_grid}_toppager_center`, topPagerDiv).remove();
                $('.ui-paging-info', topPagerDiv).remove();

                if (param_grid == 'gridA' || label.indexOf("_array") > -1) {
                    // $(`#${param_grid}Pager .ui-pg-table`).attr('style', 'display:none');
                    $(`#${param_grid}Pager`).attr('style', 'display:none');
                } else {
                    const bottomPagerDiv = $('div#pager')[0];
                    $(`#add_${param_grid}`, bottomPagerDiv).remove();
                    $(`#del_${param_grid}`, bottomPagerDiv).remove();
                    $(`#edit_${param_grid}`, bottomPagerDiv).remove();
                    $(`#search_${param_grid}`, bottomPagerDiv).remove();
                    $(`#${param_grid}Pager_left`).css('display', 'none');
                    $(`#${param_grid}Pager .ui-pg-table`).attr('style', '');
                    $(`#${param_grid}Pager_center .ui-pg-table`).attr('style', 'width:300px');
                    $(`.card-deck .card`).css('margin-left', '0');
                }
                var dataInitMultiselect = function (elem, searchOptions) {
                    var $grid = $(this);
                    setTimeout(function () {
                        var $elem = $(elem);
                        var id = elem.id;
                        var inToolbar = searchOptions.mode === 'filter';
                        let options = {
                            selectedList: 5,
                            height: '600', //auto
                            checkAllText: 'all',
                            uncheckAllText: 'no',
                            noneSelectedText: 'Any',
                            open: function () {
                                let $menu = $('.ui-multiselect-menu:visible');
                                $menu.width('auto');
                                $menu.find('>ul').css('maxHeight', '500');
                                return;
                            }
                        };
                        let $options = $elem.find('option');
                        if (inToolbar) {
                            options.minWidth = '400';
                        }
                        // $grid.triggerHandler('jqGridRefreshFilterValues');
                        $elem.multiselect(options);
                        $elem.siblings('button.ui-multiselect').css({
                            width: '100%',
                            margin: '1px 0',
                            paddingTop: '.3em',
                            paddingBottom: '.3em'
                        });
                    }, 50);
                };
                var multiselectTemplate = {
                    stype: 'select',
                    searchoptions: {
                        // generateValue: true,
                        // value: {this.multiselectValue}
                        //noFilterText: "Any",
                        sopt: ['in'],
                        attr: {
                            multiple: 'multiple',
                            size: 4
                        },
                        dataInit: dataInitMultiselect
                    }
                };

                return $grid;
            } catch
                (e) {
                toast.error('ERROR_LODING_GRID', e);
                console.log('ERROR_LODING_GRID=', e);
            }
        }//end grid init

        function setAppvOpt(elem) {
            return {
                "I": "기안",
                "A": "결재",
                "C": "합의"
            }
        }

        function setAppvStatus(elem) {
            return {
                "I": "기안",
                "A": "승인",
                "R": "반려",
                "C": "합의",
                "D": "이의"
            }
        }


        function customRowNumber() {
            var pp = $(`#${gridNum}`).jqGrid("getGridParam"),
                rn = pp.curRowNum +
                    (parseInt(pp.page, 10) - 1) * parseInt(pp.rowNum, 10);
            pp.curRowNum++;
            return rn.toString();
        }

        function expandAllClick(id) {
            $(`#${id}`).find('.treeclick').trigger('click');
        }

        function updateDialog(url, postdata) {
            return {
                url: url
                , closeAfterAdd: true
                , closeAfterEdit: true
                , afterShowForm: function (formId) {
                }
                , modal: true
                , onclickSubmit: function (params) {

                    params.url += rowData.Id;
                    params.mtype = 'POST';
                }
                , width: "300"
            };
        }

        function onclickSubmitLocal(options, postdata, oper) {
            let delMode = false;
            if (oper.length === 1) delMode = true;

            const $this = $(`#${gridNum}`);
            const p = $this.jqGrid('getGridParam');
            const idname = p.prmNames.id;
            const id = gridNum;
            const idInPostdata = `${gridNum}_id`;  //20240110
            const rowid = postdata[idInPostdata];
            const addMode = rowid === '_empty';

            let newId = rowid;
            let idOfTreeParentNode;
            // postdata has row id property with another name. we fix it:
            if (addMode) {
                // generate new id
                newId = $.jgrid.randId();
                while ($(`#${$.jgrid.jqID(newId)}`).length !== 0) {
                    newId = $.jgrid.randId();
                }
            }

            if (addMode && postdata[idname] === undefined) {
                // set id property only if the property not exist
                postdata[idname] = newId;
            }

            delete postdata[idInPostdata];
            if (p.treeGrid === true) {
                if (addMode) {
                    idOfTreeParentNode =
                        p.treeGridModel === 'adjacency'
                            ? p.treeReader.parent_id_field
                            : 'parent_id';
                    postdata[idOfTreeParentNode] = p.selrow;
                }

                // what is this for??
                $.each(p.treeReader, function () {
                    if (postdata.hasOwnProperty(this)) {
                        delete postdata[this];
                    }
                });
            }

            if (p.autoencode) {
                $.each(postdata, function (n, v) {
                    postdata[n] = $.jgrid.htmlDecode(v);
                    // TODO: some columns could be skipped
                });
            }

            const oldValueOfSortColumn =
                p.sortname === ''
                    ? undefined
                    : $this.jqGrid('getCell', rowid, p.sortname);

            // save the data in the grid
            if (p.treeGrid === true) {
                if (addMode) {
                    $this.jqGrid('addChildNode', newId, p.selrow, postdata);
                } else {
                    $this.jqGrid('setTreeRow', rowid, postdata);
                }
            } else if (addMode) {
                $this.jqGrid('addRowData', newId, postdata, 'last');
                console.log("postdata=", postdata, "\n newId===", newId, " options.addedrow==", options.addedrow);
            } else if (delMode) {
                $this.jqGrid('delRowData', postdata);
            } else {
                $this.jqGrid('setRowData', rowid, postdata);
            }

            if (
                (addMode && options.closeAfterAdd) ||
                (!addMode && options.closeAfterEdit)
            ) {
                // close the edit/add dialog
                $.jgrid.hideModal(`#editmod${gridNum}`, {
                    // $this.jqGrid.hideModal(`#editmod${id}`, {
                    gb: `#gbox_${gridNum}`,
                    jqm: options.jqModal,
                    onClose: options.onClose
                });
            }

            if (postdata[p.sortname] !== oldValueOfSortColumn) {
                // if the data are changed in the column by which are currently sorted
                // we need resort the grid
                setTimeout(function () {
                    $this.trigger('reloadGrid', [{current: true}]);
                }, 100);
            }

            // !!! the most important step: skip ajax request to the server
            options.processing = true;
            setRowDatas($this.getRowData());
            return {};
        }

        function changeTable(e) {
            console.log("event_value_________________", e.target.value);

            window.table = e.target.value;
            // setCompoTbl(e.target.value);
            $.ajax({
                url: `${API_BASE_URL}/sys/getColumns?table=${e.target.value}`,
                type: "get",
                success: function (newOptions: string) {
                    // console.log("newOptions_________________", newOptions);
                    var form = $(e.target).closest("form.FormGrid");
                    $("select#onDblClickSetItem.FormElement", form[0]).html(newOptions);
                    $("select#ExpandColumn.FormElement", form[0]).html(newOptions);
                    $("select#level_field.FormElement", form[0]).html(newOptions);
                    $("select#parent_id_field.FormElement", form[0]).html(newOptions);
                    $("select#leaf_field.FormElement", form[0]).html(newOptions);
                    // $("select#name.FormElement",form[0]).html(newOptions);
                    // $("select#name.FormElement").html(newOptions);

                }
            });
        }

        function setColumns(e) {
            const table = rpms.getTable();

            $.ajax({
                url: `${API_BASE_URL}/sys/getColumns?table=${table}`,
                type: "get",
                success: function (newOptions: string) {
                    // console.log("newOptions_________________", newOptions);
                    var form = $(e.target).closest("form.FormGrid");
                    $("select#name.FormElement", form[0]).html(newOptions);
                }
            });
            // return `${API_BASE_URL}/sys/getColumns?table=${table}`;
        }

        function getSelStr(div: string) {


            /*
            const p = JSON.stringify(postParams);
            const projCd = postParams.proj_cd;
            const ver = postParams.ver;
            const params = `corp_cd=${corpCd}&proj_cd=${projCd}&ver=${ver}`;
            */

            const corpCd = rpms.getCorpCd();
            const table = rpms.getTable();

            // console.log("table_____________", table, div);
            if (div === 'tables') {
                // setSelStr('')
                return `${API_BASE_URL}/sys/getTables?`;
            }

            if (div === 'programs') return `${API_BASE_URL}/sys/getPrograms?corp_cd=${corpCd}`;

            if (div === 'columns') {
                return `${API_BASE_URL}/sys/getColumns?table=${table}`;
            }
        }

        function taskCdCheck(element) {
            $(element).keyup(function () {
                let regExp = '[^-.0-9]';
                let val1 = element.value;
                if (val1.match(regExp)) {
                    toast.error('Only number and dot can be typed<br> ex. 1.2.1 ');
                    element.value = '';
                }
            });
        }

        const fl = i18f(`views.common.${label}`);
        const [file, setFile] = useState<File>();
        const inputRef = useRef<HTMLInputElement | null>(null);
        const handleUploadClick = () => {
            inputRef.current?.click();
        };

        const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
            if (!e.target.files) {
                return;
            }

            readXlsxFile(e.target.files[0]).then((rows) => {
                const colModel = $(`#${gridNum}`).jqGrid('getGridParam', 'colModel');
                let colname = '';
                let editable = '';

                const keys = [];
                for (let i = 0; colModel.length > i; i++) {
                    colname = colModel[i].name;
                    editable = colModel[i].editable;
                    if (editable) {
                        // console.log('colname==', colname, rows[i]);
                        keys.push(colname);
                    }
                }

                const arr = [];
                for (let i = 0; i < rows.length; i++) {
                    const obj = {};
                    for (let j = 0; j < keys.length; j++) {
                        // console.log(`${obj[keys[i]]}====`, rows[i][j]);
                        obj[keys[j]] = rows[i][j];
                    }

                    if (i !== 0) arr.push({...obj, ...postParams});
                }

                const jsonData = JSON.stringify(arr);
                $.ajax({
                    url: `${API_BASE_URL}/PJ/RG/updateTasks`,
                    data: jsonData,
                    // data: arr,
                    method: 'POST',
                    contentType: 'application/json; charset=UTF-8',
                    dataType: 'json',
                    success(data) {
                        $(`#${gridNum}`)
                            .jqGrid('setGridParam', {
                                datatype: 'jsonstring',
                                datastr: arr
                            })
                            .trigger('reloadGrid');
                        gridSearchData(gridNum);

                        toast.info('Upload succeed');
                    },
                    error(e) {
                        toast.error('Error while uploading', e);
                    }
                });
            });
        };

        function getMultiSelectUrl() {
            const p = JSON.stringify(postParams);
            const corpCd = postParams.corp_cd;
            const projCd = postParams.proj_cd;
            const ver = postParams.ver;
            const params = `corp_cd=${corpCd}&proj_cd=${projCd}&ver=${ver}`;
            return `${API_BASE_URL}/PJ/RG/getProjectMembers?${params}`;
        }

        return (
            <div className="form-group">
                <div className="input-group">
                    {((`${label}`).indexOf('hide') < 0) ? (
                        <>
                            <label className="col-sm-3 col-form-label text-center" htmlFor={id}>
                                {fl}
                            </label>
                            <div className="col-sm-9 conainter-fluid">
                                <div className={gridWrapper}>
                                    <table id={gridNum}/>
                                    <div id={gridPager}/>

                                    {/*<ErrorMessage error={error} />*/}

                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="col-sm-12 conainter-fluid">
                            <div className={gridWrapper} style={{width: '100%'}}>
                                <table id={gridNum} style={{width: '100% !important'}}/>
                                <div id={gridPager}/>

                                {/*<ErrorMessage error={error} />*/}

                            </div>
                        </div>
                    )}
                    <input
                        type="file"
                        ref={inputRef}
                        onChange={handleFileChange}
                        style={{display: 'none'}}
                    />
                </div>
                {/*<hr className="bg-gray-400 my-4"/>*/}
            </div>
        );
    })
;


function setProjMembers(elem) {
    let memarr = [];
    setTimeout(function () {
        $(elem).multiselect({
            minWidth: 100,
            height: 'auto',
            selectedList: 10,
            checkAllText: 'all',
            uncheckAllText: 'no',
            noneSelectedText: 'Any',
            appendTo: '.ui-jqdialog',
            change() {
                // console.log('change=========',memarr);
            },
            click() {
                // console.log('click=========',memarr);
            },
            close() {
                memarr = [];
                $(`.ui-multiselect-checkboxes > li > label`).each(function (i, obj) {
                    // var aaa = $(obj).children("input").filter(':checked')
                    if ($(obj).children("input")[0].checked) {
                        // let seleobj = { 'jq_user_id' : $(obj).children("input")[0].value, 'jq_record_nm' : $(obj).children("span")[0].innerHTML, 'jq_att1': ''};
                        let seleobj = {
                            'user_id': $(obj).children("input")[0].value,
                            'record_nm': $(obj).children("span")[0].innerHTML,
                            'att1': ''
                        };
                        memarr.push(seleobj);
                    }
                });
                let jsmem = JSON.stringify(memarr);
                $('#provider_array').val(jsmem);
                console.log('jsmem=========', jsmem);

            },
            open() {
                // $('.ui-dialog').css('z-index', 953);
                // $('.ui-widget-overlay').css('z-index', 953);

                // console.log('open.elem.value==========', elem.attr("name").value;
                const $menu = $('.ui-multiselect-menu:visible');

                $menu.width('auto');
                // $menu.style('999');
                // $menu.style('display', 'block');
                // $(".ddfullscreen")[0].append(this[0])
                // $menu.addClass('modal');

                $menu.find('>ul').css('maxHeight', '500px');
                $menu.find('>ul').css('overflow', '');
                $menu.find('>ul').css('display', 'block');
                // $menu.append('.ui-jqdialog');
                // overflow: inherit !important;
                // return;
            }
        });
        // .appendTo('.ui-jqdialog');
        // $(elem).siblings('button.ui-multiselect').css({
        // $(elem).siblings('.ui-multiselect').css({
        //   width: '100%',
        //   margin: '1px 0',
        //   paddingTop: '.3em',
        //   paddingBottom: '.3em'
        // });
    }, 150);


}

function i18f(m: string) {
    const {t} = i18n;
    return t(m);
}


function gw(m) {
    const wi = (m / 100) * (window.innerWidth * 0.86);
    return wi;
}
