interface Props {
  error?: string;
}

export const ErrorMessage = ({error}: Props) => {
  if (!error) return null;

  return (
    // <div class="d-flex flex-row justify-content-end p-1">
    //   <p className="text-danger">{error}</p>
    // </div>
    <p className="text-danger">{error}</p>
    // <span class="d-flex flex-row justify-start-end">
    //   <p className="text-danger">{error}</p>
    // </span>
  );
};
