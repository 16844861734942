import React, {
  Component,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {selectAllSchedule} from '@app/utils/APIUtils';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';

function FullCalendarForm(props: any) {
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.auth.currentUser);
  const {label} = props;
  console.log('FullCalendarForm###########################', props);
  const edv = eval(props.defaultValue);
  const id = `${label}`;
  const fl = i18f(`views.common.${label}`);
  const [events, setEvents] = useState([]);
  const cal = useRef();
  //
  // const form_table = props.p.form_table;
  // const program_url = props.p.program_url;

  useEffect(() => {
    const start =
      cal.current.getApi().currentData.dateProfile.currentRange.start;
    const end = cal.current.getApi().currentData.dateProfile.currentRange.end;
    const date = new Date();
    const start2 = moment(start).format();
    const end2 = moment(end).format();

    selectData(start2, end2);
  }, [props.defaultValue]);

  const dateChange = (e) => {
    const startDate = e.startStr;
    const endDate = e.endStr;
    selectData(startDate, endDate);
  };

  const dateClick = (e) => {
    console.log('e======', e);
  };

  function selectData(start, end) {
    let tmp = {
      corp_cd: user.corp_cd,
      start_dtm: start,
      stop_dtm: end
    };

    const url = '/common/selectAllSchedule';
    const pv = JSON.stringify(tmp);
    selectAllSchedule(url, pv)
      .then((response) => {
        const arr = response.rows;
        setEvents(arr);
      })
      .catch((error) => {
        if (error.status === 401) {
          // console.error('Error loading sidebar menu!');
        }
      });
  }

  let paramobj = null;

  const eventClick = (e) => {
    const schedule_id = e.event.extendedProps.schedule_id;
    const record_nm = e.event.title;
    const record_desc = e.event.extendedProps.record_desc;
    const record_category = e.event.extendedProps.record_category;
    const record_status = e.event.extendedProps.status;
    const att_array = e.event.extendedProps.att_array;
    const provider_array = e.event.extendedProps.provider_array;
    const start = new Date(e.event.start);
    const end = new Date(e.event.end);
    const customer_id = user.user_id;
    const startIso8610 = start.toISOString();
    const endIso8610 = end.toISOString();

    const background_color = e.event.backgroundColor;
    const border_color = e.event.borderColor;

    paramobj = {
      // form_table : form_table,
      // program_url : program_url,
      schedule_id: schedule_id,
      customer_id: user.user_id,
      corp_cd: user.corp_cd,

      record_nm: record_nm,
      record_desc: record_desc,
      record_status: record_status,

      record_category: record_category,
      start_dtm: startIso8610,
      stop_dtm: endIso8610,
      provider_array: provider_array,
      att_array: att_array,

      border_color: border_color,
      background_color: background_color
    };

    gogo(paramobj);
  };

  const gogo = (paramobj: any) => {
    const program_url = '/CO/RP/CORP200I';
    navigate(`${program_url}`, {
      state: {
        name: `${props.p.menu_nm_short}`,
        params: {...paramobj, fullcalendar: paramobj}
      }
    });
  };

  const handleDateSelect = (selectInfo) => {
    let calendarApi = selectInfo.view.calendar;
    calendarApi.unselect();

    let bgc = '';
    let rc = props.dbValue.record_category || 'P';

    if (rc === 'SCHE_DIV_C') {
      bgc = '#00c0ef';
    } else if (rc === 'SCHE_DIV_D') {
      bgc = '#3c8dbc';
    } else if (rc === 'SCHE_DIV_T') {
      bgc = '#605ca8';
    } else if (rc === 'SCHE_DIV_P') {
      bgc = '#f39c12';
    } else if (rc === 'SCHE_DIV_J') {
      bgc = '#0000FF';
    } else if (rc === 'SCHE_DIV_I') {
      bgc = '#DB2828';
    }

    let paramobj = {
      schedule_id: createEventId(),
      customer_id: user.user_id,
      corp_cd: user.corp_cd,
      start_dtm: selectInfo.startStr,
      stop_dtm: selectInfo.endStr,
      border_color: bgc,
      background_color: bgc
    };

    gogo(paramobj);
  };

  let eventGuid = 0;
  let todayStr = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today

  function createEventId() {
    return String(eventGuid++);
  }

  return (
    <div className="App">
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView={'dayGridMonth'}
        headerToolbar={{
          start: 'prev,next', //today
          center: 'title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay'
        }}
        // height={"68vh"}
        select={handleDateSelect}
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        // weekends={false}
        dateClick={dateClick}
        eventClick={eventClick}
        // eventContent={eventContent}
        datesSet={dateChange}
        // eventsSet={handleEvents}
        // buttonText={{
        //   today: "Today",
        //   month: "Month",
        //   week: "Week",
        //   day: "Day",
        //   list: "List",
        // }}
        events={events}
        ref={cal}
      />
    </div>
  );
}

export default FullCalendarForm;

// export default React.memo(FullCalendarForm);
