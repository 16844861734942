import React, {useRef, useState, useEffect} from 'react';

import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {PfButton} from '@profabric/react-components';

import i18n from '@app/utils/i18n';
import StockChipGroup from '@app/pages/common/addStock/stockChipGroup/StockChipGroup';
import BasicSingle from '@app/pages/common/selectBox/BasicSingle';
import {getCode} from '@app/utils/APIUtils';
import Multi from '@app/pages/common/selectBox/Multi';
import SelectUser from '@app/pages/common/selectUser/SelectUser';
import MyDropzone from '@app/pages/common/dropzone/MyDropzone';
import Comments from '@app/pages/common/comment/Comments';

import GanttGroup from '@app/pages/common/gantt_task/GanttGroup';
import DyPageInc from '@pages/pj/rg/DyPageInc';
import FullCalendarForm from '@app/pages/common/fullcalendar/FullCalendarForm';
import Editor from "@pages/common/ckeditor/Editor";
import {JqGridForm} from "@pages/common/jqgrid/JqGridForm";


export default function VisualForm({
    ltv,
   // selectedFormValues,
    compoValues,
    handleChange,
    index,
    deleteField,
    changedValues,
    dbValue
}) {

  console.log('VisualForm_dbValue=============', dbValue);
  console.log('changedValues______________',changedValues);
  console.log('ltv______________',ltv);
  console.log('compoValues______________',compoValues);

  const [halfWidth, SetHalfWidth] = useState(false);

  const textareaStyle = {
    boder: '1px solid black',
    resize: 'vertical'
  };
  const gridRef = useRef();

  const commentsRef = useRef();

  // const dateRef = useRef();

  const {label, type, value} = ltv;


  // const eltv = eval(ltv);

  const pageWidth = 370;

  const {state} = useLocation();
  const user = useSelector((state: any) => state.auth.currentUser);

  const [postParams, setPostParams] = useState({
    userInfo: user
  });

  const location = useLocation();
  const pageName = location.pathname.split('/').reverse()[0];
  const projId = state.params.proj_id;
  // console.log('state=', state, 'projId====', projId);
  // console.log('pageName_____', pageName);
  const [commentsItem, setCommentsItem] = useState([
    {
      // commentId: 'comments1',
      prog_id: pageName,
      prog_no: projId,
      ...postParams
    }
  ]);


  const [commonModuleOption, SetCommonModuleOption] = useState({
    colModel: [
      {
        name: 'column1',
        width: pageWidth * (30 / 100),
        align: 'center',
        hidden: false
      }
    ]
  });

  const [selectItems, setSelectItems] = useState([
    {
      upperCodeCd: '',
      corpCd: '',
      active: '',
      optional: '',
      defaultValue: '',
      isDisabled: ''
    }
  ]);

  const [selectOptions, setSelectOptions] = useState([
    {value: 'chocolate', label: 'Chocolate'},
    {value: 'strawberry', label: 'Strawberry'},
    {value: 'vanilla', label: 'Vanilla'}
  ]);

  const [userInfo, setUserInfo] = useState({user_id: '', record_nm: ''});

  function setParams(ov: object, type: string) {
    //  console.log('setParams_type=============', type);
    if (type === 'selectSingle' || type === 'selectMulti') {
      const eParams = JSON.parse(ov.value);
      setSelectItems(eParams);

      getCode(
        selectItems.corpCd,
        selectItems.upperCodeCd,
        selectItems.active === undefined ? 'Y' : eParams.active,
        selectItems.optional === undefined ? 'Y' : eParams.optional
      )
        .then((response) => {
          const arr = response.data;
          setSelectOptions(response.data);
        })
        .catch((error) => {
          if (error.status === 401) {
            console.error('Error loading sidebar menu!');
          }
        });
    } else if (type === 'grid') {
       console.log(' setParams__________',setParams);
    } else if (type === 'text') {
      // SetHalfWidth(eval(ov.value));
    } else if (type === 'user') {
      setUserInfo(ov.value);
    } else if (type === 'file') {
      console.log('=======file_set======');
    }
  }

/*
  useEffect(() => {
    try {
     console.log('VisualForm=====', objValue, 'type=====', type);
     handleChange({id: objValue.label, value: holdings});
     // props.handleChange()
      // setParams(ltv, type);
    } catch (exception) {
      console.log('exception___________', exception);
    }
  }, [compoChange]);
*/

  const compoChange =  (e) => {
      if (e.id || e.target.id) {
        console.log('e.id==', e.id, 'e.value==', e.value);
        const name = e.id !== undefined ? e.id : e.target.id;
        const value = e.value !== undefined ? e.value : e.target.value;
        console.log("VisualForm_handleChange__________________",name, value);

        handleChange({id: name, value: value});

/*
        setValues({
          ...values,
          [name]: value
        });
        */
      }
  };


  const [formDate, setFormDate] = useState(
    dbValue ? dbValue[`${label}`] : ''
  );

  const errorMessage = {
    color: 'red',
    position: 'absolute',
    fontSize: '11px'
  };

  const getPropsReal = (label: any, type: any, value: any) => ({

    // if(value === undefined) return;

      grid: (
      <JqGridForm
          key={label}
          gridInfo={value}
          label={label}
          handleChange={compoChange}

          // p={eltvp.p}
          ref={gridRef}
          defaultValue={dbValue}
          // defaultValue={dbValue ? dbValue[`${label}`] : ''}
      />
      )
    ,button: (
      <>
        <div className="text-right mr-2 mb-2 mt-2">
            <button className="btn btn-outline-primary btn-sm ml-1" type="submit" >
              {/* {labelButtonSubmit} */}
             Submit
             </button>
        </div>
        
      </>

    ),    
    user: (
      <>
        <SelectUser
          key={label}
          className={`form-control ${halfWidth ? 'col-md-6' : ''}`}
          type="text"
          placeholder="value"
          id={label}
          name={label}
          handleChange={compoChange}
          userInfo={ltv.value}
          defaultValue={dbValue ? dbValue[`${label}`] : ''}
        />
        {/* <ErrorMessage component="div" name={label} style={errorMessage} /> */}
      </>
    ),
    text: (
      <>
        <input
          key={label}
          className={`form-control ${halfWidth ? 'col-md-6' : ''}`}
          type="text"
          id={label}
          name={label}
          placeholder="value"
          onChange={compoChange}
          defaultValue={dbValue ? dbValue[`${label}`] : ''}
        />

      </>
    ),
    hidden: (
      <input
        key={label}
        className={`form-control ${halfWidth ? 'col-md-6' : ''}`}
        type="hidden"
        id={label}
        placeholder="value"
        // onChange={compoChange}
        defaultValue={dbValue ? dbValue[`${label}`] : ''}
        // defaultValue={dbValue[`${label}`]}
      />
    )
    ,approval: (
      <textarea
        key={label}
        className="form-control"
        // type="textarea"
        id={label}
        // value={value || ''}
        placeholder="value"
        onChange={compoChange}
        defaultValue={dbValue ? dbValue[`${label}`] : ''}
      />
    )
    ,editor: (
        <Editor
            key={label}
            id={label}
            handleChange={compoChange}
            // label={label}

            defaultValue={dbValue ? dbValue[`${label}`] : ''}
        />
    )
    ,textarea: (
      <textarea
        key={label}
        className="form-control"
        // type="textarea"
        id={label}
        // value={value || ''}
        placeholder="value"
        onChange={compoChange}
        defaultValue={dbValue ? dbValue[`${label}`] : ''}
      />
    ),
    date: (
      <input
        className="form-control"
        key={label}
        type="date"
        id={label}
        required
        onChange={compoChange}
        defaultValue={formDate}
      />

    ),
    number: (
      <input
        className="form-control"
        key={label}
        type="number"
        id={label}
        placeholder="value"
        onChange={compoChange}
        defaultValue={dbValue ? dbValue[`${label}`] : ''}
      />
    ),
    selectSingle: (
      <BasicSingle
        key={label}
        options={selectOptions}
        id={label}
        // value={value || ''}
        placeholder="value"
        handleChange={compoChange}
        defaultValue={dbValue ? dbValue[`${label}`] : ''}
      />
    ),
    selectMulti: (
      <Multi
        key={label}
        options={selectOptions}
        id={label}
        value={value || ''}
        placeholder="value"
        handleChange={compoChange}
        // onChange={(e) => onChangeForm(e, index)}
      />
    )
    ,BasicSingle: (
      <BasicSingle 
       
       options={selectOptions} 
       key={label}
       
       defaultValue="strawberry" />
    ),

    taskGantt: (
      <GanttGroup
        className="form-control"
        key={label}
        type="text"
        id={label}
        // value={value || ''}
        tasks=""
        placeholder="value"
        onChange={(e) => onChange(e, index)}
      />
    ),
    file: (
      <MyDropzone
        id={label}
        key={label}
        handleChange={compoChange}
        defaultValue={dbValue ? dbValue[`${label}`] : ''}
      />
    ),
    comment: 
    <Comments commentsItem={commentsItem} 
    key={label}

    ref={commentsRef} />
    ,
    includePage: (
        <DyPageInc
            key={label}
            type="text"
            id={label}
            placeholder="value"
            onChange={(e) => onChange(e, index)}
        />
    ),
    calendar: (
        <FullCalendarForm
            key={label}
            type="text"
            id={label}
            placeholder="value"
            onChange={(e) => onChange(e, index)}
        />
    ),
    radio: (
        <input
          className="form-control"
          key={label}
          type="radio"
          id={label}
          // value={value || ''}
          placeholder="value"
          onChange={compoChange}
          defaultValue={dbValue ? dbValue[`${label}`] : ''}
      />
    )
    ,cardProps: (
        <input
            className="form-control"
            key={label}
            type="text"
            id={label}
            // value={value || ''}
            placeholder="value"
            onChange={compoChange}
            defaultValue={dbValue ? dbValue[`${label}`] : ''}
        />
    )
    ,default:
        <input
            className="form-control"
            key={label}
            type="text"
            id={label}
            // value={value || ''}
            placeholder="value"
            onChange={compoChange}
            defaultValue={dbValue ? dbValue[`${label}`] : ''}
        />

  });

  const fl = i18f(`views.common.${label}`);

  return (
    <div className="form-group">
      <div className="input-group">
        {label === 'hide' || type === 'hidden' ? (
          <div className="col-sm-12 conainter-fluid">
            {getPropsReal(label, type, value)[type]}
          </div>
        ) : (
          <>

            <div className="col-sm-12 conainter-fluid">
              {getPropsReal(label, type, value)[type]}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export function i18f(m: string) {
  const {t} = i18n;
  return t(m);
}

 