import {createSlice} from '@reduxjs/toolkit';
import {ACCESS_TOKEN, REFRESH_TOKEN} from '../../constants';

export interface AuthState {
  isLoggedIn: boolean;
  accessToken: string | null;
  refreshToken: string | null;
  currentUser: any;
  permission: string;
}

const initialState: AuthState = {
  isLoggedIn: !!window.localStorage.getItem(ACCESS_TOKEN),
  // token: localStorage.getItem(ACCESS_TOKEN),
  accessToken: window.localStorage.getItem(ACCESS_TOKEN),
  refreshToken: window.localStorage.getItem(REFRESH_TOKEN),
  permission: '',
  currentUser: {}
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginUser: (state, {payload}) => {
      console.log("auth__________state=", state, "   payload=", payload);

      window.localStorage.setItem(ACCESS_TOKEN, payload.accessToken);
      window.localStorage.setItem(REFRESH_TOKEN, payload.refreshToken);
      // window.localStorage.setItem("corp_cd", payload.data.corp_cd);

      state.isLoggedIn = true;
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
      state.permission = payload.permission;
      state.currentUser = payload.currentUser;
    },
    logoutUser: (state) => {
      console.log("_________removeAccessToken____________");
      window.localStorage.removeItem(ACCESS_TOKEN);
      window.localStorage.removeItem(REFRESH_TOKEN);
      state.currentUser = {};
      state.isLoggedIn = false;
      state.accessToken = null;
      state.refreshToken = null;
    },
    loadUser: (state, {payload}) => {
      state.currentUser = payload;
    }
  }
});

export const {loginUser, logoutUser, loadUser} = authSlice.actions;

export default authSlice.reducer;

export const hasRole = (user, roles) =>
    roles.some(role => user.roles.includes(role));