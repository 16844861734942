import React, {useEffect, useState, useRef, useCallback} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import i18n from '@app/utils/i18n';
import {ContentHeader, MenuItem} from '@components';
import {useSelector} from 'react-redux';
import {getMenu} from '@app/utils/APIUtils';
import {toast} from 'react-toastify';
import './Pjrg100i.css';
import {Form} from '../../common/Form';
import {getInputs} from '../../common/inputs/lib/getInputs';
import store from "@store/store";
import {logoutUser} from "@store/reducers/auth";

interface RegisterFormType {
}

let title = '';

const DyPage = () => {
    const useparams = useParams();
    const navigate = useNavigate();
    const user = useSelector((state: any) => state.auth.currentUser);
    const writer_object = {user_id: user.user_id, record_nm: user.record_nm, create_dtm: new Date()};

    const {state} = useLocation();
    let proj_cd = state.params.proj_cd !== undefined ? state.params.proj_cd : '';
    if (proj_cd === ''){
        proj_cd = window.localStorage.getItem('proj_cd') === 'undefined' ? '' : window.localStorage.getItem('proj_cd');
    }

    console.log('=============================================PAGE_RELOAD_state====================================', user);

    let openPage = false;

    console.log('state=========', state);

    const [ltvp, setLtvp] = useState();
    const [movepage, setMovepage] = useState(false);
    const [pageID, setPageID] = useState(useparams.id);
    const [values, setValues] = useState(state.params);
    const [form_table, setForm_table] =useState(state.params.form_table);
    const [program_url, setProgram_url] =useState(state.params.program_url);


    //logging local storage key, values
    for(let i = 0; i < window.localStorage.length; i++) {
        const key = window.localStorage.key(i);
        const value = window.localStorage.getItem(key);
        console.log("key==="+key + " value===" + value );
    }


    console.log("[[[PAGE_RELOAD]]]\n nuseparams:", useparams, "\nstate.params:", state.params, "\npageID:", pageID, "\nuserparams.id:", useparams.id, '\nvalues:', values, '\nltvp:', ltvp
        // , '\nprogram_url:', program_url
    );


    let p = {
        userInfo: {...user,refresh_token:""},
        corp_cd: user.corp_cd,
        proj_cd: proj_cd,
        ver: '1',
        prog_id: useparams.id,
        prog_no: state.params.prog_no,
        program_url: state.params.program_url
        // program_url: state.params.program_url !== undefined ? state.params.program_url:program_url
        ,form_table: form_table
    };

    console.log("Dypage_p====", p);
    const lng = i18n.language;
    const constValues = useCallback(async () => {
        const a = await setValues(state.params);

        console.log('______________CONST_VALUES_ltvp__________________', ltvp);
        console.log('______________state__________________', state);

        // p = ltvp !== undefined ? ltvp.p : {};
        // const form_value = p.form_value;

        let ltvpv = {...ltvp, ...{p}, ...{values: state.params}};

        // let ltvpv = {...ltvp, ...ltvp.p, ...{values: state.params}};

        const c = await getInputs<RegisterFormType>({...ltvpv});
        const b = await setLtvp(c);

        console.log('______________ltvpv__________________', ltvpv);
        console.log('______________ltvpv_in_value_upldate_after__________________', ltvp);

    }, [state, useparams.id]);


    const constMovepage = useCallback(() => {
        setMovepage(false);
    }, [pageID, useparams.id]);

    const constPageId = useCallback(() => {
        setPageID(useparams.id);
    }, [useparams.id]);

    const constLtvp = useCallback(async () => {
        try {
        console.log("######################################################");
        console.log("###                    getMenu                      ##");
        console.log("######################################################");

        let response = await getMenu(user, pageID);

        console.log("===SETLTV===", response, 'pageID=', pageID)

            let ltv = response.data[0].form_json;
            let menu_nm_short = response.data[0].menu_nm_short;
            let program_url_tmp = response.data[0].program_url;
            let form_table_tmp = response.data[0].form_table;

            title = i18n.t(`menusidebar.label.${menu_nm_short}`);
            p = {...p, form_table: form_table_tmp, program_url: program_url_tmp, menu_nm_short, writer_object, lng};

            const ltvpv = {ltv, ...{p}, ...{values: state.params}};
            console.log('=========================ltvpv============================', ltvpv);

            if (ltv !== undefined && JSON.stringify(ltv).indexOf("validations") > 0) {
                console.log('=========================ltv_validation============================', {ltv, ...{p}});
                setLtvp(() => getInputs<RegisterFormType>({...ltvpv}));
            } else {
                setLtvp({...ltvpv});
            }
            console.log('p===================================================', p);

            setForm_table(form_table_tmp);
            setProgram_url(program_url_tmp);
            setMovepage(true);
        }catch(e){
console.log("e=====",e);
            toast.info("로그인해 주세요.");
            store.dispatch(logoutUser());
        }
    }, [pageID]);

    useEffect(() => {
        console.log('______________constPageId____________________', pageID);
        constPageId();
    }, [constPageId]);

    useEffect(() => {
        constValues();
    }, [constValues]);

    useEffect(() => {
        console.log('______________constMovepage____________________', movepage);
        constMovepage();
    }, [constMovepage]);

    useEffect(() => {
        console.log('______________constLtvp___________________', ltvp);
        constLtvp();

        return () => {
            console.log('______________CLEAR_MOVEPAGE____________________', movepage);
            setMovepage(false);
        }
    }, [constLtvp]);

    let lastUpdate;
    const handleChange =  (e) => {
        lastUpdate = Date.now();
        const timer = setTimeout(function () {
            const diff = Date.now() - lastUpdate;
            if (e.id || e.target.id) {
                console.log('e.id==', e.id, 'e.value==', e.value);
                const name = e.id !== undefined ? e.id : e.target.id;
                const value = e.value !== undefined ? e.value : e.target.value;

                if (diff > 500) {
                    setValues({
                        ...values,
                        [name]: value
                    });
                    console.log("value========",value);
                }
            }
        }, 500);

        return () => {
            clearTimeout(timer);
        };

    };

    if (movepage) console.log("============================MOVE_PAGE_TRUE=========================================", pageID)

    return (
        <div>
            <ContentHeader title={title}/>
            <section className="content">

                {movepage && (
                    <Form
                        ltvp={ltvp}
                        dbValue={values}
                        handleChange={handleChange}
                    />
                )}

            </section>
        </div>
    );
};
export default DyPage;

