import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {MenuItem} from '@components';
import {PfImage} from '@profabric/react-components';
import styled from 'styled-components';
import {SidebarSearch} from '@app/components/sidebar-search/SidebarSearch';
import i18n from '@app/utils/i18n';
import {toast} from 'react-toastify';
import {getSidebarMenu} from '../../../utils/APIUtils';

export interface IMenuItem {
  name: string;
  path?: string;
  children?: Array<IMenuItem>;
}

const StyledBrandImage = styled(PfImage)`
  float: left;
  line-height: 0.8;
  margin: -1px 8px 0 6px;
  opacity: 0.8;
  --pf-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23) !important;
`;

const StyledUserImage = styled(PfImage)`
  --pf-box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b !important;
`;

export const sideBarMenu: IMenuItem[] = [];

const MenuSidebar = () => {
  const user = useSelector((state: any) => state.auth.currentUser);

  console.log("MenuSidebar_user===",user);
  const sidebarSkin = useSelector((state: any) => state.ui.sidebarSkin);
  const menuItemFlat = useSelector((state: any) => state.ui.menuItemFlat);
  const menuChildIndent = useSelector((state: any) => state.ui.menuChildIndent);

  //  const [IMenuItem, setIMenuItem] = useState(false);

  const [sideBarMenu, setSideBarMenu] = useState([
    {
      name: i18n.t('menusidebar.label.dashboard'),
      path: '/'
    },
    {
      name: i18n.t('menusidebar.label.blank'),
      path: '/blank'
    },

    {
      name: i18n.t('menusidebar.label.mainMenu2'),
      path: '/mainMenu2'
    }
  ]);

  function rep(res: any) {
    console.log("res======",res);
    // const result = res.map((item: any, index: any) => {
    return {
      ...res,
      name: i18n.t(`menusidebar.label.${res.name}`)
      // children: []
    };
    // });
  }

  useEffect(() => {
    console.log(
      '_____________________user______________________________',
      user
    );
    getSidebarMenu(
         user.role1_cd
        ,user.user_id
        ,user.corp_cd
    )
      .then((response) => {
        const arr = response.data;

        const {res} = arr.reduce(
          (acc: any, curr: any) => {
            if (acc.parentMap[curr.upper_menu_id]) {
              (acc.parentMap[curr.upper_menu_id].children =
                acc.parentMap[curr.upper_menu_id].children || []).push(curr);
            } else {
              acc.res.push(curr);
            }
            acc.parentMap[curr.menu_id] = curr;
            return acc;
          },
          {parentMap: {}, res: []}
        );
        console.log('res===', res);
        setSideBarMenu(res);
      })
      .catch((error) => {
        if (error.status === 401) {
          toast.error('Error loading sidebar menu!');
          // setAuthLoading(false);
        }
      });
  }, []);

  return (
    <aside className={`main-sidebar elevation-4 ${sidebarSkin}`}>
      <Link to="/" className="brand-link">
        <StyledBrandImage
          src="/img/logo.png"
          alt="PMS Logo"
          width={33}
          height={33}
          rounded
        />
        <span className="brand-text font-weight-light">RPMS</span>
      </Link>
      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <StyledUserImage
              src={user === undefined? '' : user.picture}
              fallbackSrc="/img/default-profile.png"
              alt="User"
              width={34}
              height={34}
              rounded
            />
          </div>
          <div className="info">
            <Link to="/profile" className="d-block">
              {user === undefined? '' : user.record_nm}
            </Link>
          </div>
        </div>

        <div className="form-inline">
          <SidebarSearch />
        </div>

        <nav className="mt-2" style={{overflowY: 'hidden'}}>
          <ul
            className={`nav nav-pills nav-sidebar flex-column${
              menuItemFlat ? ' nav-flat' : ''
            }${menuChildIndent ? ' nav-child-indent' : ''}`}
            role="menu"
          >
            {sideBarMenu.map((menuItem: IMenuItem) => (
              <MenuItem
                key={menuItem.name + menuItem.path}
                menuItem={menuItem}
              />
            ))}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default MenuSidebar;
