import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import i18n from '@app/utils/i18n';

import {useSelector} from 'react-redux';
import {useDropzone} from 'react-dropzone';
import styled from 'styled-components';
// import './index.css';
import './dropzone.css';

import {toast} from 'react-toastify';
import {API_BASE_URL} from '@app/constants';
import {useLocation} from 'react-router-dom';
import {ErrorMessage} from '../../common/ErrorMessage';
import {CustomInputProps} from '../inputs/types';
import {uploadToServer} from '../../../utils/APIUtils';

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return '#eeeeee';
};

const Container = styled.div`
  flex: 1;
  display: 'flex';
  
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-width: 2px;
  border-radius: 10px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: ${(props) => {
    props.isDarkMode ? '#212529' : 'white';
  }};
  color: black;
  font-weight: bold;
  font-size: 1.4rem;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

function downloadFile(fileId: string) {
  // alert('fileId=========' + fileId);
  const url = `${API_BASE_URL}/file/get/downloadFile?svr_file_id=${fileId}`;
  const download = document.createElement('a');

  download.href = url;
  download.setAttribute('download', fileId);
  download.setAttribute('type', 'application/json');
  download.click();
}

// function DropBox(props) {

const DropBoxForm = ({
  type,
  label, 
  // dbValue,
  ...props
}: CustomInputProps) => {
  const {
    register,
    formState: {errors}
  } = useFormContext();
  // console.log('DropBoxForm_props###########################', props, "dbValue######",props.dbValue);
  const error = errors[label]?.message as string | undefined;
  const user = useSelector((state: any) => state.auth.currentUser);
  const {onDrop, handleChange, readonly} = props;
  const attId = props.id;
  const [files, setFiles] = useState({});
  const {state} = useLocation();
console.log("state=======", state);
  // console.log('props.isDarkMode=', props.isDarkMode);
  const [darkMode, setDarkMode] = useState(false);

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    open,
    isDragAccept,
    isFocused,

    isDragReject
  } = useDropzone({
    // accept: 'image/*',
    accept: '*',
    onDrop,
    noClick: true,
    noKeyboard: true,

    maxSize: 10 * 1024 * 1024 //10 MBs
  });

  let data = eval(props.defaultValue) || [];
  console.log('file_data2=========', data);
  let f2 = [];
  f2 = data.map((key, i) => ({
    name: data[i].local_file_name,
    size: data[i].file_size,
    type: data[i].file_extension,
    fileId: data[i].file_id,
    fileGubunCd: data[i].file_gubun_cd
  }));

  const [selectedFiles, setSelectedFiles] = useState(f2); 
  const [toBeUploaded, setToBeUploaded] = useState(false);

  // console.log('_____________selectedFiles1____________', selectedFiles);
  const constSelectedFiles  = useCallback(() => setSelectedFiles(f2),    [props.defaultValue]  ); 
  // const constSelectedFiles  = useCallback(()    => setSelectedFiles(selectedFiles),    [selectedFiles]  ); 
  const constToBeUploaded   = useCallback((arg) => setToBeUploaded(arg),    []  );
  
  // useEffect(() => {
  //   console.log('__________________constSelectedFiles_________________');
  //   constSelectedFiles();
  // }, [selectedFiles]);


  useEffect(() => {
    // console.log('__________________constSelectedFiles_________________');
    constSelectedFiles();
  }, [constSelectedFiles]);

  useEffect(() => { 
      // console.log('__________________Before_uploadFiles________________',selectedFiles, 'toBeUploaded==',toBeUploaded);
      if(toBeUploaded){
         uploadFiles();
      // constToBeUploaded(false); 
         setToBeUploaded(false);
         // console.log('__________________Before_uploadFiles22222________________',selectedFiles, 'toBeUploaded==',toBeUploaded);
      }
  }, [toBeUploaded]);

  // console.log('_____________selectedFiles2____________', selectedFiles);
  const dragOver = (e) => { 
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
  };

  const fileDrop = (e) => { 
    e.preventDefault();
    const {files} = e.dataTransfer;

    // console.log('fileDrop_files_______________', files);
    if (files.length) {
      constToBeUploaded(true);
      if (handleFiles(files)) {
        // console.log('selectedfiles_______________', selectedFiles);
        // uploadFiles();
      }

      // console.log('setSelectedFile111_________', selectedFiles);
    }

    // console.log('fileDrop_setFiles______________', files);
  };

  const handleFiles = (files) => {
    // console.log('handleFiles=', files, 'files_0_name=', files[0].name);
    try {
      for (let i = 0; i < files.length; i++) {
        if (validateFile(files)) {
          // console.log('________validateFile[0]name__________', files[i].name);
          const tempFile = [
            {
              name: files[i].name,
              size: files[i].size,
              type: files[i].type
            }
          ];
          
          // console.log('tempFile__________', tempFile);
          setSelectedFiles((prevArray) =>
            // console.log('prevArray=', prevArray, 'tempFile=', tempFile);
            [...prevArray, ...tempFile]
          );
          // console.log('setSelectedFiles000_________', selectedFiles);
        } else {
          // console.log('________setUnsupportedFiles__________', files[i]);
          // setUnsupportedFiles((prevArray) => [...prevArray, files[i]]);
        }
      }
    } catch (e) {
      toast.error('Error in uploading files...');
      return false;
    }
    return true;
  };

  const validateFile = (file) => {
    // const validTypes = [
    //   'image/jpeg',
    //   'image/jpg',
    //   'image/png',
    //   'image/gif',
    //   'image/x-icon'
    // ];
    // if (validTypes.indexOf(file.type) === -1) {
    //   return false;
    // }
    return true;
  };

  const fileSize = (size) => {
    if (size === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return `${parseFloat((size / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
  };

  const fileType = (fileName) => {
    return (
      fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) ||
      fileName
    );
  };

  const removeFile = (name) => {
    const selectedFileIndex = selectedFiles.findIndex((e) => e.name === name);
    constToBeUploaded(true);

    selectedFiles.splice(selectedFileIndex, 1);
    setSelectedFiles([...selectedFiles]);

    acceptedFiles.splice(selectedFileIndex, 1);

    // uploadFiles();
  };
  const [imageSent, setImageSent] = useState([]);

  const uploadFiles = () => {
    const formData = new FormData();
    selectedFiles.map((file, i) => {
      if (file === undefined) {
        return;
      }
        console.log(`data[${i}].name====`, file.name, 'file0name=', file.name);
      const variables = [
        // {
        //   file,
        //   name: file.name
        // }
      ];
      formData.append(
        `file-${i}`,
        new Blob([JSON.stringify(variables)], {type: 'application/json'}),
        file.name
      );
    });
    formData.append('corp_cd', user.corp_cd);
    formData.append('user_id', user.user_id);
    formData.append('upload_path', state.name);
    if (state.name === 'PJTRegistration') {
      formData.append('foreignKey', state.params.proj_cd);
    }else if (state.name === 'PJTPerformanceRegistration') {
      formData.append('foreignKey', props.dbValue.proj_cd||'_'||props.dbValue.task_cd);
    }

    console.log(`formData========`, formData);

    try {
      uploadToServer(formData)
        .then((res) => {
          console.log("attId========", attId);     
         
          handleChange({
            id: attId,
            value: res
          });
        })
        .catch((err) => console.log('error=', err));
    } catch (e) {
      console.log('uploadfile_error=', e);
    }
  };

  // console.log("before_droppedFiles_selectedFiles==",selectedFiles);

  const showHide = readonly ? 'none' : '';
  // console.log('showHide===', showHide);
  // console.log('readonly===', readonly);

  let droppedFiles = [];
  // if(selectedFiles !== undefined){
      droppedFiles = selectedFiles.map((data, i) => {
      // console.log('data=====', data);
      if (data === undefined) {
        return;
      }
      return (
        <div className="file-status-bar" key={`d${i}`}>
          <div>
            <div className="file-type-logo" />
            <span className="file-name">
              <a
                href="#"
                onClick={() => downloadFile(data.fileId)}
                target="_blank"
              >
                {data.name}
              </a>
            </span>
            <span className="file-size">({fileSize(data.size)})</span>
            <div className="file-remove" onClick={() => removeFile(data.name)}>
              {readonly? '': 'X'}

            </div>
          </div>
        </div>
      );
    });
  // }
  const id = label;
  const fl = i18f(`views.common.${label}`);

  return (
    <div className="form-group">
      <div className="input-group">
        {id && (
          <label className="col-sm-3 col-form-label text-center" htmlFor={id}>
            {fl}
          </label>
        )}
        <div className="col-sm-9 conainter-fluid">
          <div>
            {/* <input
          className="form-control"
          {...register(label, {
            onChange: (e) => {
              props.handleChange(e);
            }
          })}
          {...props}
          id={label}
        /> */}

            <section
              onDragOver={dragOver}
              onDragEnter={dragEnter}
              onDragLeave={dragLeave}
              onDrop={fileDrop}

            >
              {/*this div custom div by john*/}
              <div style={{display:`${showHide}`}}>
              <Container
                className="dropbox"
                {...getRootProps({isDragAccept, isFocused, isDragReject})}
              >
                     <input {...getInputProps({})} />
                     Drag & drop files
              </Container>
              </div>
            </section>
            <aside>
              <div className="file-display-container">{droppedFiles}</div>
            </aside>
            <ErrorMessage error={error} />
          </div>
        </div>
      </div>
      {/*<hr className="bg-gray-400 my-4"/>*/}
    </div>
  );
};
export default DropBoxForm;

function i18f(m: string) {
  const {t} = i18n;
  return t(m);
}
