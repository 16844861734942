import React from 'react';
import {Navigate, Outlet, useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {logoutUser} from '@app/store/reducers/auth';
import {toast} from 'react-toastify';

const PrivateRoute = () => {
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const userEmail = useSelector((state: any) => state.auth.currentUser.email); // mail@example.com
  // console.log('userEmail==', userEmail);

  // const logOut = (event: any) => {
  //   event.preventDefault();
  //   dispatch(logoutUser());
  //   navigate('/login');
  // };

  const location = useLocation();
  const pageModule = location.pathname.split('/')[1];

  const proj_cd = window.localStorage.getItem('proj_cd');

  const refreshToken = window.localStorage.getItem('refreshToken');

  if (refreshToken === undefined || refreshToken === null) {
    // return <Redirect to='/somePage'/>;
    dispatch(logoutUser());
    navigate('/login');
  } else if (pageModule === 'PJ' && proj_cd === '') {
    navigate('/PJ/RG/PJRG100l');
    toast.info('Select Project please...');
  }

  return isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
