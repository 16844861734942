import React, {useEffect, useState, useRef, useCallback} from 'react';
import {PfButton} from '@profabric/react-components';

import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import i18n from '@app/utils/i18n';
import {ContentHeader, MenuItem} from '@components';

import {useSelector} from 'react-redux';
import {FullScreen, useFullScreenHandle} from 'react-full-screen';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faArrowsAlt,
  faExpandArrowsAlt
} from '@fortawesome/free-solid-svg-icons';

import BasicSingle from '@app/pages/common/selectBox/BasicSingle';
import Multi from '@app/pages/common/selectBox/Multi';
import {toast} from 'react-toastify';
import Input from './Input';

import InputReal from './InputReal';
import './Cmpc100i.css';

import {getAllMenu, colNmReq, menuNmReq,  updateCompoJson, getCompo} from '../../../utils/APIUtils';
import VisualForm from "@pages/cm/pc/VisualForm";
// import SelectInput from '@material-ui/core/Select/SelectInput';

export function i18f(m: string) {
  const {t} = i18n;
  return t(m);
}

const Cmpc000i = () => {
  const user = useSelector((state: any) => state.auth.currentUser);
  const [values, setValues] = useState({});
  const [menuNm, setMenuNm] = useState();
  const [colNm, setColNm] = useState();
  const [tableName, setTableName] = useState();
  const [tableList, setTableList] = useState();

  useEffect(() => {
    window.tale_name = tableName;
  }, [tableName]);

  const [selectOptions, setSelectOptions] = useState([]);
  const {state} = useLocation();
  const title = i18n.t(`menusidebar.label.${state.name}`);
  const [compoValues, setCompoValues] = useState([]);

  console.log( 'PAGE_LOAD=============',compoValues, ' state========',state );
  const [toggle, setToggle] = useState(false);
  const inputRef = useRef();
  const selectRef = useRef();
  const selectMenuRef = useRef();

  //drag start
  const dragItem = useRef();
  const dragOverItem = useRef();
  const dragStart = (e, position) => {
    dragItem.current = position;
    console.log(e.target.innerHTML);
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    console.log(e.target.innerHTML);
  };

  const drop = (e) => {
    const copyListItems = [...compoValues];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setCompoValues(copyListItems);
  };
  //drag end

  let navigate = useNavigate();
  const saveHandleSubmit = (e: {preventDefault: () => void}) => {
    e.preventDefault();
    let compoCd = '';

    console.log("selectMenuRef==",selectMenuRef);
    if (selectMenuRef.current) {

      compoCd = selectMenuRef.current.value;

      console.log("compoCd==",compoCd);

      const compoSubCd = compoValues[0].label;

      const fv = JSON.stringify(compoValues);

      console.log("fv==",fv);
      updateCompoJson(compoCd,compoSubCd, fv, user)
          .then((response) => {
            const arr = response.data;
            toast.success('저장 하였습니다.');

            navigate('/CM/PC/CMPC000I',{
              state: {...state}
            });

          })
          .catch((error) => {
            if (error.status === 401) {
            }
            toast.error('저장에 실패 하였습니다.');
          });
    }
  };

  function isJsonString(str) {
    try {
      if (str === '') return false;

      JSON.parse(str);
    } catch (e) {
      // console.log('str_is_not_jsonFormat__', str, e);
      return false;
    }
    return true;
  }


  let lastUpdate;
  const onChangeForm = (e: any, index: number) => {
    console.log('onChangeForm_e____________', e, 'index___', index);

    lastUpdate = Date.now();
    const timer = setTimeout(function () {
      const diff = Date.now() - lastUpdate;

      if (diff > 1000) {
        let values = null;
        values = [...compoValues];

        if (e.target) {
          console.log(' e.target.value___', e.target.value);
          if (e.target.value) {
            values[index].value = e.target.value;
          } else {
            values[index].value = '';
          }
        }
        console.log('values__________', values);

         setCompoValues(values);

      }
    }, 1000);

    return () => {
      clearTimeout(timer);

      console.log('_________clearTimeout_Formsortinig_____________');
    };
  };

  const onChangeFormReal = (e: any) => {
    console.log(' onChangeFormReal___e.target______________', e);

    if ((e && e.id !== undefined) || (e.target && e.target.id !== undefined)) {
      const name = e.id !== undefined? e.id : e.target.id;
      const value = e.value !== undefined ? e.value: e.target.value;

      setValues({
        ...values,
        [name]: value
      });

      console.log('valuesonChangeFormReal______________', values);
    }

  };

  const handleChange = (e: any, index: number) => {

    console.log('handleChange========', e.target.value);
    getCompo(e.target.value)
        .then((response) => {
          const arr = eval(response.rows[0].compo_json);
          console.log(
              'formReq22============================',
              arr
          );
          // setCompoValues(arr);
          if (response.rows !== undefined) setCompoValues(arr);
          else setCompoValues([]);
        })
        .catch((error) => {
          setCompoValues([])
          // console.error('Exception_loading_compo_____!',error);
          // if (error.status === 401) {
          //   // console.error('Error loading sidebar menu!');
          // }
        });
    //
    // if (e.compo_json) setCompoValues(eval(e.compo_json));
    // else setCompoValues([]);
  };

  const handleAddField = (e: {preventDefault: () => void}) => {
    e.preventDefault();

    const values = [...compoValues];
    values.push({
      label: inputRef.current.value || 'label',
      type: selectRef.current.value || 'text',
      value: ''
    });
    // values.sort((a, b) => (a.order > b.order ? 1 : -1));
    setCompoValues(values);
    setToggle(false);
  };

  const addBtnClick = (e: {preventDefault: () => void}) => {
    e.preventDefault();
    setToggle(true);
  };

  const handleDeleteField = (e: any, index: number) => {
    const values = [...compoValues];
    values.splice(index, 1);
    setCompoValues(values);
  };

  const screen10 = useFullScreenHandle();
  const screen20 = useFullScreenHandle();

  const reportChange = useCallback((state, handle) => {
    if (handle === screen10) {
      console.log('Screen 1 went to state= ', state, ' handle=', handle);
    }
    if (handle === screen20) {
      console.log('Screen 2 went to state= ', state, ' handle=', handle);
    }
  }, [screen10, screen20]);


  return (

      <div  >
        <ContentHeader title={title} />
        <section className="content">
          <div className="card-deck container-fluid ">
            <div className="card col-sm-6"  style={{height: 800 + 'em'}}>
              <div className="card-header">
                <h3 className="card-title">Title</h3>

                <div className="card-tools">
                  <button
                      type="button"
                      className="btn btn-tool"
                      title="Expand screen"
                      onClick={screen10.enter}
                  >
                    <FontAwesomeIcon icon={faExpandArrowsAlt} />
                  </button>
                </div>
              </div>
              <div className="card-body">


                <FullScreen handle={screen10}  onChange={reportChange}>
                  {screen10.active && (
                      <button type="button" onClick={screen10.exit}>
                        <FontAwesomeIcon icon={faArrowsAlt} />
                      </button>
                  )}

                  <form className="form-horizontal">
                    <div className="form-group">
                      <div className="input-group">
                        <label htmlFor="Name" className="col-sm-2 col-form-label">
                          Component
                        </label>
                        <div className="col-sm-9">
                          <select  className="form-control" onChange={handleChange}  ref={selectMenuRef}>
                            <option value="approval">approval</option>
                            <option value="button">button</option>
                            <option value="cardProps">cardProps</option>
                            <option value="calendar">calendar</option>
                            <option value="comment">comment</option>
                            <option value="date">date</option>
                            <option value="dept">dept</option>
                            <option value="deptMulti">deptMulti</option>
                            <option value="editor">editor</option>
                            <option value="email">email</option>
                            <option value="file">file</option>
                            <option value="grid">grid</option>
                            <option value="hidden">hidden</option>
                            <option value="includePage">includePage</option>
                            <option value="number">number</option>
                            <option value="password">password</option>
                            <option value="radio">radio</option>
                            <option value="selectSingle">selectSingle</option>
                            <option value="selectMulti">SelectMulti</option>
                            <option value="taskGantt">taskGantt</option>
                            <option value="text">text</option>
                            <option value="textarea">textarea</option>
                            <option value="user">user</option>
                          </select>

                        </div>
                      </div>
                    </div>

                    {
                      compoValues.map((obj, index) => (
                          <Input
                              key={crypto.randomUUID()}
                              objValue={obj}
                              onChange={onChangeForm}
                              index={index}
                              deleteField={handleDeleteField}

                              draggable
                              onDragStart={(e) => dragStart(e, index)}
                              onDragEnter={(e) => dragEnter(e, index)}
                              onDragEnd={drop}
                              onDragOver={(e) => e.preventDefault()}
                          />
                      ))}

                    {!toggle ? (
                        <div className="offset-sm-6">
                          <button
                              type="button"
                              className="btn btn-primary"
                              onClick={addBtnClick}
                          >
                            Add new
                          </button>
                        </div>
                    ) : (
                        <div className="dialog-box">
                          <input type="text" placeholder="label" ref={inputRef} />
                          <select ref={selectRef} className="form-control">
                            <option value="approval">approval</option>
                            <option value="button">button</option>
                            <option value="cardProps">cardProps</option>
                            <option value="calendar">calendar</option>
                            <option value="comment">comment</option>
                            <option value="date">date</option>
                            <option value="dept">dept</option>
                            <option value="deptMulti">deptMulti</option>
                            <option value="editor">editor</option>
                            <option value="email">email</option>
                            <option value="file">file</option>
                            <option value="grid">grid</option>
                            <option value="hidden">hidden</option>
                            <option value="includePage">includePage</option>
                            <option value="number">number</option>
                            <option value="password">password</option>
                            <option value="radio">radio</option>
                            <option value="selectSingle">selectSingle</option>
                            <option value="selectMulti">SelectMulti</option>
                            <option value="taskGantt">taskGantt</option>
                            <option value="text">text</option>
                            <option value="textarea">textarea</option>
                            <option value="user">user</option>
                          </select>

                          <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleAddField}
                          >
                            Add
                          </button>
                        </div>
                    )}

                    <div className="offset-sm-10 col-sm-1">
                      <button
                          type="submit"
                          onClick={saveHandleSubmit}
                          className="btn btn-danger"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </FullScreen>

              </div>
            </div>

            <div className="card col-sm-6">
              <div className="card-header">
                <h3 className="card-title">View</h3>
                <div className="card-tools">
                  <button
                      type="button"
                      className="btn btn-tool"
                      title="Expand screen"
                      onClick={screen20.enter}
                  >
                    <FontAwesomeIcon icon={faExpandArrowsAlt} />
                  </button>
                </div>
              </div>


              <div className="card-body">
                <FullScreen handle={screen20}  onChange={reportChange}>
                  {screen20.active && (
                      <button type="button" onClick={screen20.exit}>
                        <FontAwesomeIcon icon={faArrowsAlt} />
                      </button>
                  )}



                  {compoValues.map((obj, index) => (
                      <VisualForm
                        key={index}
                        ltv={obj}
                        handleChange={onChangeFormReal}
                        index={index}
                        deleteField={handleDeleteField} dbValue={undefined}                    
                        />
                  ))}



                </FullScreen>

              </div>
            </div>
          </div>
        </section>
      </div>
  );
};
export default Cmpc000i;

export function getMultiSelectUrl() {
}


export function gw(m: any) {

}

function customRowNumber() {

}


function setAppvOpt(elem){

}