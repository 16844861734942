import * as Yup from 'yup';
import {AnyObject} from 'yup/lib/types';
import {toast} from 'react-toastify';
import {FormSection, InputProps} from '../types';
// import {forms} from '../lib';

type YupBoolean = Yup.BooleanSchema<
  boolean | undefined,
  AnyObject,
  boolean | undefined
>;
type YupString = Yup.StringSchema<
  string | undefined,
  AnyObject,
  string | undefined
>;
type YupNumber = Yup.NumberSchema<
  number | undefined,
  AnyObject,
  number | undefined
>;

const generateValidations = (
  field: InputProps
): YupBoolean | YupString | YupNumber | null => {
  if (!field.validations) return null;

  let schema = Yup[field.typeValue || 'string']();

  // eslint-disable-next-line no-restricted-syntax
  for (const rule of field.validations) {
    switch (rule.type) {
      case 'isTrue':
        schema = (schema as YupBoolean).isTrue(rule.message);
        break;
      case 'isEmail':
        schema = (schema as YupString).email(rule.message);
        break;
      case 'minLength':
        schema = (schema as YupString).min(rule.value as number, rule.message);
        break;
      case 'oneOf':
        schema = (schema as YupString).oneOf(
          [Yup.ref(rule.ref as string)],
          rule.message
        );
        break;
      default:
        schema = schema.required(rule.message);
        break;
    }
  }

  return schema;
};

// export const getInputs = <T>(section: FormSection) => {
export const getInputs = <T>(formData: object) => {
  let initialValues: {[key: string]: any} = {};
  let validationsFields: {[key: string]: any} = {};
  let clearErrors = [];

  // let initialValues = {};
  // let validationsFields = {};

 console.log('formData=', formData);
  // for (const field of forms[section]) {
  const eFormData = eval(formData.ltv);
  const eFormDataValues = formData.values;
  // const field = formData.value;

  const efl = eFormData === undefined ?  0 : eFormData.length;
  console.log('eFormDataValues=', eFormDataValues);
  // let clearErrors = false;
  try {
    // for (const field of formData) {
    for (let i = 0; i < efl; i++) {
      const field = eFormData[i];
      // const efv = eFormDataValues[i];
      // initialValues[field.label] = field.value.value || '';
      //  console.log('field.label=', field.label);
      // console.log('efv=', efv);

      if (field.value !== "" && field.type !== 'grid' && field.type !== 'approval' &&field.type !== 'deptMulti') {
         // console.log('field.value=', field.value);
      const objVal = JSON.parse(field.value);

        if (objVal.validations !== undefined && field.type !== 'grid') {
          // console.log('objVal==', objVal, 'objVal.value==', objVal.value);
          // console.log('objVal.validations==', objVal.validations);
          // console.log(' efv[field.label]==',  eFormDataValues[field.label]);
          if (eFormDataValues[field.label] !== undefined){
            initialValues[field.label] = eFormDataValues[field.label] || '';
            // clearErrors.push(field.label);

          }

          console.log(`initialValues=`, initialValues);

          const schema = generateValidations(objVal);

          validationsFields[field.label] = schema;

           console.log('schema=', schema);
        }
      }
    }
  } catch (e) {
    console.log('error=', e);
    toast.error('getInputs=', e);
  }

  return {
    // validationSchema: Yup.object({...validationsFields}),
    validationSchema: Yup.object({...validationsFields}),
    initialValues: initialValues as T,
    // inputs: forms[section]
    // inputs: formData
    // ltvp: formData,
    // ...formData,
    // clearErrors,
      values : eFormDataValues,
    ltv:eFormData,
      p: formData.p
  };

   

};
