import React, {
    RefObject, useEffect,
    useMemo, useRef,
    useState,
} from 'react';
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {CustomInputProps} from "@pages/common/inputs/types";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import i18n from "@app/utils/i18n";
import axios from "axios";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import DOMPurify from "isomorphic-dompurify";
import QuillImageDropAndPaste from 'quill-image-drop-and-paste'
import { ImageData } from 'quill-image-drop-and-paste'

//https://github.com/chenjuneking/quill-image-drop-and-paste#handler

export const QuillEditor = (
    {label, handleChange, ...props}: CustomInputProps
) => {
    // console.log("QuillEditor_props===", props);
    const [values, setValues] = useState();


    Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)
/*

    const quill = new Quill('#editor-container', {
        modules: {
            imageDropAndPaste: {
                // add an custom image handler
                handler: dropAndPasteHandler,
            },
        },
    })
*/

    const dropAndPasteHandler = async (imageData: string) => {
        /*
        imageData
            .minify({
                maxWidth: 320,
                maxHeight: 320,
                quality: 0.7,
            })
            .then((miniImageData) => {
                const blob = miniImageData.toBlob()
                const file = miniImageData.toFile()
                // create a form data, and upload to the server...
            })
        */
        const blob = dataURLtoBlob(imageData);
        // const blob = imageData.toBlob()
        // const file = imageData.toFile();

        // duill.setSelectedFile(blob);

        const formData = new FormData();
        formData.append("file", blob);
        // formData.append("file", file);
        console.log("file===", file);
        formData.append('corp_cd', user.corp_cd);
        formData.append('user_id', user.user_id);
        formData.append('upload_path', state.name);

        const config = {
            mod: "cors",
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };

        const result = await axios.post("/file/uploadEditorImage", formData, config);
        const IMG_URL = "./itempic.get/" + result.data.pkey;
        // const IMG_URL = `/upload/${result.data[0].server_path}/${result.data[0].server_file_name}`;  //this doesnt work??

        const editor: any = quillRef?.current?.getEditor();
        if (editor) {
            const range = editor.getSelection();
            editor.insertEmbed(range.index, "image", IMG_URL);
        }
    };


    const modules = useMemo(() => {
        return {
            toolbar: {

                container: [
                    [{size: ['small', false, 'large', 'huge']}],
                    [{align: []}],
                    ['bold', 'italic', 'underline', 'strike', 'image', 'video'],
                    [{list: 'ordered'}, {list: 'bullet'}],
                    [{color: [],},{background: []},
                    ],
                ],

                // toolbar: {                container: toolbarOptions,            },
                imageDrop: true,
                clipboard: {
                    matchVisual: false
                    // toggle to add extra line breaks when pasting HTML:
                },

                imageDropAndPaste: {
                    handler: dropAndPasteHandler,
                }
            },
        };
    }, []);

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike',
        'color', 'background',
        'list', 'bullet', 'check',
        'indent',
        'align', 'direction',
        'link', 'image',
        "code-block",
        "script",
        "table",
    ]


    const user = useSelector((state: any) => state.auth.currentUser);
    const {state} = useLocation();

    const quillRef = useRef()

    // console.log('Editor_props############################', props);
    let defaultValue = props.defaultValue === undefined ? '' : props.defaultValue;

    const [editValue, setEditValue] = useState(defaultValue);

    // console.log("defaultValue===================================",defaultValue);

    useEffect(() => {
        // console.log('editValue====================================', editValue);
        label === undefined ? '' : label;
        handleChange({id: label, value: editValue});
    }, [editValue]);

    const id = `${label}`;

    const tmplabel = props.ltvp !== undefined ? props.ltvp.p.prog_id.toLowerCase() : '';
    const fl2 = i18f(`views.${tmplabel}.${label}`);
    let fl = i18f(`views.common.${label}`);
    fl = fl2.indexOf(label) > -1 ? fl : fl2;


    const dataURLtoBlob = (dataUrl: string) => {
        const arr = dataUrl.split(",");

        const mimeMatch = arr[0].match(/:(.*?);/);
        if (!mimeMatch || mimeMatch.length < 2) {
            throw new Error("Invalid data URL");
        }

        const mime = mimeMatch[1];
        const bstr = atob(arr[1]);
        const n = bstr.length;
        const u8arr = new Uint8Array(n);

        for (let i = 0; i < n; i++) {
            u8arr[i] = bstr.charCodeAt(i);
        }

        return new Blob([u8arr], { type: mime });
    };

    useEffect(() => {
        const quill = quillRef.current;
        const handleImage = () => {
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");
            input.click();
            input.onchange = async () => {
                const file = input.files[0];

                // 현재 커서 위치 저장
                const range = quill.selection

                // 서버에 올려질때까지 표시할 로딩 placeholder 삽입
                quill.getEditor().insertEmbed(range.index, "image", `/images/loading.gif`);

console.log("quill_file___", file);
                try {
                    const formData = new FormData();

                    formData.append('name', file.name);
                    formData.append('file', file);

                    formData.append('corp_cd', user.corp_cd);
                    formData.append('user_id', user.user_id);
                    formData.append('upload_path', state.name);
                    // const result = await actionUploadEditorImage(formData);
                    axios
                        .post('/file/uploadEditorImage', formData)
                        .then((result) => {
                             const res = result;
                            // let server_file_name=res.server_file_name;
                            // let server_path=res.server_path;
                            console.log("quill_______",quill);
                            // let file_path=server_path+"/"+server_file_name;
                            // const img_path = `${API_BASE_URL}/upload/${res.data[0].server_path}/${res.data[0].server_file_name}`;
                            const img_path = `/upload/${res.data[0].server_path}/${res.data[0].server_file_name}`;
                            console.log("res_______",res, " img_path___",img_path);
                            // 정상적으로 업로드 됐다면 로딩 placeholder 삭제
                            quill.getEditor().deleteText(range.index, 1);
                            // 받아온 url을 이미지 태그에 삽입
                            quill.getEditor().insertEmbed(range.index, "image", img_path);
                            // 사용자 편의를 위해 커서 이미지 오른쪽으로 이동
                            quill.getEditor().setSelection(range.index + 1);
                        })
                        .catch((err) =>
                            console.error("upload_error"));

                } catch (e) {
                    quill.getEditor().deleteText(range.index, 1);
                }
            };
        };

        if (quillRef.current) {
            const toolbar = quill.getEditor().getModule("toolbar");
            toolbar.addHandler("image", handleImage);
        }
    }, []);
/*


    editor.clipboard.addMatcher('img', (node: any) => {
        const blob = dataURLtoBlob(node.getAttribute('src'))
        const delta = new Delta()
        uploadMedia({
            file: blob
        }).then((res: any) => {
            editor.updateContents(
                new Delta().retain(editor.getSelection()?.index ?? 0).insert({
                    image: res.data.uploadMedia.mediaFile.url
                })
            )
        })
        return delta
    })
*/

    let edotor_lastUpdate;
    return (
        <>
            <div className="form-group">
                <div className="input-group">
                    {label && (
                        <label className="col-sm-3 col-form-label text-center" htmlFor={id}>
                            {fl}
                        </label>
                    )}
                    <div className="col-sm-9 conainter-fluid">
                        <div>
                            <ReactQuill
                                formats={formats}
                                style={{width: "100%", height: "400px", marginBottom: "40px"}}
                                // placeholder="Quill Content"
                                theme="snow"
                                ref={quillRef}
                                value={defaultValue}

                                onChange={(content, delta, source, editor) => {
                                    const quill = quillRef.current;
                                    edotor_lastUpdate = Date.now();
                                    const editor_timer = setTimeout(function () {

                                        const editor_diff = Date.now() - edotor_lastUpdate;

                                        const data = editor.getHTML();
                                        // console.log("______content=",content, " data=", data, " delta=", delta, ' source=', source, ' editor=', editor);
                                        console.log("content====",content," editor_interval====", editor_diff, " endwith=",data.endsWith("<p><br></p>"));

                                        if (editor_diff > 1000) {
                                            if (defaultValue !== undefined && data !== undefined && !data.endsWith("<p><br></p>")) {
                                                setEditValue(data);
                                                // editor.getSelection(focus = true);
                                            }
                                            // editor.focus();
                                        }
                                    }, 1000);

                                    return () => {
                                        clearTimeout(editor_timer);
                                        console.log('_________editor_clearTimeout_____________');
                                    };

                                }}
                                modules={modules}
                            />
{/*
                            <div
                                className="view ql-editor" // react-quill css
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(editValue),
                                }}
                            />
                            */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function i18f(m: string) {
    const {t} = i18n;
    return t(m);
}
