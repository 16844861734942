import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect
} from 'react';
import * as XLSX from 'xlsx';

import {useNavigate, Link} from 'react-router-dom';
import i18n from '@app/utils/i18n';

import {toast} from 'react-toastify';
import {API_BASE_URL} from '@app/constants';
import './jqGrid.css';

// import {jqGrid} from 'jquery';
// import $ from 'jquery';
// import mydata from '../../../utils/data';
import {gridSearch} from '../../../utils/APIUtils';

// import '../../../../public/common';

const $ = window.$;

// window.$ = $;
type jqItem = {
  jqItems?: Array<jqItem>;
};

let lastsel: any;
let url: any;
let postParams: any;
let pageWidth: any;

let $grid: any;
let $gridPager: any;
let gridId: any;
let gridPager: any;
let handleChange: any;
let gridLabel: any;
let defaultValue: any;

const JqGrid = forwardRef((props, ref) => {
  const navigate = useNavigate();

  console.log('JqGrid_props############################', props);

  const jg = props.jqItems[0];

  if (props.defaultValue !== undefined && props.defaultValue !== '') {
    defaultValue = JSON.parse(props.defaultValue);

    // console.log('Parsed_defaultValue--------------------', defaultValue);
  } else {
    defaultValue = [];
  }

  gridId = jg.gridId;

  url = jg.url;

  // console.log('gridId--------------------', gridId);
  gridPager = `${jg.gridId}Pager`;
  handleChange = props.handleChange;

  useImperativeHandle(ref, () => ({
    setFilter() {
      postParams = props.jqItems[0].postParams;
      pageWidth = props.jqItems[0].width;
      gridInit();
      gridSearchData();
    }
  }));

  const [rowDatas, setRowDatas] = useState([]);

  useEffect(() => {
    // console.log('rowDatas______________', rowDatas, props.handleChange);

    if (props.handleChange)
      handleChange({id: props.gridLabel, value: rowDatas});
  }, [rowDatas]); // use effect

  useEffect(() => {
    // console.log('rowDatas=', rowDatas, 'defaultValue=', defaultValue);
    if (url !== undefined) {
      gridInit();
      if (rowDatas.length !== 0) {
        console.log('rowDatas.length !== 0');

        setGridData(rowDatas);
      } else if (
        JSON.stringify(defaultValue) !== '[]' &&
        defaultValue !== '' &&
        defaultValue !== undefined
      ) {
        // console.log('JqGrid_defaultValue !== []', defaultValue);
        setGridData(defaultValue);
      } else if (rowDatas.length === 0) {
        gridSearchData();
      }
    }
  }, [props]);

  function gridInit() {
    $grid = $(`#${gridId}`);
    $gridPager = $(`#${gridId}Pager`);
    if ($.jgrid) {
      $(`#${gridId}`).GridUnload(`#${gridId}`);
    }

    // url = jg.url;
    postParams = {} || jg.postParams;

    // console.log('gridInit_JqGrid_url===', url);
    $grid.jqGrid({
      colModel: jg.colModel ? jg.colModel : false,
      autoencode: true,
      width: pageWidth,
      // rowNum: 10,
      rowNum: jg.rowNum ? jg.rowNum : false,

      toppager: jg.toppager ? jg.toppager : false,
      treeGrid: jg.treeGrid ? true : false,
      ExpandColumn: jg.ExpandColumn ? jg.ExpandColumn : false,

      treeReader: jg.treeReader ? jg.treeReader : false,
      shrinkToFit: jg.shrinkToFit ? jg.shrinkToFit : false,
      treeIcons: {leaf: 'glyphicon-list-alt'},
      cmTemplate: {align: 'center', editable: true},
      cellEdit: false,
      cellsubmit: 'clientArray',
      // contentType: 'text/html; charset=utf-8',
      contentType: 'application/json; charset=utf-8',
      autowidth: true,
      height: 'auto',
      altRows: true,
      threeStateSort: true,
      guiStyle: 'bootstrap4',
      iconSet: 'fontAwesome',
      idPrefix: 'gb1_',
      ignoreCase: true,
      loadonce: jg.loadonce ? jg.loadonce : false,
      pager: $gridPager,
      gridview: true,
      rownumbers: false,
      sortname: 'id',
      viewrecords: true,

      jsonReader: {
        repeatitems: false,
        page: 'page',
        total: 'total',
        root: 'rows',
        records: 'records'
      },
      rowList: false,
      sortorder: 'desc',
      // jsonReader: {
      //   root: defaultValue,
      //   repeatitems: false
      // },

      // pager: !jg.pager ? jg.pager : $gridPager,
      loadComplete() {
        // console.log('___________________loadComplete________________________');
        // expandAllClick(gridName);
      },
      afterEditCell() {
        // console.log('___________________afterEditCell_______________________');
      },
      afterSubmitCell() {
        // console.log('___________________afterSubmitCell_______________________'  );
      },
      beforeProcessing() {
        // console.log('___________________beforeProcessing________________');
      },

      beforeSelectRow() {
        // console.log(
        // '______beforeSelectRow_____________';
        // );
      },
      onSelectRow: jg.onSelectRow
        ? function (id: any) {
            if (id && id !== lastsel) {
              // $(`#${gridId}`).jqGrid('restoreRow', lastsel);
              // $(`#${gridId}`).jqGrid('editRow', id, true);
              lastsel = id;
            }
            <Link
              to="/PJ/RG/PJRG100i?projId=id"
              state={{menuEngName: 'PJTRegistration'}}
            />;
          }
        : function (id: any) {
            const rowData = $grid.jqGrid('getRowData', id);
            // console.log('rowData=', rowData);
            navigate('/PJ/RG/PJRG100i', {
              state: {name: 'PJTRegistration', params: rowData}
            });
          },
      ondblClickRow() {
        // console.log('___________________ondblClickRow________________________');
      }
    });

    $grid.navGrid(
      `#${gridPager}`,
      {
        cloneToTop: true,
        edit: !!jg.edit,
        add: !!jg.add,
        del: !!jg.del,
        search: !!jg.search,
        refresh: false
      },
      // edit
      {
        // beforeShowForm: true,
        top: '100',
        width: '800',
        left: '300',
        // height: '800',

        recreateForm: true,
        jqModal: false, // true means default jqModal setting
        checkOnUpdate: true,
        reloadAfterSubmit: true,
        closeOnEscape: true,
        closeAfterEdit: true,
        onclickSubmit: onclickSubmitLocal
      },
      {
        // add
        recreateForm: true,
        jqModal: true,
        checkOnUpdate: true,
        reloadAfterSubmit: false,
        addedrow: 'last',
        closeOnEscape: true,
        closeAfterAdd: true, //import.... multiple error
        onclickSubmit: onclickSubmitLocal
      },
      {
        // delete
        onclickSubmit: (options, rowid) => {
          // console.log('____________delete____onclickSubmit_________');
          let $this = $(`#${gridId}`);
          // id = $.jgrid.jqID(this.id),
          // p = $this.jqGrid('getGridParam'), // p = this.p,
          const p = $(`#${gridId}`).jqGrid('getGridParam'); // p = this.p,
          let newPage = p.page;

          options.processing = true;

          // delete the row
          $this.jqGrid('delRowData', rowid);
          if (p.treeGrid) {
            $this.jqGrid('delTreeNode', rowid);
          } else {
            $this.jqGrid('delRowData', rowid);
          }
          $.jgrid.hideModal(`#delmod${gridId}`, {
            gb: `#gbox_${gridId}`,

            jqm: options.jqModal,
            onClose: options.onClose
          });

          if (p.lastpage > 1) {
            if (p.reccount === 0 && newPage === p.lastpage) {
              newPage -= 1;
            }
            $this.trigger('reloadGrid', [{page: newPage}]);
          }
          return true;
        },
        processing: true
      },
      // {},
      {
        // modal: false,
        multipleSearch: true,
        multipleGroup: true,
        // overlay: 0,   // comment or can not contorl multiplesearch pop up
        showQuery: true,
        // dataInit(elem) {
        //   console.log('dataInit_______________', elem);
        // },
        // attr: {top: '50px', width: '700px', title: 'Finset Search'},

        // beforeShowSearch: beforeShowModal,

        onSearch() {
          let i;
          let rules;
          let rule;
          // let $grid;
          const postData = $grid.jqGrid('getGridParam', 'postData');
          const filters = $.parseJSON(postData.filters);

          if (
            filters &&
            typeof filters.rules !== 'undefined' &&
            filters.rules.length > 0
          ) {
            rules = filters.rules;
            for (let i = 0; i < rules.length; i++) {
              rule = rules[i];
              if (rule.field === 'name') {
                rule.field = 'amount';
              }
            }

            // const $filter = $('#' + $.jgrid.jqID(`fbox_${gridId}`));

            const $filter = $(`#${$.jgrid.jqID(`fbox_${gridId}`)}`);
            let sql = $filter.jqFilter('toSQLString');

            sql = sql.replace(/\"/g, "'");

            const data = {sql};
            postParams = data;
          }
        }
      }
    );

    // const $searchDialog = $(`#searchmodfbox_${gridId}`);
    // $searchDialog.dialog({
    //   width: '700',
    //   position: {
    //     of: `#search_${gridId}_top`,
    //     at: 'right bottom',
    //     my: 'left top'
    //   }
    // });
    if (jg.upload) {
      $grid.navButtonAdd(`#${gridId}_toppager_left`, {
        caption: '',
        buttonicon: 'fas fa-solid fa-upload btn-default',
        onClickButton: function () {
          // var fileupload = document.getElementById('fileupload');
          // if (fileupload) {
          //   $('#fileupload').trigger('click');
          // }
        },
        position: 'last',
        id: 'excelUpload',
        title: 'Excel Upload',
        cursor: 'pointer'
      });
    }
    $grid.navButtonAdd(`#${gridId}_toppager_left`, {
      caption: '',
      buttonicon: 'fas fa-solid fa-download btn-default',
      onClickButton() {
        // console.log('______________conClickButton___________________');
        const data = $grid.jqGrid('getRowData');
        const cm = $(`#${gridId}`).jqGrid('getGridParam', 'colModel');

        const cmarr: any[] = [];
        let txt = '';
        Array.from(cm).map(function (v) {
          typeof v['label'] != 'undefined'
            ? cmarr.push(v['label'])
            : (txt = txt + v['name'] + ',');
        });

        const arr = [];
        arr.push(cmarr);

        const txtarr = txt.split(',');
        Array.from(data).map(function (v) {
          for (const y in txtarr) {
            delete v[`${txtarr[y]}`];
          }
          const arrsub = [];
          for (const x in v) {
            arrsub.push(v[x]);
          }
          arr.push(arrsub);
        });

        const wsName = 'SheetJS';
        const filename = 'jqGrid.xlsx';
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(arr);
        XLSX.utils.book_append_sheet(wb, ws, wsName);
        XLSX.writeFile(wb, filename);
      },
      position: 'last',
      id: 'excelDown',
      title: 'Excel Download',
      cursor: 'pointer'
    });
    $grid.navButtonAdd(`#${gridId}_toppager_left`, {
      caption: '',
      title: 'Reorder Columns',
      buttonicon: 'fas fa-solid fa-list-ol btn-default',
      // jqModal: true,

      onClickButton() {
        $(`#${gridId}`).jqGrid('columnChooser');
      }
    }); // jqGrid

    try {
      const topPagerDiv = $(`#${gridId}_toppager`)[0];
      $(`#${gridId}_top`, topPagerDiv).remove();
      $(`#${gridId}_toppager_center`, topPagerDiv).remove();
      $('.ui-paging-info', topPagerDiv).remove();

      // const bottomPagerDiv = $(`#${gridPager}`)[0];

      const bottomPagerDiv = $('div#pager')[0];
      $(`#add_${gridId}`, bottomPagerDiv).remove();
      $(`#del_${gridId}`, bottomPagerDiv).remove();
      $(`#edit_${gridId}`, bottomPagerDiv).remove();
      $(`#search_${gridId}`, bottomPagerDiv).remove();

      $(`#${gridId}pager_left`).css('display', 'none');

      $(`#${gridId}Pager .ui-pg-table`).attr('style', '');

      $(`.card-deck .card`).css('margin-left', '0'); //remove grid left margin

      const $searchDialog = $(`#searchmodfbox_${gridId}`);

      $searchDialog.dialog({
        width: '700',
        position: {
          of: `#search_${gridId}_top`,
          at: 'right bottom',
          my: 'left top'
        }
      });

    } catch (e) {
      console.log('jqgrid_exceiption=', e);
    }

  }

  function gridSearchData() {
     console.log('gridSearchData_postData==', postParams, url);

    if (url !== null) {
      gridSearch(postParams, url)
        .then((response) => {
          const rows = response.rows;
          // console.log(`rows===================`, rows);
          jQuery(`#${gridId}`)
            .jqGrid('setGridParam', {
              datatype: 'local',
              data: rows
            })
            .trigger('reloadGrid');

          // setGridData(response.data);
        })
        .catch((error: any) => {
          toast.error(error.message || 'searchGrid Error', error);
          console.log('searchGrid Error', error);
        });
    }
  }

  function setGridData(data: any) {
    $grid.jqGrid('clearGridData');
    // console.log('JqGrid_setGridData_data_____________', data);

    if (data.length > 0) {
      let i = 0;
      for (i = 0; i <= data.length; i++) {
        // console.log('data[i]_____________', data[i]);
        $grid.jqGrid('addRowData', i + 1, data[i]);
      }
    }
    return false;
  }

  // let changeTogle = 0;
  // function isJsonString(str) {
  //   try {
  //     if (str === '') return false;
  //     JSON.parse(str);
  //   } catch (e) {
  //     console.log('str_is_not_jsonFormat__', str, e);
  //     return false;
  //   }
  //   return true;
  // }

  function onclickSubmitLocal(options, postdata) {
    // console.log('_______________onclickSubmitLocal_222________________');
    // let $this = $(this);
    const $this = $(`#${gridId}`);

    const p = $this.jqGrid('getGridParam');
    const idname = p.prmNames.id;
    // const id = $.jgrid.jqID(this.id);
    const id = gridId;
    // console.log('id22________________________', gridId);
    // const idInPostdata = this.id + '_id';
    const idInPostdata = `${gridId}_id`;

    const rowid = postdata[idInPostdata];
    const addMode = rowid === '_empty';
    // const oldValueOfSortColumn;
    let newId = rowid;

    // console.log('newId______________________', newId);
    let idOfTreeParentNode;
    // postdata has row id property with another name. we fix it:
    if (addMode) {
      // generate new id
      newId = $.jgrid.randId();
      // console.log('newId222______________________', newId);
      while ($(`#${$.jgrid.jqID(newId)}`).length !== 0) {
        newId = $.jgrid.randId();
      }
    }
    if (postdata[idname] === undefined) {
      // set id property only if the property not exist

      // console.log('newId333______________________', newId);
      postdata[idname] = newId;
    }

    delete postdata[idInPostdata];

    // prepare postdata for tree grid
    if (p.treeGrid === true) {
      if (addMode) {
        idOfTreeParentNode =
          p.treeGridModel === 'adjacency'
            ? p.treeReader.parent_id_field
            : 'parent_id';
        postdata[idOfTreeParentNode] = p.selrow;
      }

      // what is this for??
      $.each(p.treeReader, function () {
        if (postdata.hasOwnProperty(this)) {
          delete postdata[this];
        }
      });
    }

    if (p.autoencode) {
      $.each(postdata, function (n, v) {
        postdata[n] = $.jgrid.htmlDecode(v); // TODO: some columns could be skipped
      });
    }

    const oldValueOfSortColumn =
      p.sortname === ''
        ? undefined
        : $this.jqGrid('getCell', rowid, p.sortname);

    // save the data in the grid
    if (p.treeGrid === true) {
      if (addMode) {
        $this.jqGrid('addChildNode', newId, p.selrow, postdata);
      } else {
        $this.jqGrid('setTreeRow', rowid, postdata);
      }
    } else if (addMode) {
      $this.jqGrid('addRowData', newId, postdata, options.addedrow);
    } else {
      $this.jqGrid('setRowData', rowid, postdata);
    }

    if (
      (addMode && options.closeAfterAdd) ||
      (!addMode && options.closeAfterEdit)
    ) {
      // close the edit/add dialog
      $.jgrid.hideModal(`#editmod${gridId}`, {
        // $this.jqGrid.hideModal(`#editmod${id}`, {
        gb: `#gbox_${gridId}`,
        jqm: options.jqModal,
        onClose: options.onClose
      });
    }

    if (postdata[p.sortname] !== oldValueOfSortColumn) {
      // if the data are changed in the column by which are currently sorted
      // we need resort the grid
      setTimeout(function () {
        $this.trigger('reloadGrid', [{current: true}]);
      }, 100);
    }

    // !!! the most important step: skip ajax request to the server
    options.processing = true;

    setRowDatas($this.getRowData());
    // return $this.getRowData()
    return {};
  }

  function insertEmptyRow() {
    // console.log('insertEmptyRow_________', jg.insertRow);

    if (jg.insertRow) {
      // $grid.jqGrid('addRow', {
      //   rowID: 0,
      //   initdata: {},
      //   position: 'last',
      //   useDefValues: false,
      //   useFormatter: false,
      //   addRowParams: {extraparam: {}}
      // });

      // $grid.jqGrid('saveRow', 0);
      // $grid.jqGrid('setSelection', 0);
      $grid.jqGrid('addRowData', 1, {}, 'last'); // 마지막 행에 Row 추가
    }
  }

  return (
    <div className="grid_wrapper">
      <table id={gridId} />
      <div id={gridPager} />
    </div>
  );
});

function getMultiSelectUrl() {
  const p = JSON.stringify(postParams);
  console.log('JqGrid_postParams==================', postParams);
  const corpCd = postParams.corp_cd;
  const projCd = postParams.proj_cd;
  const ver = postParams.ver;

  const params = `corp_cd=${corpCd}&proj_cd=${projCd}&ver=${ver}`;

  return `${API_BASE_URL}/PJ/RG/getProjectMembers?${params}`;
}

export default React.memo(JqGrid);
