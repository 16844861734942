import {createSlice} from '@reduxjs/toolkit';
import {
  addWindowClass,
  calculateWindowSize,
  removeWindowClass
} from '@app/utils/helpers';
import {
  NAVBAR_DARK_VARIANTS,
  NAVBAR_LIGHT_VARIANTS,
  SIDEBAR_DARK_SKINS,
  SIDEBAR_LIGHT_SKINS
} from '@app/utils/themes';
import {ACCESS_TOKEN} from "@app/constants";

export interface UiState {
  screenSize: string;
  menuSidebarCollapsed: boolean;
  controlSidebarCollapsed: boolean;
  darkMode: boolean;
  headerBorder: boolean;
  headerFixed: boolean;
  footerFixed: boolean;
  layoutBoxed: boolean;
  layoutFixed: boolean;
  menuItemFlat: boolean;
  menuChildIndent: boolean;
  navbarVariant: string;
  sidebarSkin: string;
}

const initialState: UiState = {
  screenSize: calculateWindowSize(window.innerWidth),
  darkMode:  window.localStorage.getItem('darkMode') === 'true' ? true :  false,
  // navbarVariant: 'navbar-light',
  navbarVariant:  window.localStorage.getItem('darkMode') === 'true' ? 'navbar-dark' :  'navbar-light-primary',
  sidebarSkin:  window.localStorage.getItem('darkMode') === 'true' ? 'sidebar-dark-primary' :  'sidebar-light-primary',

  menuSidebarCollapsed: window.localStorage.getItem('menuSidebarCollapsed') === 'false' ? false :  true,
  controlSidebarCollapsed: window.localStorage.getItem('controlSidebarCollapsed') === 'false' ? false :  true,
  headerBorder: window.localStorage.getItem('headerBorder') === 'false' ? false :  true,
  headerFixed: window.localStorage.getItem('headerFixed') === 'false' ? false :  true,
  footerFixed: window.localStorage.getItem('footerFixed') === 'false' ? false :  true,
  layoutBoxed: window.localStorage.getItem('layoutBoxed') === 'true' ? true :  false,
  menuItemFlat: window.localStorage.getItem('menuItemFlat') === 'true' ? true :  false,
  menuChildIndent: window.localStorage.getItem('menuChildIndent') === 'false' ? false :  true,
  layoutFixed: window.localStorage.getItem('menuChildIndent') === 'false' ? false :  true
};

// addWindowClass('layout-footer-fixed');

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleSidebarMenu: (state) => {
      state.menuSidebarCollapsed = !state.menuSidebarCollapsed;

      window.localStorage.setItem('menuSidebarCollapsed', JSON.stringify(state.menuSidebarCollapsed));
    },
    toggleControlSidebar: (state) => {
      state.controlSidebarCollapsed = !state.controlSidebarCollapsed;

      window.localStorage.setItem('controlSidebarCollapsed', JSON.stringify(state.controlSidebarCollapsed));
    },
    toggleHeaderBorder: (state) => {
      state.headerBorder = !state.headerBorder;

      window.localStorage.setItem('headerBorder', JSON.stringify(state.headerBorder));
    },
    toggleHeaderFixed: (state) => {
      state.headerFixed = !state.headerFixed;
      window.localStorage.setItem('headerFixed', JSON.stringify(state.headerFixed));
      if (state.headerFixed) {
        addWindowClass('layout-navbar-fixed');
      } else {
        removeWindowClass('layout-navbar-fixed');
      }
    },
    toggleFooterFixed: (state) => {
      state.footerFixed = !state.footerFixed;

      window.localStorage.setItem('footerFixed', JSON.stringify(state.footerFixed));

      if (state.footerFixed) {
        addWindowClass('layout-footer-fixed');
      } else {
        removeWindowClass('layout-footer-fixed');
      }
    },
    toggleLayoutBoxed: (state) => {
      state.layoutBoxed = !state.layoutBoxed;

      window.localStorage.setItem('layoutBoxed', JSON.stringify(state.layoutBoxed));
      if (state.layoutBoxed) {
        addWindowClass('layout-boxed');
      } else {
        removeWindowClass('layout-boxed');
      }
    },
    toggleLayoutFixed: (state) => {
      state.layoutFixed = !state.layoutFixed;

      window.localStorage.setItem('layoutFixed', JSON.stringify(state.layoutFixed));
      if (state.layoutFixed) {
        removeWindowClass('layout-fixed');
      } else {
        addWindowClass('layout-fixed');
      }
    },
    toggleMenuItemFlat: (state) => {
      state.menuItemFlat = !state.menuItemFlat;

      window.localStorage.setItem('menuItemFlat', JSON.stringify(state.menuItemFlat));
    },
    toggleMenuChildIndent: (state) => {

      state.menuChildIndent = !state.menuChildIndent;
      window.localStorage.setItem('menuChildIndent', JSON.stringify(state.menuChildIndent));
    },
    toggleDarkMode: (state) => {
      state.darkMode = !state.darkMode;
      window.localStorage.setItem('darkMode', JSON.stringify(state.darkMode));

      if (state.darkMode) {
        state.navbarVariant = NAVBAR_DARK_VARIANTS[0].value;
        state.sidebarSkin = SIDEBAR_DARK_SKINS[0].value;
        // console.log("NAVBAR_DARK_VARIANTS[0].value==",NAVBAR_DARK_VARIANTS[0].value);
        // console.log("SIDEBAR_DARK_SKINS[0].value==",SIDEBAR_DARK_SKINS[0].value);
      } else {
        state.navbarVariant = NAVBAR_LIGHT_VARIANTS[0].value;
        state.sidebarSkin = SIDEBAR_LIGHT_SKINS[0].value;
      }
      if (state.darkMode) {
        addWindowClass('dark-mode');
      } else {
        removeWindowClass('dark-mode');
      }
    },
    setNavbarVariant: (state, {payload}) => {
      if (state.darkMode) {
        state.navbarVariant = payload || NAVBAR_DARK_VARIANTS[0].value;
      } else {
        state.navbarVariant = payload || NAVBAR_LIGHT_VARIANTS[0].value;
      }
    },
    setSidebarSkin: (state, {payload}) => {
      if (state.darkMode) {
        state.sidebarSkin = payload || SIDEBAR_DARK_SKINS[0].value;
      } else {
        state.sidebarSkin = payload || SIDEBAR_LIGHT_SKINS[0].value;
      }
    },
    setWindowSize: (state, {payload}) => {
      console.log("payload");
      state.screenSize = payload;
    }
  }
});

export const {
  toggleSidebarMenu,
  setWindowSize,
  toggleControlSidebar,
  toggleDarkMode,
  setNavbarVariant,
  setSidebarSkin,
  toggleHeaderBorder,
  toggleHeaderFixed,
  toggleFooterFixed,
  toggleLayoutBoxed,
  toggleMenuItemFlat,
  toggleMenuChildIndent,
  toggleLayoutFixed
} = uiSlice.actions;

export default uiSlice.reducer;
