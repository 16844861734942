import {useFormContext} from 'react-hook-form';
import i18n from '@app/utils/i18n';
import {useCallback, useEffect, useRef, useState} from 'react';
import {ErrorMessage} from '../../common/ErrorMessage';
import {CustomInputProps} from './types';
import {randomUUID} from 'crypto';

export const CustomHidden = ({
                                seq,
                                type,
                                label,
                                dbValue,
                                ...props
                            }: CustomInputProps) => {
    const {
        register,
        formState: {errors}
    } = useFormContext<any>();

    const id = `${label}`;

    console.log('CustomHidden_props############################', dbValue, props);

    dbValue=props.defaultValue;

    console.log('dbValue=============', dbValue);
    let error = errors[label]?.message as string | undefined;

    const hv = dbValue.value !== undefined ? {id: id, value: dbValue.value}: {};

    useEffect(() => {
        if(dbValue.value !== undefined)
        props.handleChange(hv);
    }, []);

    return (
        <div className="form-group">
            <div className="input-group">

                <div className="col-sm-9">
                    <div>
                        <input
                            type="hidden"
                            autoFocus
                            className="form-control "
                            id={label}
                            key={seq}

                            defaultValue={dbValue || ''}
                        />
                        <ErrorMessage error={error}/>
                    </div>
                </div>
            </div>
            {/*<hr className="bg-gray-400 my-4"/>*/}
        </div>
    );
};
