import React, {useEffect, useState, useRef, useCallback} from 'react';
import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';
import i18n from '@app/utils/i18n';
import {ContentHeader, MenuItem} from '@components';
import {useDispatch, useSelector} from 'react-redux';
import {getMenu} from '@app/utils/APIUtils';
import '../../pj/rg/Pjrg100i.css';
import {Form} from '../../common/Form';
import {getInputs} from '../../common/inputs/lib/getInputs';
import {toggleSidebarMenu} from "@store/reducers/ui";
import store from "@store/store";
import {logoutUser} from "@store/reducers/auth";
import {toast} from "react-toastify";

interface RegisterFormType {}
let title ='';

const DMain = () => {
  let useparams = useParams();
  let navigate = useNavigate();

  for(let i = 0; i < window.localStorage.length; i++) {
    const key = window.localStorage.key(i);
    const value = window.localStorage.getItem(key);
    console.log("key1==="+key + " value1===" + value );
  }

  const user = useSelector((state: any) => state.auth.currentUser);
  let {state} = useLocation();

  console.log('useparams=================',useparams, ' state=================',state, " user=",user);
  // if(useparams.id === undefined && state === null) useparams.id='MAIN';

  if(state ===null) {
    useparams.id='MAIN';
    // state.params='';
    state = {params: ''}
  }else if(state.name === 'ScreenRegistration') {
    useparams.id='CMPC100I';

  }


  const projCd =window.localStorage.getItem('proj_cd') === 'undefined' ? '': window.localStorage.getItem('proj_cd');
  console.log('=============================================PAGE_RELOAD_state====================================',state);

  let openPage = false;

  const [ltvp, setLtvp] = useState();
  const [movepage, setMovepage] = useState(false);
  const [pageID, setPageID] = useState(useparams.id);
  const [values, setValues] = useState(state.params);

  console.log("[[[PAGE_RELOAD]]]\n nuseparams:", useparams,"\nstate.params:",state.params, "\npageID:", pageID, "\nuserparams.id:",useparams.id,'\nvalues:',values,'\nltvp:',ltvp);

  let p = {
    userInfo: user,
    // user_id : user.user_id,
    corp_cd: user.corp_cd,
    proj_cd: projCd,
    ver: '1',
    prog_id: useparams.id,
    prog_no: state.params.prog_no,
    form_table : ''
  };

  console.log("i18n====",i18n.language);
  const lng= i18n.language;
  const constValues  = useCallback(async () => {

    let a = await setValues(state.params);

    console.log('______________CONST_VALUES_ltvp__________________',ltvp );

    let p=ltvp !== undefined? ltvp.p:{};
    let ltvpv={...ltvp,...{p}, ...{values: state.params}};

    let c= await getInputs<RegisterFormType>({...ltvpv});
    // console.log('______________c________________',{... c} );
    let b = await setLtvp(c);

    console.log('______________ltvpv_in_value_upldate_after__________________',ltvp );

  } , [state.params, useparams.id]    );
  const constMovepage= useCallback(() => {setMovepage(false);},    [pageID, useparams.id]  );
  const constPageId  = useCallback(() => {setPageID(useparams.id);},    [useparams.id]  );
  const constLtvp    = useCallback(async () =>  {
    try {
      console.log("######################################################");
      console.log("###             getMenu                             ##");
      console.log("######################################################");

      let response = await getMenu(user, pageID);

      console.log("===SETLTV===", response, 'pageID=', pageID)

      let ltv = response.data[0].form_json;
      let program_url = response.data[0].program_url;
      let menu_nm_short = response.data[0].menu_nm_short;
      let form_table = response.data[0].form_table;

      title = i18n.t(`menusidebar.label.${menu_nm_short}`);
      const writer_object = {user_id: user.user_id, record_nm: user.record_nm, create_dtm: new Date()};

      p = {...p, form_table, program_url, menu_nm_short, writer_object, lng};


      const ltvpv = {ltv, ...{p}, ...{values: state.params}};
      console.log('=========================ltvpv============================', ltvpv);

      if (ltv !== undefined && JSON.stringify(ltv).indexOf("validations") > 0) {
        console.log('=========================ltv_validation============================', {ltv, ...{p}});
        setLtvp(() => getInputs<RegisterFormType>({...ltvpv}));
      } else {
        setLtvp({...ltvpv});
      }
      console.log('p===================================================', p);

      setMovepage(true);
    }catch(e){
      // toast.error("Unexpected error occured !!");
      toast.info("로그인해 주세요.");

      store.dispatch(logoutUser());
    }
  },[pageID ] );

  useEffect( () => {
    console.log('______________constPageId____________________',pageID);
    constPageId();
  } ,[constPageId]);

  useEffect( () => {
    constValues();
  } ,[constValues]);

  useEffect( () => {
    console.log('______________constMovepage____________________',movepage);
    constMovepage();
  } ,[constMovepage]);

  useEffect( () => {
    console.log('______________constLtvp___________________',ltvp);
    constLtvp();

    return ()=>{
      console.log('______________CLEAR_MOVEPAGE____________________',movepage);
      setMovepage(false);
    }
  } ,[constLtvp]);

  let lastUpdate;
  const handleChange = (e) => {
    console.log('DyPage_handleChange_e============================', e);

    lastUpdate = Date.now();
    const timer = setTimeout(function () {
      const diff = Date.now() - lastUpdate;
      if (e.id || e.target.id) {
        console.log('e.id==', e.id, 'e.value==',e.value);
        const name = e.id !== undefined? e.id : e.target.id;
        const value = e.value !== undefined ? e.value: e.target.value;

        if (diff > 1000) {
          setValues({
            ...values,
            [name]: value
          });
          console.log("___________________setValues_________________________", value);
        }
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
      // setMovepage(true);
      console.log('_________clearTimeout_____________');
    };
  };

  const dispatch = useDispatch();

  const handleToggleMenuSidebar = () => {
    dispatch(toggleSidebarMenu());
  };

    return (
        <div>
          <ContentHeader  title={title} />
          <section className="content">

            { movepage && (
                <Form
                    ltvp={ltvp}
                    dbValue={values}
                    handleChange={handleChange}
                />
            )}

          </section>
        </div>
    );
};

export default DMain;

