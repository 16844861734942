import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link, useNavigate, useLocation, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useFormik} from 'formik';
// import {useTranslation} from 'react-i18next';
import {loginUser} from '@store/reducers/auth';
import {setWindowClass, removeWindowClass} from '@app/utils/helpers';
import {PfButton, PfCheckbox} from '@profabric/react-components';
import * as Yup from 'yup';
import {Form, InputGroup} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import * as AuthService from '../../services/auth';
import {ACCESS_TOKEN, REFRESH_TOKEN} from '../../constants';
import {
    login,
    tokenLogin,
    getWorkspace,
    loginWithCorpCd, getUserSummary,
} from '../../utils/APIUtils';
import {ContentHeader} from "@components";
import Input from "@pages/cm/pc/Input";

const WorkSpace = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const useparams = useParams();

    const {state} = useLocation();
    console.log('workspace_useparams===============', useparams);
    console.log('workspace_state===============', state);

    const [workspace, setWorkspace] = useState([]);

    useEffect(() => {
        getWorkspace(state.res.data.user_id)
            .then((response) => {
                console.log('workspace_response===============', response[0]);
                if (response.length > 1) {
                    setWorkspace(response);
                    removeWindowClass('hold-transition');
                    removeWindowClass('login-page');

                } else {
                    movePage(response[0].corp_cd);
                }
            })
            .catch((error) => {
                toast.error(
                    error.message || 'Login Exception'
                );
            });
    }, []);

    const movePage = (corp_cd: string) => {
        console.log("state.res______________________", state.res)
        const accessToken = window.localStorage.getItem(ACCESS_TOKEN);
        const refreshToken = window.localStorage.getItem(REFRESH_TOKEN);
        const loginRequest = {corpCd: corp_cd, userId:state.res.data.user_id, password: state.res.data.password };
        //
        // console.log("loginRequest____________________", loginRequest)
        // console.log("accessToken___________________", accessToken)
        // console.log("refreshToken____________________", refreshToken)
        //token 정보 및 permission정보 체크
        // return type User
        loginWithCorpCd(loginRequest)
            .then((response2) => {
                console.log("response2____________________", response2);
                
                //유저 상세정보(email, mobile, corp_cd, picture 정보 가져옴
                //return type Map
                window.corp_cd = corp_cd;
                window.user_id = state.res.data.user_id;
                // window.localStorage.setItem('corp_cd', corp_cd);
                console.log("loginWithCorpCd___corp_cd________________", corp_cd);

                console.log("window.corp_cd______________", window.corp_cd);
                getUserSummary(corp_cd, response2.id)
                    .then((response3) => {
                        console.log("response3____________________", response3)
                        dispatch(
                            loginUser({
                                accessToken: accessToken,
                                refreshToken: refreshToken,
                                permission: response2.permission,
                                currentUser: response3.currentUser,
                                // ...response.data
                            })
                        );

                        navigate('/');

                    })
                    .catch((error) => {
                        toast.error(
                            error.message || 'Login Exception'
                        );
                        navigate('/login')
                    });

            })
            .catch((error) => {
                toast.error(
                    error.message || 'Login Exception'
                );
                navigate('/login')
            });
    }

    const containerStyle= {
        width: '100vw',
        height: '100vh',
        backgroundImage: `url(./img/earthmoon.jpg)`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    }

    return (
        <div style={containerStyle}>
            {/*<ContentHeader title="Pick Workspace"/>*/}

            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6" style={{"fontWeight": "bold"}} >
                            <h2 style={{ color: 'white' }}>Select work group</h2>
                        </div>
                        {/*
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li className="breadcrumb-item active">{title}</li>
                            </ol>
                        </div>
*/}
                    </div>
                </div>
            </section>

            <section className="content">
                <div className="container-fluid">

                    {
                        workspace.map((ws, index) => (

                            <>
                                <div className="card">
                                    {/*
                                    <div className="card-header">
                                        <h3 className="card-title">Title</h3>
                                        <div className="card-tools">
                                            <button
                                                type="button"
                                                className="btn btn-tool"
                                                data-widget="collapse"
                                                data-toggle="tooltip"
                                                title="Collapse"
                                            >
                                                <i className="fa fa-minus"/>
                                            </button>

                                        </div>
                                    </div>
                                    */}
                                    <div className="card-body d-flex">

                                        <div className="icon mr-auto p-2" style={{marginLeft: '100px', "fontWeight": "bold"}}>
                                            {ws.corp_nm}
                                        </div>
                                        <div style={{marginRight: '100px'}}>
                                            <a href="#" className="small-box-footer"
                                               onClick={() => movePage(`${ws.corp_cd}`)}>
                                                Go <i className="fas fa-arrow-circle-right"/>
                                            </a>
                                        </div>

                                        <div style={{marginReft: '100px'}}>
                                            {ws.login_dt24}
                                        </div>

                                    </div>
                                </div>
                            </>
                        ))}

                    {/*<div className="card-footer">Footer</div>*/}

                </div>
            </section>
        </div>
    );
};

export default WorkSpace;
