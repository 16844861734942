import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

// import translationKP2 from '../locales/kp/translation.json';
// import translationEN2 from '../locales/en/translation.json';
import {getMenu, getMsg} from '@app/utils/APIUtils';
import {useSelector} from 'react-redux';

// console.log(
//     'translationKP2==', translationKP2
// );

let translationKP = {};
let translationEN = {};

let resources = {};
const corp_cd =
    window.localStorage.getItem('corp_cd') === null
    ? '12'
    : window.localStorage.getItem('corp_cd');
console.log(
  'localStorage.getItem("corp_cd")=======',
    window.localStorage.getItem('corp_cd')
);

const response = getMsg({corp_cd: corp_cd})
  .then((response) => {
    translationKP = response.kp;
    translationEN = response.en;

    resources = {
      en: {
        translation: translationEN
      },
      kp: {
        translation: translationKP
      }
    };
    console.log('translationKP===', translationKP);
    console.log('translationEN===', translationEN);

    i18n
      .use(initReactI18next) // passes i18n down to react-i18next
      .init({
        resources,
        lng: 'kp',
        debug: true,
        fallbackLng: 'en',
        // compatibilityJSON: 'v1',
        // keySeparator: false,
        formatSeparator: false,
        interpolation: {
          escapeValue: false
          //
        },
        react: {
          useSuspense: true
        }
      } as any);
  })
  .catch(() => {
    console.log('-------------i18n_getmessage_error-----------------');
  });

console.log('resources----------', resources);

export default i18n;
