import React, {useState} from 'react';

import Select from 'react-select';
// import {colourOptions} from './data';

const Checkbox = ({children, ...props}: JSX.IntrinsicElements['input']) => (
  <label style={{marginRight: '1em'}}>
    <input type="checkbox" {...props} />
    {children}
  </label>
);

export default (props) => {
  console.log('Multi_props==', props);
  // console.log('props.defaultValue==', props.defaultValue);
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);

  return (
    <>
      <Select
        defaultValue={props.defaultValue}
        isDisabled={isDisabled}
        options={props.options}
        className="basic-multi-select"
        classNamePrefix="select"
        isMulti
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        onChange={props.handleChange}
        name="color"
      />

      <div
        style={{
          color: 'hsl(0, 0%, 40%)',
          display: 'inline-block',
          fontSize: 12,
          fontStyle: 'italic',
          marginTop: '1em'
        }}
      >
        {/* 
        <Checkbox
          checked={isClearable}
          onChange={() => setIsClearable((state) => !state)}
        >
          Clearable
        </Checkbox>
        <Checkbox
          checked={isSearchable}
          onChange={() => setIsSearchable((state) => !state)}
        >
          Searchable
        </Checkbox>
        <Checkbox
          checked={isDisabled}
          onChange={() => setIsDisabled((state) => !state)}
        >
          Disabled
        </Checkbox>
        <Checkbox
          checked={isLoading}
          onChange={() => setIsLoading((state) => !state)}
        >
          Loading
        </Checkbox>
        <Checkbox checked={isRtl} onChange={() => setIsRtl((state) => !state)}>
          RTL
        </Checkbox>
         */}
      </div>
    </>
  );
};
