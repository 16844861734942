import {logoutUser} from '@app/store/reducers/auth';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import store from '@store/store';

import {API_BASE_URL, POLL_LIST_SIZE, ACCESS_TOKEN, REFRESH_TOKEN} from '../constants';
import { toast } from 'react-toastify';

const request = (options: any) => {


  const headers = new Headers({
    'Content-Type': 'application/json'
  });
  const fileheaders = new Headers({
    // 'Content-Type': `multipart/form-data; `
    'Content-Type': 'multipart/form-data; '
  });

  const downheaders = new Headers({
    'Content-Type': 'content-disposition; '
  });

  const accessToken = window.localStorage.getItem(ACCESS_TOKEN);

   // console.log('accessToken______________', accessToken);
  if (accessToken !== undefined && accessToken !== null) {
    headers.append('Authorization', `Bearer ${accessToken}`);
  }

  const refreshToken = window.localStorage.getItem(REFRESH_TOKEN);

  // console.log('refreshToken______________', refreshToken);
  if (refreshToken !== undefined && refreshToken !== null) {
    headers.append('Refresh-Token', `Bearer ${refreshToken}`);
  }

  // console.log('______________token______________', token);
  //  const defaults = {headers: headers};
  // console.log('______________options.header______________', options.header);

  let defaults = null;
  if (options.header === 'file') {
    defaults = {fileheaders};
  } else if (options.header === 'download') {
    defaults = {downheaders};
  } else {
    defaults = {headers};
  }

  options = {...defaults, ...options};
  // console.log('options=====', options);

  return fetch(options.url, options)
      .then((response) => {
        console.log("response:", response)
        if(response.status === 404) {
          toast.error("ID를 확인해 주세요. ");
          return {};
        }else  if(response.status === 400) {
          toast.error("패스워드를 확인해 주세요. ");
          return {};
        }else  if(response.status === 200) {
          return response.json();
        }
      }).then((json) => {
        return json;
      })
      .catch((error) => {

        console.log("error:", error)

      });

  // console.log('result______', result);
};

export function login(loginRequest: any) {
  return request({
    url: `${API_BASE_URL}/auth/login`,
    method: 'POST',
    body: JSON.stringify(loginRequest)
  });
}

export function loginWithCorpCd(loginRequest: any) {
  return request({
    url: `${API_BASE_URL}/auth/loginWithCorpCd`,
    method: 'POST',
    body: JSON.stringify(loginRequest)
  });
}


export function tokenLogin() {
  console.log("tokenLogin====");
  return request({
    url: `${API_BASE_URL}/auth/tokenLogin`,
    method: 'POST',
    body: JSON.stringify({})
  });
}
/*

export function getCorpSummary(id: string) {
  if (!window.localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject(new Error('No access token set.'));
  }

  return request({
    url: `${API_BASE_URL}/auth/user/getCorpSummary?userId=${id}`,
    method: 'POST',
    body: JSON.stringify({userId: id})
  });
}
*/

export function getUserSummary(corp_cd: string, user_id: string) {
  if (!window.localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject(new Error('No access token set.'));
  }

  return request({
    url: `${API_BASE_URL}/auth/user/getUserSummary`,
    method: 'POST',
    body: JSON.stringify({corp_cd: corp_cd, user_id: user_id})
  });
}
export function getWorkspace(user_id: string) {
  return request({
    url: `${API_BASE_URL}/auth/getWorkspace`,
    method: 'POST',
    body: JSON.stringify({user_id: user_id})
  });
}
/*

export function corpProfile(user_id: string) {
  return request({
    url: `${API_BASE_URL}/auth/corpProfile`,
    method: 'POST',
    body: JSON.stringify({user_id: user_id})
  });
}
*/

export function getSidebarMenu(role1_cd: string, user_id: string, corp_cd: string) {
  console.log("corp_cd_____",corp_cd);
  console.log("user_id_____",user_id);
  console.log("role1_cd_____",role1_cd);

  return request({
    url: `${API_BASE_URL}/common/getSidebarMenu`,
    method: 'POST',
    body: JSON.stringify({role1_cd: role1_cd, user_id: user_id, corp_cd: corp_cd})
  });
}

export function getAllMenu(userInfo: any) {
  return request({
    url: `${API_BASE_URL}/common/getAllMenu`,
    method: 'POST',
    body: JSON.stringify({userInfo})
  });
}

export function getMenu(userInfo: any, menuNameEng: any) {
  return request({
    url: `${API_BASE_URL}/common/getMenu`,
    method: 'POST',
    body: JSON.stringify({userInfo, menu_nm_eng: menuNameEng})
  });
}

export function getCode(
  corpCd: string,
  upperCodeCd: string,
  active: string,
  optional: string
) {
  return request({
    url: `${API_BASE_URL}/common/getCode`,
    method: 'POST',
    body: JSON.stringify({
      corp_cd: corpCd,
      upper_code_cd: upperCodeCd,
      optional,
      p_active: active
    })
  });
}

export function gridSearch(requestParam: any, requestUrl: any) {
  return request({
    url: `${API_BASE_URL}${requestUrl}`,
    method: 'POST',
    body: JSON.stringify(requestParam)
  });
}

export function makeApproval(requestParam : any) {
  return request({
    url: `${API_BASE_URL}/common/makeApproval`,
    method: 'POST',
    body: JSON.stringify(requestParam)
  });
}

export function getDeptByCorpCd(corpCd: string) {
  return request({
    url: `${API_BASE_URL}/common/getDeptByCorpCd`,
    method: 'POST',
    body: JSON.stringify({corpCd})
  });
}



export function resetPassword(email: string) {
  return request({
    url: `${API_BASE_URL}/auth/resetPassword`,
    method: 'POST',
    body: JSON.stringify({email})
  });
}

export function registerNewMember(email: string, password: string, mobile: string, nickName: string,codeValue: string) {
  return request({
    url: `${API_BASE_URL}/auth/registerNewMember`,
    method: 'POST',
    body: JSON.stringify({email, password, mobile, nickName, codeValue})
  });
}

export function registerEmailCheck(email: string) {
  return request({
    url: `${API_BASE_URL}/auth/registerEmailCheck`,
    method: 'POST',
    body: JSON.stringify({email})
  });
}

export function registerCodeCheck(email: string, code: string) {
  return request({
    url: `${API_BASE_URL}/auth/registerCodeCheck`,
    method: 'POST',
    body: JSON.stringify({email, code})
  });
}



export function uploadToServer(fd: FormData) {
  return request({
    url: `${API_BASE_URL}/file/uploadFile`,
    method: 'POST',
    body: fd,
    header: 'file'
  });
}

export function getComments(userInfo: any, progId: string, progNo: string) {
  return request({
    url: `${API_BASE_URL}/comments/getComments`,
    method: 'POST',
    body: JSON.stringify({userInfo, prog_id: progId, prog_no: progNo})
  });
}

export function getMsg(obj) {
  const url = `${API_BASE_URL}/common/getMsg`;
  let options = {url : url,  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({corp_cd: obj.corp_cd})};
  return fetch(options.url, options).then((response) =>
      response.json().then((json) => {
        if (!response.ok) {
          console.log('_______getMsg_IS_NOT_OK_______', response);
          toast.error("Error in getMsg....\n"+JSON.stringify(response));
        }
        return json;
      })
  );
}

export function postComments(
  data: object,
  progId: string,
  progNo: string,
  userInfo: object
) {
  return request({
    url: `${API_BASE_URL}/comments/postComment`,
    method: 'POST',
    body: JSON.stringify({data, prog_id: progId, prog_no: progNo, userInfo})
  });
}

export function updateMenuFormJson(
  menuId: string,
  formJson: string,
  userInfo: object
) {
  return request({
    url: `${API_BASE_URL}/common/updateMenuFormJson`,
    method: 'POST',
    body: JSON.stringify({menu_nm_eng: menuId, form_json: formJson, userInfo})
  });
}


export function updateCompoJson(
    compoCd: string,
    compoSubCd: string,
    compoJson: string,
    userInfo: object
) {
  return request({
    url: `${API_BASE_URL}/sys/updateCompoJson`,
    method: 'POST',
    body: JSON.stringify({compo_cd: compoCd,compo_sub_cd: compoSubCd, compo_json: compoJson, userInfo})
  });
}

export function upvoteComment(
  id: string,
  progId: string,
  progNo: string,
  userInfo: object,
  upvote: string
) {
  return request({
    url: `${API_BASE_URL}/comments/upvoteComment`,
    method: 'POST',
    body: JSON.stringify({
      id,
      prog_id: progId,
      prog_no: progNo,
      userInfo,
      upvote
    })
  });
}

export function putComment(data: any) {
  return request({
    url: `${API_BASE_URL}/comments/postComment`,
    method: 'POST',
    body: JSON.stringify(data)
  });
}

export function deleteComment(
  id: string,
  progId: string,
  progNo: string,
  userInfo: object
) {
  return request({
    url: `${API_BASE_URL}/comments/deleteComment`,
    method: 'POST',
    body: JSON.stringify({prog_id: progId, prog_no: progNo, id, userInfo})
  });
}

export function commentsFile(id: any, fd: FormData) {
  return request({
    url: `${API_BASE_URL}/comments/uploadAttachments?id=${id}`,
    method: 'POST',
    body: fd,
    header: 'file'
  });
}


export function getCompo(compo_cd:string) {
  // const stVal = JSON.parse(pv);
  // console.log('appvHandle_pv=', pv);
  return request({
    url: `${API_BASE_URL}/sys/getCompo`,
    method: 'POST',
    body: JSON.stringify({compo_cd:compo_cd})
  });
}


export function colNmReq(table_name: string) {
  // const stVal = JSON.parse(pv);
  // console.log('appvHandle_pv=', pv);
  return request({
    url: `${API_BASE_URL}/sys/getColNm`,
    method: 'POST',
    body: JSON.stringify({table_name: table_name})
  });
}

export function menuNmReq() {
  // const stVal = JSON.parse(pv);
  // console.log('appvHandle_pv=', pv);
  return request({
    url: `${API_BASE_URL}/sys/getMenuNm`,
    method: 'POST',
    body: JSON.stringify({dummy: ''})
  });
}


export function appvHandle(url: string, pv: string) {
  // const stVal = JSON.parse(pv);
  console.log('appvHandle_pv=', pv);
  return request({
    url: `${API_BASE_URL}${url}`,
    method: 'POST',
    body: pv
  });
}

export function searchHandle(url: string, pv: string) {
  // const stVal = JSON.parse(pv);
  console.log('searchHandle_pv=', pv);
  return request({
    url: `${API_BASE_URL}${url}`,
    method: 'POST',
    body: pv
  });
}

export function saveHandle(url: string, pv: string) {
  // const stVal = JSON.parse(pv);
  console.log('saveHandle_pv=', pv);
  return request({
    url: `${API_BASE_URL}${url}`,
    method: 'POST',
    body: pv 
  });
}

export function deleteHandle(url: string, pv: string) {
  // const stVal = JSON.parse(pv);
  console.log('pv=', pv);
  return request({
    url: `${API_BASE_URL}${url}`,
    method: 'POST',
    body: pv
  });
}

export function updateTaskSchedules(updateParams: object, keyName: string) {
  const param = JSON.stringify(updateParams);
  console.log('param=', param);
  return request({
    url: `${API_BASE_URL}/PJ/RG/updateTaskSchedules`,
    method: 'POST',
    // body: JSON.stringify({updateParams: updateParams, keyName: keyName})
    body: param
    // body: {updateParams}
  });
}


export function selectAllSchedule(url: string, pv: string) {
  // const stVal = JSON.parse(pv);
  console.log('pv=', pv);
  return request({
    url: `${API_BASE_URL}${url}`,
    method: 'POST',
    body: pv
  });
}

