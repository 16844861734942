import React, {Dispatch, SetStateAction, useEffect, useRef} from 'react';
import styles from './ModalBasic.module.css';
import {toast} from "react-toastify";
import i18n from "@app/utils/i18n";
import {appvHandle} from "@app/utils/APIUtils";
import {useSelector} from "react-redux";

interface props {
    setModalOpen: Dispatch<SetStateAction<boolean>>;
    dbValue?: string;
    ltvp: string;
    appvSeq:number;
}

function ModalBasic({setModalOpen,  dbValue, ltvp, appvSeq  }: props) {
    console.log("ModalBasic_props====",setModalOpen,  dbValue, ltvp );

    const user = useSelector((state: any) => state.auth.currentUser);
    const closeModal = () => {
        setModalOpen(false);
    };

    const modalRef = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
        const handler = () => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setModalOpen(false);
            }
        };
        
        document.addEventListener('mousedown', handler);
        // document.addEventListener('touchstart', handler); // 모바일 대응
        
        return () => {
            document.removeEventListener('mousedown', handler);
            console.log("_______________return_function___________________");
            // document.removeEventListener('touchstart', handler); // 모바일 대응
        };
    });

    const makeApproval = async (str) => {
        console.log("____________________makeApproval_____________________");

        const jdata = dbValue;
        const appv_opinion = document.getElementById("appv_opinion") === null ? '' : document.getElementById("appv_opinion").value;

        const appv_status = str;
        const stVal = {...jdata,...ltvp.p, userInfo: user, appv_opinion, appv_status};
        console.log("stVal3====",stVal);
        const pv = JSON.stringify(stVal);
        const url ="/common/makeApproval"  ;

          appvHandle(url, pv)
            .then((response) => {
                closeModal();
                setTimeout(function () {
                    const arr = response.rows[0];
                    // setSelectOptions(response.data);
                    console.log("arr====",arr, "appv_array====",arr.appv_array);
                    $(`#gridA`).jqGrid('setGridParam', {
                        datatype: 'jsonstring',
                        datastr: arr.appv_array
                    }).trigger('reloadGrid');
                }, 100);
            })

            .catch((error) => {

                    console.error('Error loading sidebar menu!');

            });
        try {
            toast.success(i18n.t(`views.messages.approvalDone`));
            // goList();
        }catch(error)  {
            toast.error(i18n.t(`views.messages.approvalFail`));
        };
        return;
    }
    return (
        <div ref={modalRef} className={styles.container}>
            <button className={styles.close} onClick={closeModal}>
                X
            </button>

            <div className="form-group">
                <div className="input-group"><label className="col-sm-2 col-form-label" htmlFor="record_nm">의견</label>
                    <div className="col-sm-10 conainter-fluid">
                        <div>
                            <textarea
                                className="form-control"
                                key="appv_opinion"
                                id="appv_opinion"
                                placeholder="value"
                                 />
                        </div>
                        <div className="col-sm-10 text-center mt-2 mr-2 mb-2">
                            {
                                appvSeq === 0 ?  (
                                <>
                                    <button className="btn btn-outline-primary btn-sm ml-1" type="button"
                                            onClick={() => makeApproval('A')}>
                                        기안
                                    </button>
                                    <button className="btn btn-outline-danger btn-sm ml-1" type="button" onClick={() => closeModal()}>
                                    취소
                                    </button>
                                </>
                                )
                             :  (
                                <>
                                    <button className="btn btn-outline-primary btn-sm ml-1" type="button"
                                            onClick={() => makeApproval('A')}>
                                        승인
                                    </button>
                                    <button className="btn btn-outline-danger btn-sm ml-1" type="button" onClick={() => makeApproval('R')}>
                                    반려
                                    </button>
                                </>
                                )
                            }

                        </div>

                    </div>
                </div>
            </div>

        </div>
 
    );
}


export default ModalBasic;