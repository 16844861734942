import {useFormContext} from 'react-hook-form';
import {ErrorMessage} from '../ErrorMessage';
import {CustomInputProps} from './types';
import { useEffect, useState } from 'react';
import { getCode } from '@app/utils/APIUtils';
import { toast } from 'react-toastify';
import i18n from '@app/utils/i18n';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
 
import styled from '@emotion/styled';
 
 

const Label = styled.label `
  display: inline-block;
  margin-top: 10px;
  margin-right: 10px;
  font-size: 10px; 
`;


const Div = styled.div `

// display: inline-block;
width: 20px;
height: 20px;
margin-right: 10px;
cursor: pointer;
 
&:hover {
  span { 
    transform: scale(1.25); 
  }
}
 
`;

const Span = styled.span` 
  display: block;
  width: 100%;
  height: 100%;
  
text-align: center;
justify-content: center;
display: flex;

  transition: transform .2s ease-in-out;  
       ${cprops => { 
        console.log("cprops==",cprops);
          if (cprops.id === 'SCHE_DIV_P') {
            return 'background-color: #f39c12';
          } else if (cprops.id === 'SCHE_DIV_T') {
            return 'background-color: #605ca8';
          } else if (cprops.id === 'SCHE_DIV_D') {
            return 'background-color: #3c8dbc';
          } else if (cprops.id === 'SCHE_DIV_C') {
            return 'background-color: #00c0ef';
          } else if (cprops.id === 'SCHE_DIV_J') {
            return 'background-color: #0000FF';
          } else if (cprops.id === 'SCHE_DIV_I') {
            return 'background-color: #DB2828';
          } else{
            return 'background-color: #f39c12';
          }
        }};
        }
      }
       `;

const Input = styled.input `
  //  appearance: none; 
   opacity: 1;
  &:checked + span {
    transform: scale(1.25);
    border: 2px solid #501962;
  }`;

export const CustomRadioGroup = ({
  name,
  label,
  // options,
  ...props
}: CustomInputProps) => {
  const {
    register,
    formState: {errors}
  } = useFormContext();
 
  console.log("CustomRadioGroup################", props);
// const classes = useStyles();
  const [selectOptions, setSelectOptions] = useState([]); 
  const [selectItems, setSelectItems] = useState([
    {
      upperCodeCd: '',
      corpCd: '',
      active: '',
      optional: '',
      defaultValue: '',
      isDisabled: ''
    }
  ]);

  const fl = i18f(`views.common.${label}`);
  
  useEffect(() => {
    try {
      const pp = props.p;
      setSelectItems(pp);
      if (props.upperCodeCd !== undefined) {
        getCode(
          pp.corp_cd,
          props.upperCodeCd,
          pp.active === undefined ? 'Y' : pp.active,
          pp.optional === undefined ? 'Y' : pp.optional
        )
          .then((response) => {
            setSelectOptions(response.data);
          })
          .catch((error) => {
            toast.error('Error loading sidebar menu!');
          });
      }
    } catch (exception) {
      console.log('exception___________', exception);
    }
  }, []); // props.handleChange


  const localChange = (e: any) => {
    // console.log('e====', e);
    console.log('e.target.id====', e.target.id);
    console.log('e.target.value====', e.target.value);
    // console.log('props.userInfo==============', props.userInfo);
    let up = {
      id: e.target.id,
      value: e.target.value
    };

     
    props.handleChange(up);
  };
 
  let dv = props.defaultValue || "P";
 

  const error = errors[name]?.message as string | undefined;

  return ( 
     
    <div className="form-group">
      <div className="input-group">
        {label && (
          <label className="col-sm-3 col-form-label text-center" htmlFor={label}>
            {fl}
          </label>
        )}
        <div className="col-sm-9 conainter-fluid">
          <div>
          {selectOptions &&
            selectOptions.map(({code_nm, code_cd, att1}, index) => (
            
            <Label htmlFor={code_cd} >{code_nm}
            <Div className={code_cd}><Span  id={code_cd}>
              <Input  
                type="radio" 
                // {...register(label, {
                //   onChange: (e) => {
                //     // localChange(e);
                //     props.handleChange(e);
                //   }
                // })}
                defaultValue={dv}
                checked={dv === code_cd ?  true: undefined}
                // disabled={true}
                value={code_cd}
                id={props.id}
                key={index}
                label={props.id}
                onChange={(e) => {
                  localChange(e);
                }}
                name={props.id}
                // index={code_cd}
                />
             
 
                </Span>
                </Div>
            </Label>
            
          ))}
            <ErrorMessage error={error} />
          </div>
        </div>{' '}
      </div>
      {/*<hr className="bg-gray-400 my-4"/>*/}
    </div>
  );
};

function i18f(m: string) {
  const {t} = i18n;
  return t(m);
}
