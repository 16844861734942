import React, {
  useEffect,
  useState
} from 'react';
import {API_BASE_URL} from '../../../constants';

import {
  // getApproval
} from '../../../utils/APIUtils';
import i18n from '@app/utils/i18n';
import {JqGridForm} from "@pages/common/jqgrid/JqGridForm";

 function ApprovalForm(props) {
    let label = props.label;
    console.log('ApprovalForm====================', props);
    const edv = eval(props.defaultValue);
    console.log("edv====",edv);


    const appvongoing = edv === undefined || [] ? false: edv[0].appv_dtm === undefined ? false : true;

    console.log('appvongoing====================', appvongoing);
    const cm = props.p;
    const $ = window.$;
    const id = `${label}`;
    const fl = i18f(`views.common.${label}`);

  return (
    <div className="form-group">
        <div className="col-sm-12 conainter-fluid">
          <div>
            <div>

              <JqGridForm
                  key={props.p.keys}
                  gridInfo={props.gridInfo}
                  label={label}
                  p={props.p}
                  approval={true}
                  appvongoing={appvongoing}
                  handleChange={props.handleChange}
                  defaultValue={props.defaultValue}
              />
            </div>
            
          </div>
        </div>
        {/*<hr className="bg-gray-400 my-4"/>*/}
{/*

        <div className="col-sm-12">

          <hr
              style={{
                color: 'grey',marginTop: 50 ,
                // backgroundColor: color,
                height: 5
              }}
          />

        </div>
*/}

    </div>
  );

}

  export default React.memo(ApprovalForm);

function i18f(m: string) {
  const {t} = i18n;
  return t(m);
}
