/* eslint-disable react/react-in-jsx-scope */
import {yupResolver} from '@hookform/resolvers/yup';
import {FormProvider, useForm} from 'react-hook-form';
import React, {forwardRef, useCallback, useEffect, useRef, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {appvHandle, deleteHandle, getCode, saveHandle, searchHandle} from '@app/utils/APIUtils';
import {useSelector} from 'react-redux';
import i18n from '@app/utils/i18n';

import {
    CustomCheckbox,
    CustomGenericInput,
    CustomDateInput,
    CustomRadioGroup,
    CustomSelect,
    CustomSelectUser,
    CustomSelectSingle, CustomInput
} from '../common/inputs';
import {InputProps, SchemaForm} from './inputs/types';
import {JqGridForm} from './jqgrid/JqGridForm';
import MyDropzoneForm from './dropzone/MyDropzoneForm';
import {CustomSelectDept} from './inputs/CustomSelectDept';
import GanttGroup from './gantt_task/GanttGroup';
import CommentsForm from './comment/CommentsForm';
import {toast} from 'react-toastify';
import ApprovalForm from './approval/ApprovalForm';
import Multi from "@pages/common/selectBox/Multi";
import DyPageInc from "@pages/pj/rg/DyPageInc";
import ModalBasic from '@pages/common/modal/ModalBasic';
import FullCalendarForm from './fullcalendar/FullCalendarForm';
import {CustomTextarea} from './inputs/CustomTextarea';
import {ContentHeader} from "@components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsAlt, faExpandArrowsAlt} from "@fortawesome/free-solid-svg-icons";
import {FullScreen, useFullScreenHandle} from "react-full-screen";
import Editor from "@pages/common/ckeditor/Editor";
import {QuillEditor} from "@pages/common/quilleditor/QuillEditor";
import {CustomHidden} from "@pages/common/inputs/CustomHidden";

// import CustomInput from "@pages/common/inputs/CustomInput";

interface Props {
    // onSubmit: (data: unknown) => void;
    labelButtonSubmit?: string;
    titleForm?: string;
    initialValues: unknown;
    handleChange: unknown;
    dbValue?: string;
    validationSchema: SchemaForm;
    ltvp: InputProps[];
}

// export const Form = forwardRef((props : Props, ref) => {
export const Form = (props: Props) => {
    const {
        initialValues,
        dbValue, // real db data
        ltvp,
        handleChange,
        cardW,
        validationSchema
    } = props;


    console.log(`###########Form.props#########`, props);

    const formMethods = useForm({
        resolver: yupResolver(ltvp.validationSchema)
        , mode: "onSubmit"
    });

    const navigate = useNavigate();
    const labelButtonSubmit = "Submit";
    const pageWidth = 370;

    const location = useLocation();
    const pageName = location.pathname.split('/').reverse()[0];

    let keys = '';
    if (ltvp.p.keys !== undefined) {
        keys = ltvp.p.keys;
    } else {
        keys = "noincpage";
    }

    if (ltvp.ltv === undefined || ltvp.ltv.label === 'test') {
        return false;
    }
    // console.log('Form_ltvp==', ltvp);
    const eltv = eval(ltvp.ltv);
    const eltvp = eval(ltvp);

    let card_title = "";
    // let card_count = 1;
    let card_height = "";
    let card_span = "";

    eltv.map(({value, type, label}, index) => {
        // const card = `card${i+1}`;
        if (value !== '') {
            try {
                let evalue = eval('(' + value + ')');
                // let card_name = eval("evalue.card_pro"+(i+1));
                console.log(" label===", label, " type=", type, " evalue==", evalue, " evalue.length=======",evalue.length);
                if (type === 'cardProps') {

                    for(let i =0; i<evalue.length;i++){
                        card_title=card_title+evalue[i].name+",";
                        card_span=card_span+evalue[i].span+",";
                    }

                    card_title = card_title.substring(0,card_title.length-1);
                    card_span = card_span.substring(0,card_span.length-1);
                    console.log(" card_title===", card_title);
                    console.log(" card_span===", card_span);
                    // card_count = evalue.count;
                    // card_span = evalue.span;
                    card_height = evalue.height !== undefined ? "" : evalue.height;
                }
            } catch (e) {
                console.log(" value===", value, " label===", label);
                toast.error(label + " 의 Value 등록이 잘못되었습니다." + value);
            }
        }
    });

    const user = useSelector((state: any) => state.auth.currentUser);
    const permission = useSelector((state: any) => state.auth.permission);


    console.log(" form_user===", user.user_id, user.corp_cd);
    const prog_id = eltvp.p.prog_id;
    const chk_save_auth = prog_id + '_S';
    const chk_del_auth = prog_id + '_D';

    let save_auth = false
    let del_auth = false
    if (user.role1_cd === 'USER_ROLE_SYS_ADMIN') {
        save_auth = true;
        del_auth = true;
    } else {
        save_auth = permission.indexOf(chk_save_auth) > -1 ? true : false;
        del_auth = permission.indexOf(chk_del_auth) > -1 ? true : false;
    }

    console.log(" save_auth===", save_auth, chk_save_auth);
    console.log(" del_auth===", del_auth, chk_del_auth);

    let searchUrl = '';
    let saveUrl = '';
    let saveNoSubmitUrl = '';
    let reloadAfterSubmit = '';
    let deleteUrl = '';
    let listUrl = '';
    let listName = '';
    let saveNoSubmit = false;
    let buttonExist = false;
    let appvUrl = '';
    let appvName = '';
    let appvSeq = 0;
    let skipButton = false;
    let readonlyParam = false;

    const buttonSetup = () => {
        eltv.map(({value, type, label}, index) => {
            // console.log("props.card=",props.card, " type=",type, "props.includePage==", props.includePage   );
            // value=JSON.stringify(value);
            if (value !== '') {
                let evalue = "";

                try {
                    evalue = eval('(' + value + ')');

                } catch (e) {
                    toast.error("Check form data");
                    navigate("/CM/PC/CMPC100I");
                }

                // console.log('props.ltvp.p========',props.ltvp.p);
                // console.log('evalue222========',evalue, 'type==',type, " props.card==",props.card , " evalue.card==", evalue.card, "includePage==", props.includePage, "skipButton==",skipButton);
                console.log('evalue.value===========================', evalue.value);
                console.log('dbValue=========================', dbValue, label);

                if (type === 'button' && (!props.includePage)) {
                    console.log('buttonType_evalue=================', evalue.card, evalue);
                    saveNoSubmitUrl = evalue.saveNoSubmit === undefined ? '' : evalue.saveNoSubmit;
                    saveNoSubmit = evalue.saveNoSubmit === undefined ? false : true;
                    reloadAfterSubmit = evalue.reloadAfterSubmit === undefined ? "" : evalue.reloadAfterSubmit;
                    searchUrl = evalue.search === undefined ? '' : evalue.search;
                    saveUrl = evalue.save === undefined ? '' : evalue.save;
                    deleteUrl = evalue.delete === undefined ? '' : evalue.delete;
                    listUrl = evalue.list === undefined ? '' : evalue.list.url;
                    listName = evalue.list === undefined ? '' : evalue.list.name;
                    buttonExist = true;

                    console.log('saveUrl=', saveUrl, 'searchUrl=', searchUrl, 'deleteUrl=', deleteUrl, 'listUrl=', listUrl, buttonExist);
                    // }else if(type ==='approval' && (props.includePage)  ){
                } else if (type === 'approval' && dbValue[`${label}`] !== undefined) {

                    const appv_array = JSON.parse(dbValue[`${label}`]);
                    let is_approving = false;
                    let is_firsta_pprover = false;
                    for (let a = 0; a < appv_array.length; a++) {
                        if (a === 0 && user.user_id === appv_array[a].approver_id && "" === appv_array[a].appv_dtm) {
                            is_approving = true;
                            appvSeq = 0;
                        } else if (a !== 0 && user.user_id === appv_array[a].approver_id && "" === appv_array[a].appv_dtm && "" !== appv_array[a - 1].appv_dtm) {
                            is_approving = true;
                            appvSeq = a;
                        }
                    }

                    if (is_approving) {
                        appvName = 'Approval';
                        buttonExist = true;
                    }
                }
            }
        })
    }

    const [open, setOpen] = useState([false]);
    const toggleHandler = (i: number) => () => {
        const o = {...open};
        o[i] = !o[i];
        setOpen(o);
    };

    let card_span_arr = card_span.split(",");
    let card_title_tmp = card_title.split(",");

    console.log("card_span_arr===", card_span_arr);

    const buildCard = () =>
        card_span_arr.map((key, i) => {
                eltvp.p = {...eltvp.p, card_span: card_span_arr[i]};

                let cspan = `col-sm-${card_span_arr[i]}`;
                const screen = useFullScreenHandle();
                let card = `card${i + 1}`;
                let cardShop = `#card${i + 1}`;

                return (
                    <div className={cspan}>
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">{card_title_tmp[i]}</h3>
                                <div className="card-tools">
                                    {/*
                        <button className="btn btn-tool" type="button" onClick={paramSet} title="Search">
                          <i className="fa fa-search"/>
                        </button>
*/}
                                    <button type="button" className="btn btn-tool btn-sm"
                                            data-toggle="collapse"
                                            data-target={cardShop}
                                            onClick={toggleHandler(i)}
                                    >
                                        <i className={open[i] ? ("fas fa-plus") : ("fas fa-minus")}></i>
                                    </button>

                                    <button type="button" className="btn btn-tool" data-card-widget="maximize"
                                            title="Expand screen" onClick={screen.enter}>
                                        <FontAwesomeIcon icon={faExpandArrowsAlt}/>
                                    </button>

                                </div>
                            </div>
                            {/*
                    <div class="collapse" id={card} className={open[i] ? ('collapse') : ('show')}>
                      <div className='card card-body'>
                        */}

                            <div id={card} className={open[i] ? ('card-body width collapse') : ('card-body width show')}>

                                <FullScreen handle={screen}>
                                    {buildCustomInputs(i)}
                                </FullScreen>
                            </div>
                            {/*                    </div>*/}
                        </div>
                    </div>
                )
            }
        )

    let provider_exist = (JSON.stringify(ltvp.ltv).indexOf('provider') > -1) ? true : false;

    // console.log('provider_exist===============',provider_exist);
    // console.log('ltvp.ltv==',ltvp.ltv);
    // console.log('ltvp.ltv.indexOf(\'provider\')==',ltvp.ltv.indexOf('provider'));


    console.log('dbValue====================================================', dbValue);

    const buildCustomInputs = (cardnum) =>
        eltv.map(({value, type, label}, index) => {
            let component_type = '';

            // const label_ko = i18f(`views.common.${label}`);

            const card = `card${cardnum + 1}`;
            // cardnum=cardnum+1;

            index = keys + '_' + label + '_' + index;
            // let dummyProp = Math.random();
            // index = index + '-' + Date.now();
            //    index = crypto.randomUUID();
            if (value !== '') {

                component_type = type;
                let evalue = "";

                try {
                    evalue = eval('(' + value + ')');
                } catch (e) {
                    // toast.error("Check form data");
                    return navigate("/CM/PC/CMPC100I", {state: {name: `CMPC100I`, params: ''}});
                }
                console.log("evalue_______________", evalue)
                console.log("evalue___card__________________", evalue.card)
                console.log("evalue___value__________________", evalue.value)
                let card_dbvalue = evalue.card !== undefined ? evalue.card : evalue.value !== undefined ? evalue.value[0].card : "";
                let customer_id = dbValue === undefined || dbValue['customer_id'] === undefined ? '' : dbValue['customer_id'];
                let provider_ids = dbValue === undefined || dbValue['provider_ids'] === undefined ? '' : dbValue['provider_ids'];
                let is_provider = evalue.provider === undefined ? false : true;
                let tmp_readonly = evalue.readonly === undefined ? false : true;

                // console.log("form_user=====",user);
                // console.log("is_provider=====",is_provider);
                // console.log("provider_ids=====",provider_ids);
                // console.log("provider_exist=====",provider_exist);
                // console.log("customer_id=====",customer_id);

                readonlyParam = false;
                if (user.role1_cd === 'USER_ROLE_SYS_ADMIN') {
                    readonlyParam = false;
                } else if (customer_id.indexOf(user.user_id) > -1) {
                    readonlyParam = false;
                } else if (tmp_readonly) {
                    readonlyParam = true;
                } else if (provider_exist && !is_provider && provider_ids.indexOf(user.user_id) > -1) {
                    readonlyParam = true;
                } else if (is_provider && provider_ids.indexOf(user.user_id) > -1) {
                    readonlyParam = false;
                }

                // console.log("label=", label, " dbValue=", dbValue[`${label}`], " index=", index, " tmp_readonly=", tmp_readonly, " customer_id=", customer_id, " provider_exist=", provider_exist, " provider_ids=", provider_ids, " user.user_id=", user.user_id, " readonlyParam=", readonlyParam, " indexOf(user.user_id)=", provider_ids.indexOf(user.user_id));

                if (card === card_dbvalue) {


                    let key_col = '';
                    if (type === 'grid') {

                        const gridId = evalue.gridId;
                        console.log("evalue_card______________", evalue.card);
                        const eval_card = evalue.card !== undefined ? evalue.card : "card1";

                        console.log('form_evalue==', evalue);

                        props.ltvp.p = {...props.ltvp.p, gridId};

                        try {

                            evalue.colModel.map((data) => {
                                if (data.key && eval_card === card_dbvalue) {
                                    key_col = data.name;
                                    console.log("form_key_col_", key_col);
                                    if (key_col !== 'user_id' || props.ltvp.p.prog_id === 'CMUC200I') {
                                        console.log("_____form_key_col_user_id_____", key_col);

                                        props.ltvp.p = {...props.ltvp.p, key_col};
                                    }
                                    console.log('key_col==', key_col);
                                }
                            });
                        } catch (e) {
                            navigate('/CM/PC/CMPC100I', {
                                state: {}
                            });

                        }
                        console.log('evalue=============', evalue);
                        if (evalue.source  !== undefined) {
                            let form_table = evalue.source !== undefined ? evalue.source : "";
                            props.ltvp.p = {...props.ltvp.p, form_table};
                            console.log('form_table=============', form_table, props.ltvp.p);
                        }

                        if (evalue.treeReader  !== undefined) {
                            let parent_col = evalue.treeReader.parent_id_field;
                            props.ltvp.p = {...props.ltvp.p, parent_col};
                            console.log('parent_col==', parent_col, props.ltvp.p);
                        }

                        console.log('evalue_select_params_______________', evalue.select_params);
                        if (evalue.select_params !== undefined) {

                            const select_params = evalue.select_params;
                            props.ltvp.p = {...props.ltvp.p, select_params};
                            console.log('select_params2__________________', props.ltvp.p);
                        }

                        if (evalue.const_params !== undefined) {
                            const const_params = evalue.const_params !== undefined ? evalue.const_params :"";
                            props.ltvp.p = {...props.ltvp.p, const_params};
                            console.log('const_params==', const_params, props.ltvp.p);
                        }

                        if (evalue.order_by !== undefined) {
                            const order_by = evalue.order_by !== undefined ? evalue.order_by : "";
                            props.ltvp.p = {...props.ltvp.p, "order_by": evalue.order_by};
                            console.log('props.ltvp.p==', props.ltvp.p);
                        }
                    }

                    // console.log('From_props_ltvp_p==', props.ltvp.p);

                    switch (component_type) {
                        case 'approval':
                            return (
                                <ApprovalForm
                                    key={index}

                                    readonly={readonlyParam}
                                    gridInfo={value}
                                    label={label}
                                    p={eltvp.p}
                                    handleChange={handleChange}
                                    defaultValue={dbValue ? dbValue[`${label}`] : ''}
                                />
                            );
                        case 'button':
                            return (
                                <>

                                </>
                            );
                        case 'calendar':
                            return (
                                <FullCalendarForm
                                    {...eltvp}
                                    dbValue={dbValue}
                                    label={label}
                                    key={index}
                                    id={label}
                                    handleChange={handleChange}
                                    defaultValue={dbValue ? dbValue[`${label}`] : ''}
                                />
                            );
                        case 'comment':
                            return (
                                <CommentsForm
                                    {...evalue}
                                    // id={label}
                                    // dbValue={dbValue}
                                    label={label}
                                    key={index}
                                    commentsItem={eltvp.p}
                                />
                            );
                        case 'dept':
                            return (
                                <CustomSelectDept
                                    {...evalue}
                                    label={label}
                                    readonly={readonlyParam}
                                    // id={label}
                                    key={index}
                                    handleChange={handleChange}
                                    userInfo={value}
                                    defaultValue={dbValue ? dbValue[`${label}`] : ''}
                                />
                            );
                        case 'date':
                            return (
                                <CustomDateInput
                                    // id={label}
                                    seq={index}
                                    label={label}
                                    readonly={readonlyParam}
                                    handleChange={handleChange}
                                    defaultValue={dbValue ? dbValue[`${label}`] : ''}
                                    key={index}
                                />
                            );
                        case 'editor':
                            return (
                                <QuillEditor
                                    id={label}
                                    seq={index}
                                    label={label}
                                    ltvp={ltvp}
                                    readonly={readonlyParam}
                                    handleChange={handleChange}
                                    defaultValue={dbValue ? dbValue[`${label}`] : ''}
                                    key={index}
                                />
                            );
                        /*
                      case 'editor':
                        return (
                            <Editor
                                id={label}
                                seq={index}
                                label={label}
                                ltvp={ltvp}
                                readonly={readonlyParam}
                                handleChange={handleChange}
                                defaultValue={dbValue ? dbValue[`${label}`] : ''}
                                key={index}
                            />
                        );
                        */
                        case 'file':
                            return (
                                <MyDropzoneForm
                                    {...evalue}
                                    dbValue={dbValue}
                                    key={index}
                                    readonly={readonlyParam}
                                    label={label}
                                    id={label}
                                    handleChange={handleChange}
                                    defaultValue={dbValue ? dbValue[`${label}`] : ''}
                                />
                            );
                        case 'grid':
                            return (
                                <JqGridForm
                                    key={index}
                                    // id={label}
                                    readonly={readonlyParam}
                                    gridInfo={value}
                                    label={label}
                                    p={eltvp.p}
                                    handleChange={handleChange}
                                    defaultValue={dbValue ? dbValue[`${label}`] : ''}
                                    // ref={gridref}
                                />
                            );
                        case 'hidden':
                            return (
                                <CustomHidden

                                    key={index}
                                    type="hidden"

                                    label={label}
                                    handleChange={handleChange}
                                    // defaultValue={dbValue ? dbValue[`${label}`] : evalue.value}
                                    defaultValue={evalue ? evalue : ''}
                                />
                            );
                        case 'includePage':
                            return (
                                <DyPageInc
                                    {...evalue}
                                    key={index}
                                    readonly={readonlyParam}
                                    id={label}
                                    defaultValue={dbValue ? dbValue[`${label}`] : ''}
                                    // onChange={(e) => onChangeForm(e, index)}
                                />
                            );
                        case 'selectMulti' :
                            return (
                                <Multi
                                    key={index}
                                    readonly={readonlyParam}
                                    options={value}  //?
                                    // id={label}
                                    defaultValue={dbValue ? dbValue[`${label}`] : ''}
                                    placeholder="value"
                                    handleChange={handleChange}
                                    // onChange={(e) => onChangeForm(e, index)}
                                />
                            );
                        case 'taskGantt':
                            return (
                                <GanttGroup
                                    {...evalue}
                                    readonly={readonlyParam}
                                    label={label}
                                    // id={label}
                                    tasks={dbValue}
                                    handleChange={handleChange}
                                    defaultValue={dbValue ? dbValue[`${label}`] : ''}
                                    key={index}
                                />
                            );
                        case 'text':
                            return (
                                <CustomInput
                                    {...eltvp}
                                    id={label}
                                    seq={index}
                                    readonly={readonlyParam}
                                    label={label}
                                    dbValue={dbValue ? dbValue[`${label}`] : ''}
                                    handleChange={handleChange}
                                    key={index}
                                />
                            );
                        case 'textarea':
                            return (
                                <CustomTextarea
                                    {...eltvp}
                                    seq={index}
                                    label={label}
                                    id={label}
                                    readonly={readonlyParam}
                                    value={evalue}
                                    tasks={dbValue}
                                    handleChange={handleChange}
                                    dbValue={dbValue ? dbValue[`${label}`] : ''}
                                    key={index}
                                />
                            );
                        case 'user':
                            return (
                                <CustomSelectUser
                                    {...evalue}
                                    {...eltvp}
                                    key={index}
                                    readonly={readonlyParam}
                                    label={label}
                                    id={label}
                                    handleChange={handleChange}
                                    userInfo={value}
                                    dbValue={dbValue ? dbValue[`${label}`] : ''}
                                />
                            );
                        case 'selectSingle':
                            return (
                                <CustomSelectSingle
                                    {...evalue}
                                    id={label}
                                    readonly={readonlyParam}
                                    label={label}
                                    value={value}
                                    ltvp={ltvp}
                                    handleChange={handleChange}
                                    defaultValue={dbValue ? dbValue[`${label}`] : ''}
                                    key={index}
                                />
                            );
                        case 'radio':
                            return (
                                <CustomRadioGroup
                                    {...evalue}
                                    key={index}
                                    id={label}
                                    readonly={readonlyParam}
                                    p={eltvp.p}
                                    label={label}
                                    defaultValue={dbValue ? dbValue[`${label}`] : ''}
                                    handleChange={handleChange}
                                />
                            );
                        default:
                            return (
                                <></>
                            );
                    }
                }
            }
        });

    const saveHandleFunction = async () => {
        props.ltvp.p = {...props.ltvp.p, 'oper': 'insert'};
        saveCommFunc();
    }

    const delHandleFunction = async () => {
        props.ltvp.p = {...props.ltvp.p, 'oper': 'del'};
        saveCommFunc();
    };

    const saveCommFunc = async () => {
        let rc = dbValue.record_category;

        console.log("rc2==============", rc);
        let bgc = '';
        let scobj = {};
        if (rc !== undefined) {
            if (rc === 'SCHE_DIV_C') bgc = '#00c0ef';
            else if (rc === 'SCHE_DIV_D') bgc = '#3c8dbc';
            else if (rc === 'SCHE_DIV_T') bgc = '#605ca8';
            else if (rc === 'SCHE_DIV_P') bgc = '#f39c12';
            else if (rc === 'SCHE_DIV_J') bgc = '#0000FF';
            else if (rc === 'SCHE_DIV_I') bgc = '#DB2828';
            scobj = {"border_color": bgc, "background_color": bgc}
        }

        let jdata = '';

        //메뉴구성의 경우 입력값을 저장한다
        if (props.ltvp.p.form_table === 'pub_menu_r') {
            const db_form_table = dbValue.form_table;
            const db_program_url = dbValue.program_url;

            jdata = {...dbValue, db_form_table, db_program_url, ...props.ltvp.p, ...scobj};
            console.log("jdata_CMPC300I================================", jdata);
        } else {
            //proj setting proj_cd value set
            const tmp_proj_cd = dbValue.proj_cd !== undefined ? dbValue.proj_cd : '';
            if (tmp_proj_cd !== '') {
                jdata = {...dbValue, ...props.ltvp.p, ...scobj, proj_cd: tmp_proj_cd};
            } else {
                jdata = {...dbValue, ...props.ltvp.p, ...scobj};
            }
            //hide null to params optimise
            jdata = {...jdata, hide: ""};
            console.log("jdata_etc================================", jdata);
        }

        console.log("dbValue================================", dbValue, "\nprops.ltvp.p=", props.ltvp.p, "\nscobj=", scobj);

        const pv = JSON.stringify({jdata});
        const url = saveNoSubmitUrl || saveUrl;

        let response = await saveHandle(url, pv);

        console.log("response================================", response);
        try {
            if (reloadAfterSubmit !== "") {
                const arr = response.rows;
                console.log("arr=", arr);
                if (reloadAfterSubmit.indexOf("grid") > -1) {

                    await $(`#${reloadAfterSubmit}`).jqGrid('setGridParam', {
                        datatype: 'jsonstring',
                        datastr: arr
                    }).trigger('reloadGrid');

                } else if (reloadAfterSubmit.indexOf("fullcalendar") > -1) {
                    const program_url = props.ltvp.p.program_url;

                    console.log("arr[0]=================================", arr[0]);
                    navigate(`${program_url}`, {
                        state: {name: `${props.ltvp.p.menu_nm_short}`, params: {...arr[0]}}
                    });
                }
            }

            if (response.ERROR_CODE === undefined)
                toast.success(i18n.t(`views.messages.saveDone`));

        } catch (error) {
            console.log("error=", error);

            toast.error(i18n.t(`views.messages.saveFail`));
        }
    }


    const searchFunction = async (e) => {
        const link_url = props.ltvp.p.program_url;
        navigate(`${link_url}`
            , {state: {name: `${props.ltvp.p.prog_id}`, params: ''}}
        );

        console.log("link_url========", link_url);
        return (
            <Link to={link_url}/>
        );
    };

    const appvFunction = async (e) => {
        setModalOpen(true);
    }

    function goList() {
        navigate(`${listUrl}`, {
            state: {name: `${listName}`, params: ''}
        });
    }


    const [modalOpen, setModalOpen] = useState(false);
    const showModal = () => {
        setModalOpen(true);
    };

    return (
        <FormProvider {...formMethods}>
            <form
                onSubmit={formMethods.handleSubmit(saveHandleFunction)}
                className="form-horizontal col-sm-12"
            >

                {buttonSetup()}

                {buttonExist && (
                    <div className="text-right mr-2 mb-2">

                        {searchUrl && (
                            <button className="btn btn-outline-primary btn-sm ml-3" type="button"
                                    onClick={searchFunction}>
                                {i18f(`button.label.search`)}
                            </button>
                        )}

                        {saveNoSubmitUrl && save_auth && (
                            <button className="btn btn-outline-primary btn-sm ml-3" type="button"
                                    onClick={saveHandleFunction}
                            >
                                Save
                            </button>
                        )}

                        {saveUrl && save_auth && (
                            <button className="btn btn-outline-primary btn-sm ml-3" type="submit">
                                {i18f(`button.label.save`)}
                            </button>
                        )}

                        {deleteUrl && (
                            <button className="btn btn-outline-danger btn-sm ml-3" type="button"
                                    onClick={delHandleFunction}>
                                {i18f(`button.label.delete`)}
                            </button>
                        )}

                        {listUrl && (
                            <button className="btn btn-outline-secondary btn-sm ml-3" type="button" onClick={goList}>
                                {i18f(`button.label.list`)}
                            </button>
                        )}

                        {appvName && (
                            <button className="btn btn-outline-primary btn-sm ml-3" type="button"
                                    onClick={appvFunction}>
                                Approval
                            </button>
                        )}

                    </div>

                )}


                {modalOpen &&
                <div className="form-group d-flex justify-content-end">
                    <div className="col-sm-12 conainter-fluid">
                        <div className="form-group">
                            <div className="input-group">
                                <label className="col-sm-2 col-form-label"></label>
                                <div className="col-sm-10 conainter-fluid">
                                    <ModalBasic setModalOpen={setModalOpen} dbValue={dbValue} ltvp={ltvp}
                                                appvSeq={appvSeq}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }

                <div className="container-fluid">
                    <div className="row">

                        {buildCard()}

                    </div>
                </div>

            </form>
        </FormProvider>


    );
};

//end Form

export function getMultiSelectUrl() {
}

//
export function i18f(m: string) {
    const {t} = i18n;
    return t(m);
}
