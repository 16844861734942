import {Task} from '../../dist/types/public-types';

export function initTasks(props) {
  const currentDate = new Date();
  console.log(
    'initTasks_props#################',
    props.label,
    props.tasks[props.label],
    props
  );

  let tmpTasks: Task[] = [];
  let tmpTask = {};

  // if (`${props.tasks[props.label]}` === undefined) {
  // tmpTasks = [
  //   {
  //     start: new Date(2020, 1, 1),
  //     end: new Date(2020, 1, 2),
  //     name: 'No tasks found',
  //     id: 'task_0',
  //     type: 'task',
  //     progress: 45,
  //     isDisabled: true,
  //     styles: {progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d'}
  //   }
  // ];
  // }

  console.log('helper_props.tasks=', props.tasks);
  if (props.tasks !== undefined && props.tasks.hide !== undefined && props.tasks.hide !== "") {

    let project_task_cd = '';
    const ts = props.tasks.hide.map((item, index) => {
      const g = props.tasks.hide[index];
      // console.log('g==', g);
      let start = g.plan_start_dtm ? g.plan_start_dtm : '';
      let end = g.plan_stop_dtm ? g.plan_stop_dtm : '';
      const record_nm = g.record_nm ? g.record_nm : '';
      const task_cd = g.task_cd ? g.task_cd : '';
      const progress_rate = g.progress_rate ? g.progress_rate : '';
      const dependencies = g.depend ? g.depend : null;
      const type1 =
        g.t_level == 0
          ? 'project'
          : g.milestone_yn == 'Y'
          ? 'milestone'
          : 'task';

      if (index === 0) {
        project_task_cd = g.task_cd;
      }

      const ver = g.ver;
      const corp_cd = g.corp_cd;
      const proj_cd = g.proj_cd;

      // console.log('start=', start);
      // console.log('record_nm=', record_nm);
      // console.log('task_cd=', task_cd);
      // console.log('dependencies=', dependencies);

      let startTmp = new Date(start);
      let endTmp   = new Date(end);

      const interval = endTmp.getTime()-startTmp.getTime();

      //to prevent error
      if(interval<0) end = start;

      tmpTask = {
        start: new Date(start),
        end: new Date(end),
        name: record_nm,
        id: task_cd,
        type: type1,
        progress: progress_rate,
        isDisabled: false,
        dependencies: [dependencies],
        styles: {progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d'},
        hideChildren: index === 0 ? false : null,
        project: index !== 0 ? project_task_cd : null,
        ver: ver,
        corp_cd: corp_cd,
        proj_cd: proj_cd
      };
      tmpTasks = [...tmpTasks, tmpTask];
    });
  } else {

    tmpTasks = [
      {
        // start: new Date(2023, 1, 1),
        // end: new Date(2023, 1, 2),
        start: new Date(),
        end: new Date(),
        name: 'No tasks found',
        id: 'task_0',
        type: 'task',
        progress: 45,
        isDisabled: true,
        styles: {progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d'}
      }
    ];
  }

  // console.log('tmpTasks===', tmpTasks);
  const returnTasks = tmpTasks;

   // console.log('returnTasks===', returnTasks);
  return returnTasks;
}

export function getStartEndDateForProject(tasks: Task[], projectId: string) {
  console.log('getStartEndDateForProject===', tasks, 'projectId=',projectId);
  const projectTasks = tasks.filter((t) => t.project === projectId);
  let start = projectTasks[0].start;
  let end = projectTasks[0].end;
  console.log('start===', start, end);
  for (let i = 0; i < projectTasks.length; i++) {
    const task = projectTasks[i];

    if (start.getTime() > task.start.getTime()) {
      start = task.start;
    }

    if (end.getTime() < task.end.getTime()) {
      end = task.end;
    }
  }
  return [start, end];
}
