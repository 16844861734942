import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useFormik} from 'formik';
// import {useTranslation} from 'react-i18next';
import {loginUser} from '@store/reducers/auth';
import {setWindowClass, removeWindowClass} from '@app/utils/helpers';
import {PfButton, PfCheckbox} from '@profabric/react-components';
import * as Yup from 'yup';
import {Form, InputGroup} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {ACCESS_TOKEN, REFRESH_TOKEN} from '../../constants';
import {login, getUserSummary, tokenLogin} from '../../utils/APIUtils';

const Login = () => {
  const [currentUser, setCurrentUser] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [token, setToken] = useState(null);

  const [isAuthLoading, setAuthLoading] = useState(false);
  const [isAuthenticated, setAuthenticated] = useState(false);

  const [isGoogleAuthLoading, setGoogleAuthLoading] = useState(false);
  const [isFacebookAuthLoading, setFacebookAuthLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [t] = useTranslation();


  console.log("Login_refreshToken==",window.localStorage.getItem('refreshToken'));

  useEffect(() => {

    if (window.localStorage.getItem('refreshToken') !== null) {

      // const token ={'accessToken': localStorage.getItem('accessToken'), 'refreshToken': localStorage.getItem('refreshToken')}
      tokenLogin()
          .then((response) => {
            console.log("tokenLogin_response=",response);
            if(response !== undefined) {

              window.localStorage.setItem(ACCESS_TOKEN, response.accessToken);
              window.localStorage.setItem(REFRESH_TOKEN, response.refreshToken);

              setLoading(false);

              removeWindowClass('login-page');
              removeWindowClass('hold-transition');

              toast.success('Login is succeed!');
              setLoading(false);

              navigate('/workspace',{
                state: {res: response}
              });

              return;
            }
          })
          .catch((error) => {
            if (error.status === 402) {
              toast.error(
                  error.message || 'Login Exception'
              );
              setLoading(false);
              navigate('/')
            } else {
              toast.error(
                  error.message || 'Sorry! Something went wrong. Please try again!'
              );
              setLoading(false);
            }
          });
    }
  }, []);

    const loginfunc = async (values: {userId: string; password: string}) => {
      setLoading(true);

      const loginRequest = {...values};

      login(loginRequest)
        .then((response) => {
          console.log("login_response==",response);
          if(response.status !== undefined) {
            window.localStorage.setItem(ACCESS_TOKEN, response.accessToken);
            window.localStorage.setItem(REFRESH_TOKEN, response.refreshToken);
            setLoading(false);
            const res = {...response, data: {user_id:loginRequest.userId, password: loginRequest.password}};
            navigate('/workspace',{
              state: {res: res}
            });
          }
        })
        .catch((error) => {
          if (error.status === 401) {
            toast.error(
              error.message ||
                'Your Username or Password is incorrect. Please try again!'
            );
            setLoading(false);
          } else {
            toast.error(
              error.message || 'Sorry! Something went wrong. Please try again!'
            );
            setLoading(false);
          }
        });
    };

  const {handleChange, values, handleSubmit, touched, errors} = useFormik({
    initialValues: {
      userId: '',
      password: ''
    },
    validationSchema: Yup.object({
      // userId: Yup.string().email('Invalid email address').required('Required'),
      userId: Yup.string().required('Required'),
      password: Yup.string()
        .min(5, 'Must be 5 characters or more')
        .max(30, 'Must be 30 characters or less')
        .required('Required')
    }),
    onSubmit: (values) => {
      loginfunc(values);
      // login(loginRequest);
    }
  });

  setWindowClass('hold-transition login-page');

  return (
    <div className="login-box">
      <div className="card card-outline card-primary">
        <div className="card-header text-center">
          <Link to="/" className="h1">
            <b>RPMS</b>
            <span> Login</span>
          </Link>
        </div>
        <div className="card-body">
          <p className="login-box-msg">{t<string>('login.label.signIn')}</p>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <InputGroup className="mb-3">
                <Form.Control
                  id="userId"
                  name="userId"
                  // type="email"
                  placeholder="Email"
                  onChange={handleChange}
                  value={values.userId}
                  isValid={touched.userId && !errors.userId}
                  isInvalid={touched.userId && !!errors.userId}
                />
                {touched.userId && errors.userId ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.userId}
                  </Form.Control.Feedback>
                ) : (
                  <InputGroup.Append>
                    <InputGroup.Text>
                      <i className="fas fa-envelope" />
                    </InputGroup.Text>
                  </InputGroup.Append>
                )}
              </InputGroup>
            </div>
            <div className="mb-3">
              <InputGroup className="mb-3">
                <Form.Control
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  onChange={handleChange}
                  value={values.password}
                  isValid={touched.password && !errors.password}
                  isInvalid={touched.password && !!errors.password}
                />
                {touched.password && errors.password ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                ) : (
                  <InputGroup.Append>
                    <InputGroup.Text>
                      <i className="fas fa-lock" />
                    </InputGroup.Text>
                  </InputGroup.Append>
                )}
              </InputGroup>
            </div>

            <div className="row">
              <div className="col-8">
                <PfCheckbox checked={false}>
                  {t<string>('login.label.rememberMe')}
                </PfCheckbox>
              </div>
              <div className="col-4">
                <PfButton
                  block
                  type="submit"
                  loading={isAuthLoading}
                  disabled={isFacebookAuthLoading || isGoogleAuthLoading}
                >
                  {t<string>('login.button.signIn.label')}
                </PfButton>
              </div>
            </div>
          </form>

          <p className="mb-1">
            <Link to="/forgot-password">
              {t<string>('login.label.forgotPass')}
            </Link>
          </p>
          <p className="mb-0">
            <Link to="/register" className="text-center">
              {t<string>('login.label.registerNew')}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
export default Login;
