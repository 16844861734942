import React, {useRef, useState, useEffect} from 'react';
// import '../../../../public/common';

// interface SelectUserProp {
//   id: string;
//   onChange: object;
//   // onChange(name: string, value: number): void;
//   handleChange(name: string, value: number): void;
//   userInfo: object;
// }
export default function SelectUser(prop) {
  const inputRef = useRef();

  const [userNm, setUserNm] = useState('');
  // console.log('SelectUser====', prop);

  let defaultValue = '';
  if (prop.defaultValue !== undefined && prop.defaultValue !== '')
    defaultValue = JSON.parse(prop.defaultValue);
  // console.log('SelectUser_defaultValue====', defaultValue);

  function setUserInfo(e) {
    prop.handleChange({
      id: prop.id,
      value: {user_id: e.user_id, record_nm: e.record_nm}
    });

    inputRef.current.value = e.record_nm;
  }

  const userChange = (e: any, index: number) => {
    console.log('e.target====', e.target);
    console.log('prop.userInfo==============', prop.userInfo);
    // const values = [...formValues];
    // if (e.target) values[index].value = e.target.value;

    rpms.getUser(e.target, JSON.parse(prop.userInfo), setUserInfo);

    // setFormValues(values);
  };

  // export default function SelectUser(prop: SelectUserProp) {

  useEffect(() => {
    if (defaultValue !== '[]' && defaultValue !== '') {
      setUserInfo(defaultValue);
    }
  }, []);

  return (
    <div>
      <input
        type="text"
        className={`form-control 'col-md-6' }`}
        placeholder="value"
        id={prop.id}
        onChange={userChange}
        // value={userNm}
        ref={inputRef}
      />
    </div>
  );
}
