import React, {useEffect, useState, useRef, useCallback} from 'react';

import {useLocation, useParams} from 'react-router-dom';
import i18n from '@app/utils/i18n';
import {ContentHeader, MenuItem} from '@components';
import {useSelector} from 'react-redux';
import {FullScreen, useFullScreenHandle} from 'react-full-screen';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faArrowsAlt,
  faExpandArrowsAlt
} from '@fortawesome/free-solid-svg-icons';

import {getMenu, searchHandle} from '@app/utils/APIUtils';

import './Pjrg100i.css';
import {Form} from '../../common/Form';
import {getInputs} from '../../common/inputs/lib/getInputs';
import useDidMountEffect from '@app/pages/common/useDidMountEffect';
import {InputProps, SchemaForm} from "@pages/common/inputs/types";
import {toast} from "react-toastify";

interface Props {
    labelButtonSubmit?: string;
    titleForm?: string;

    initialValues: unknown;
    handleChange: unknown;
    dbValue?: string;
    ltvp: InputProps[];
}

interface RegisterFormType {}
 
const DyPageInc = ({...props}: Props) => {
    const screen2 = useFullScreenHandle();
    const user = useSelector((state: any) => state.auth.currentUser);
    const {state} = useLocation();
    const projCd =window.localStorage.getItem('proj_cd') === 'undefined' ? '': window.localStorage.getItem('proj_cd');
    const [ltvp, setLtvp] = useState();

    let ltv = "";
    console.log('state.params.page_id===',state.params.page_id );
    const [movePage, setMovePage] = useState(false);
    const [pageID, setPageID] = useState(state.params.page_id);
    const [values, setValues] = useState(state.params);
    const [keys, setKeys] = useState( state.params.keys);
    const [cardW, setCrdW] = useState();
  let p = {
    userInfo: user,
    user_id : user.user_id,
    corp_cd: user.corp_cd,
    proj_cd: projCd,
    ver: '1',
    prog_id: pageID,
    prog_no: projCd,
    cardW : "",
    form_table : ''
  };
    let form_table ="";

    const constKeys  = useCallback(() => setKeys(state.params.keys),    [keys, state.params.keys]  );
    const constMovePage= useCallback(() => setMovePage(false),    [pageID, state.params.keys]  );
    const constPageId  = useCallback(() => setPageID(state.params.page_id),    [state.params.page_id]  );

  // console.log("[[[DyPageInc_PAGE_RELOAD]]]\nchildMovePage:",  "\nstate.params:",state.params, "\nstate.params.keys:", state.params.keys, "values:",values, " pageID==",pageID, " keys==", keys);

    const constLtvp    = useCallback(async () =>  {
       getMenu(user, pageID)
       .then((response) => {
           console.log("constLtvp___________________",response);
           const arr = response.data[0];
           let ltv = arr.form_json;
           let program_url = arr.program_url;
           let menu_nm_short = arr.menu_nm_short;
           form_table = arr.form_table;
           p = {...p, form_table, program_url, menu_nm_short, keys};
           if(ltv.includes("validations") > 0){
               console.log("getInputs===",{ltv, ...{p}});
               // setLtvp(() => getInputs<RegisterFormType>({ltv, ...{p}}));
               setLtvp({ltv, ...{p}});
           }else if(menu_nm_short.includes('PJTList') || menu_nm_short.includes('ApprovalManagement')){
               p = {...p, form_table, program_url, menu_nm_short, ...{proj_cd: "", ver: ""}, keys};
               setLtvp({ltv, ...{p}});
           }else {
               setLtvp({ltv, ...{p}});
           }
       })
         .catch((error) => {
            console.error('Error loading sidebar menu!');
       });
    },[pageID, keys]  );

    const constValues    = useCallback(async () =>  {
        if(keys !== undefined) {
            const jdata = JSON.parse(keys);
            if (ltvp !== undefined) {
                const stVal = {...ltvp.p, ...jdata};
                console.log("stVal====", stVal);
                const pv = JSON.stringify(stVal);
                const url = "/common/selectCommon";
                let response = await searchHandle(url, pv);
                setValues(response.rows[0]);
                setMovePage(true);
            }
        }
    },[ltvp]  );

      useEffect( () => {
            // console.log('______________DyPageInc_constKeys____________________',keys);
            constKeys();
      } ,[constKeys]);

      useEffect( () => {
        // console.log('______________DyPageInc_constPageId____________________',pageID);
        constPageId();
      } ,[constPageId]);

      useEffect( () => {
          constValues();
        // console.log('______________DyPageInc_CONST_VALUES___________________',values );
      } ,[constValues]);

      useEffect( () => {
        // console.log('______________DyPageInc_constMovepage____________________',movePage);
        constMovePage();
      } ,[constMovePage]);
      //
      useEffect( () => {
        console.log('______________DyPageInc_constLtvp___________________',ltvp);
        constLtvp();
      } ,[constLtvp]);

      const handleChange = (e) => {
          console.log('______________handleChange__________________',ltvp);
           if (e.id || e.target.id) {
            const name = e.id || e.target.id;
            const value = e.value || e.target.value;
            setValues({
              ...values,
              [name]: value
            });
          }
      };

  // console.log('[DyPageInc_PAGE END]\nmovePage:', movePage, '\nvalues:', values, '\nltvp:', ltvp);

    if(state.params.page_id === undefined) return;

  return (
<>
      {
          movePage && (

                 <Form
                    ltvp={ltvp}
                    card="card2"
                    dbValue={values}
                    includePage={true}
                    handleChange={handleChange}
                  />
      )}
</>
   );
};

export default React.memo(DyPageInc);
