import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import {useFormik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {loginUser} from '@store/reducers/auth';
import {setWindowClass} from '@app/utils/helpers';
import {Form, InputGroup} from 'react-bootstrap';
import {PfButton, PfCheckbox} from '@profabric/react-components';

import * as AuthService from '../../services/auth';
import {registerEmailCheck, registerNewMember} from "@app/utils/APIUtils";
import {PulseLoader} from "react-spinners";

const Register = () => {
    const [isAuthLoading, setAuthLoading] = useState(false);
    const [isGoogleAuthLoading, setGoogleAuthLoading] = useState(false);
    const [isFacebookAuthLoading, setFacebookAuthLoading] = useState(false);
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const navigate = useNavigate();


    const codeRef = useRef();

    const register = async (email: string, password: string, mobile: string, nickName: string, codeValue: string) => {
        try {

            // const token = await AuthService.registerByAuth(email, password);
            setAuthLoading(true);
            const response = await registerNewMember(email, password, mobile, nickName, codeValue).then(res => {
                console.log("res_registerNewMember===========", res);
                if (res.status === '200') {
                    toast.success('Register is succeeded');
                    navigate('/login');

                } else  {
                    toast.error('Register failed');
                }

            });
        } catch (error: any) {
            toast.error(error.message || 'Failed');
            // setAuthLoading(false);
        }

        setAuthLoading(false);
    };

/*

    const registerByGoogle = async () => {
        try {
            setGoogleAuthLoading(true);
            const token = await AuthService.registerByGoogle();
            setGoogleAuthLoading(false);
            dispatch(loginUser(token));
            toast.success('Authentication is succeed!');
            navigate('/');
        } catch (error: any) {
            toast.error(error.message || 'Failed');
            setGoogleAuthLoading(false);
        }
    };

    const registerByFacebook = async () => {
        try {
            setFacebookAuthLoading(true);

            const token = await AuthService.registerByFacebook();
            setFacebookAuthLoading(false);
            dispatch(loginUser(token));
            toast.success('Register is succeeded!');
            navigate('/');
        } catch (error: any) {
            setFacebookAuthLoading(false);
            toast.error(error.message || 'Failed');
        }
    };
*/

    const {handleChange, values, handleSubmit, touched, errors} = useFormik({
        initialValues: {
            email: '',
            password: '',
            passwordRetype: '',
            codeValue: '',
            nickName: '',
            mobile: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email address').required('Required'),
            password: Yup.string()
                .min(5, 'Must be 5 characters or more')
                .max(30, 'Must be 30 characters or less')
                .required('Required'),
            passwordRetype: Yup.string()
                .min(5, 'Must be 5 characters or more')
                .max(30, 'Must be 30 characters or less')
                .required('Required')
                .when('password', {
                    is: (val: string) => !!(val && val.length > 0),
                    then: Yup.string().oneOf(
                        [Yup.ref('password')],
                        'Both password need to be the same'
                    )
                }),
            nickName: Yup.string()
                .min(3, 'Must be 3 characters or more')
                .max(10, 'Must be 10 characters or less')
                .required('Required'),
            mobile: Yup.number()
                .min(8, 'Must be 8 characters or more')
                .required('Required')
        }),
        onSubmit: (values) => {
            console.log("values====",values);
            register(values.email, values.password, values.mobile, values.nickName, values.codeValue);
        }
    });

    setWindowClass('hold-transition register-page');

    const [isLoading, setIsLoading] = useState(false);
    const [isGetCode, setIsGetCode] = useState(false);
    // const [isTimer, setIsTimer] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    // const [count, setCount] = useState(180);
    const [codeValue, setCodeValue] = useState('');
    const emailRegExp = /^[A-Za-z0-9_]+[A-Za-z0-9]*[@]{1}[A-Za-z0-9]+[A-Za-z0-9]*[.]{1}[A-Za-z]{1,3}$/;

    const onValidMail = useCallback(
        e => {
            e.preventDefault();
            setAuthLoading(true);
            let response = registerEmailCheck(values.email).then(res => {

                console.log("res===========", res);
                console.log("res.code_value===========", res.code_value);
                if (res.status === '200') {
                    setCodeValue(res.code_value);
                    setIsLoading(false);
                    setIsGetCode(true);
                    // setIsTimer(true);
                    // setCount(180);
                } else if (res.status === '401') {
                    // alert('이미 존재하는 이메일입니다.');

                    toast.error( '이미 존재하는 이메일입니다');
                    // } else if (res.status === '402') {
                    //   alert('이미 인증이 진행중입니다.');
                }
            });
            setAuthLoading(false);
        },
        [values]
    );

/*

    const Timer = ({count, setCount}) => {
        const formatTime = time => {
            const minutes = Math.floor(time / 60);
            const seconds = time % 60;
            return `${minutes.toString().padStart(2, '0')}:${seconds
                .toString()
                .padStart(2, '0')}`;
        };

        useEffect(() => {
            const id = setInterval(() => {
                setCount(count => count - 1);
            }, 1000);

            if (count === 0) {
                clearInterval(id);
            }
            return () => clearInterval(id);
        }, [count]);

        return (
            <div className="timerContainer">
                <span className="timerText">{formatTime(count)}</span>
            </div>
        );
    };
*/


    const handleEmail = (e) => {
        // setCodeValue(e.target.value);
    };


    const onValidCode = (e) => {
        e.preventDefault();
        // let response = registerCodeCheck(values.email, values.codeValue).then(res => {
        const iv = codeRef.current.value;
        console.log("codeValue==", codeValue, " iv==", iv);
        if (codeValue === iv) {
            setIsChecked(true);
        } else {
            // alert('인증 번호가 일치하지 않아요.');
            toast.error( '인증 번호가 일치하지 않아요');
        }
        // }
    }

    return (
        <div className="register-box" style={{width:500}}>
            <div className="card card-outline card-primary">
                <div className="card-header text-center">
                    <Link to="/" className="h1">
                        <b>RPMS</b>
                        {/*<span>LTE</span>*/}
                    </Link>
                </div>
                <div className="card-body">
                    <p className="login-box-msg">{t<string>('register.registerNew')}</p>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <InputGroup className="mb-3">
                                <Form.Control
                                    id="email"
                                    name="email"
                                    type="email"
                                    isValid={isChecked}
                                    disabled={(isChecked)}
                                    placeholder="Email"
                                    onChange={handleChange}
                                    value={values.email}
                                    // isValid={touched.email && !errors.email}
                                    isInvalid={touched.email && !!errors.email}
                                />
                                {touched.email && errors.email ? (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                ) : (
                                    <>
                                        {isLoading ? (
                                            <div className="emailCheckBtn">
                                                <PulseLoader color="#fff" size={3} margin={5}/>
                                            </div>
                                        ) : (
                                            <>
                                                {isChecked ? (
                                                    <InputGroup.Append>
                                                        <InputGroup.Text>
                                                            <i className="fas fa-envelope"/>
                                                        </InputGroup.Text>
                                                    </InputGroup.Append>
                                                ) : (
                                                    <button
                                                        onClick={onValidMail}
                                                        disabled={!emailRegExp.test(values.email) || isChecked}
                                                    >
                                                        {t<string>('register.mailValidation')}
                                                    </button>
                                                )
                                                }
                                            </>
                                        )
                                        }
                                    </>
                                )}
                            </InputGroup>
                        </div>

                        {isGetCode ? (
                            <>
                            <div className="mb-3">
                                <InputGroup className="mb-2">

                                    <Form.Control
                                        name="email"
                                        id="email"
                                        placeholder={t<string>('register.codeInput')}
                                        onChange={handleEmail}
                                        disabled={(isChecked)}

                                        // isValid={isChecked}
                                        ref={codeRef}
                                    />
                                    {isChecked ? (
                                        <InputGroup.Append>
                                            <InputGroup.Text>
                                                <i className="fas fa-check"/>
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    ) : (
                                        <button
                                            className="codeCheckBtn"
                                            onClick={onValidCode}
                                            // disabled={!(codeValue && codeValue.length >= 6)}
                                        >
                                            {t<string>('views.common.confirm')}
                                        </button>
                                    )}

                                </InputGroup>
                            </div>
                            </>
                        ) : null
                            }


                        {isChecked ? (
                            <>
                                <div className="mb-3">
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            id="password"
                                            name="password"
                                            type="password"
                                            placeholder="Password"
                                            onChange={handleChange}
                                            value={values.password}
                                            isValid={touched.password && !errors.password}
                                            isInvalid={touched.password && !!errors.password}
                                        />
                                        {touched.password && errors.password ? (
                                            <Form.Control.Feedback type="invalid">
                                                {errors.password}
                                            </Form.Control.Feedback>
                                        ) : (
                                            <InputGroup.Append>
                                                <InputGroup.Text>
                                                    <i className="fas fa-lock"/>
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                        )}
                                    </InputGroup>
                                </div>

                                <div className="mb-3">
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            id="passwordRetype"
                                            name="passwordRetype"
                                            type="password"
                                            placeholder="Retype password"
                                            onChange={handleChange}
                                            value={values.passwordRetype}
                                            isValid={touched.passwordRetype && !errors.passwordRetype}
                                            isInvalid={touched.passwordRetype && !!errors.passwordRetype}
                                        />

                                        {touched.passwordRetype && errors.passwordRetype ? (
                                            <Form.Control.Feedback type="invalid">
                                                {errors.passwordRetype}
                                            </Form.Control.Feedback>
                                        ) : (
                                            <InputGroup.Append>
                                                <InputGroup.Text>
                                                    <i className="fas fa-lock"/>
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                        )}
                                    </InputGroup>
                                </div>


                                <div className="mb-3">
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            id="mobile"
                                            name="mobile"
                                            // type="password"
                                            placeholder="Phone number"
                                            onChange={handleChange}
                                            // onChange={handleChange}
                                            // value={values.passwordRetype}
                                            isValid={touched.mobile && !errors.mobile}
                                            isInvalid={touched.mobile && !!errors.mobile}
                                        />

                                        {touched.mobile && errors.mobile ? (
                                            <Form.Control.Feedback type="invalid">
                                                {errors.mobile}
                                            </Form.Control.Feedback>
                                        ) : (
                                            <InputGroup.Append>
                                                <InputGroup.Text>
                                                    <i className="fas fa-phone"/>
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                        )}
                                    </InputGroup>
                                </div>

                                <div className="mb-3">
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            id="nickName"
                                            name="nickName"
                                            onChange={handleChange}
                                            // type="password"
                                            placeholder="Nick name"
                                            // value={values.nickName}
                                            isValid={touched.nickName && !errors.nickName}
                                            isInvalid={touched.nickName && !!errors.nickName}
                                        />

                                        {touched.nickName && errors.nickName ? (
                                            <Form.Control.Feedback type="invalid">
                                                {errors.nickName}
                                            </Form.Control.Feedback>
                                        ) : (
                                            <InputGroup.Append>
                                                <InputGroup.Text>
                                                    <i className="fas fa-user"/>
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                        )}
                                    </InputGroup>
                                </div>

                                <div className="row">
                                    <div className="col-7">
                                        <PfCheckbox checked={false}>
                                            <Link to="/">{t<string>('register.term')}</Link>
                                            <span> {t<string>('register.agree')}</span>

                                        </PfCheckbox>
                                    </div>
                                    <div className="col-5">
                                        <PfButton
                                            type="submit"
                                            block
                                            loading={isAuthLoading}
                                            // disabled={isFacebookAuthLoading || isGoogleAuthLoading}
                                            // disabled={isChecked}
                                        >
                                            {t<string>('views.common.register')}
                                        </PfButton>
                                    </div>
                                </div>
                            </>
                        ) : null
                        }

                    </form>
                    {/*
                    <div className="social-auth-links text-center">
                        <PfButton
                            className="mb-2"
                            block
                            onClick={registerByFacebook}
                            loading={isFacebookAuthLoading}
                            disabled={isAuthLoading || isGoogleAuthLoading}
                        >
                            <i className="fab fa-facebook mr-2"/>
                            {t<string>('login.button.signUp.social', {
                                what: 'Facebook'
                            })}
                        </PfButton>
                        <PfButton
                            block
                            theme="danger"
                            onClick={registerByGoogle}
                            loading={isGoogleAuthLoading}
                            disabled={isAuthLoading || isFacebookAuthLoading}
                        >
                            <i className="fab fa-google mr-2"/>
                            {t<string>('login.button.signUp.social', {what: 'Google'})}
                        </PfButton>
                    </div>
                    */}
                    <Link to="/login" className="text-center">
                        {t<string>('register.alreadyHave')}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Register;
