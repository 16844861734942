import React, {useEffect, useState, useRef, useCallback} from 'react';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import i18n from '@app/utils/i18n';
import {ContentHeader, MenuItem} from '@components';
import {useSelector} from 'react-redux';
import {FullScreen, useFullScreenHandle} from 'react-full-screen';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faArrowsAlt,
    faExpandArrowsAlt
} from '@fortawesome/free-solid-svg-icons';

import BasicSingle from '@app/pages/common/selectBox/BasicSingle';
import {toast} from 'react-toastify';
import Input from './Input';
import './Cmpc100i.css';
import {getAllMenu, getCompo, updateMenuFormJson} from '../../../utils/APIUtils';
import VisualForm from "@pages/cm/pc/VisualForm";

export function i18f(m: string) {
    const {t} = i18n;
    return t(m);
}

const Cmpc200i = () => {
    const user = useSelector((state: any) => state.auth.currentUser);
    const [values, setValues] = useState({});
    const [compoValues, setCompoValues] = useState([]);

    useEffect(() => {
        //2 parameter is program eng name
        getAllMenu(user, '')
            .then((response) => {
                const arr = response.data;
                console.log(
                    'Cmpc200i_getAllMenu_response_data=====================================',
                    response.data
                );
                setSelectMenus(response.data);
            })
            .catch((error) => {
                if (error.status === 401) {
                    // console.error('Error loading sidebar menu!');
                }
            });
    }, []);

    const [selectMenus, setSelectMenus] = useState([]);
    const {state} = useLocation();
    const title = i18n.t(`menusidebar.label.${state.name}`);
    const [formValues, setFormValues] = useState([]);

    console.log('PAGE_LOAD=============', formValues, ' state========', state);
    // const [movePage, setMovePage] = useState(false);
    const [toggle, setToggle] = useState(false);
    const inputRef = useRef();
    const selectRef = useRef();
    const selectMenuRef = useRef(null);

    //drag start
    const dragItem = useRef();
    const dragOverItem = useRef();
    const dragStart = (e, position) => {
        dragItem.current = position;
        console.log(e.target.innerHTML);
    };

    const dragEnter = (e, position) => {
        dragOverItem.current = position;
        console.log(e.target.innerHTML);
    };

    const drop = (e) => {
        const copyListItems = [...formValues];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        setFormValues(copyListItems);
    };
    //drag end
    const [clickedCpt, setClickedCpt] = useState([]);

    let navigate = useNavigate();
    const saveHandleSubmit = (e: { preventDefault: () => void }) => {
        e.preventDefault();

        let menuId = '';
        if (selectMenuRef.current) {
            menuId = selectMenuRef.current.props.value[0].menu_nm_eng;

            const fv = JSON.stringify(formValues);

            updateMenuFormJson(menuId, fv, user)
                .then((response) => {
                    const arr = response.data;
                    toast.success('저장 하였습니다.');

                    navigate('/CM/PC/CMPC200I', {
                        state: {...state}
                    });

                })
                .catch((error) => {
                    if (error.status === 401) {
                    }
                    toast.error('저장에 실패 하였습니다.');
                });
        }
    };

    function isJsonString(str) {
        try {
            if (str === '') return false;

            JSON.parse(str);
        } catch (e) {
            // console.log('str_is_not_jsonFormat__', str, e);
            return false;
        }
        return true;
    }

    let lastUpdate = null;
    const onChangeInput = (e: any, index: number) => {
        console.log('onChangeInput____________', e, 'index___', index);

        lastUpdate = Date.now();
        const timer = setTimeout(function () {
            const diff = Date.now() - lastUpdate;

            if (diff > 1000) {
                let tmp_values = null;
                tmp_values = [...formValues];

                if (e.target) {
                    console.log(' e.target.value___', e.target.value);
                    if (e.target.value) {
                        tmp_values[index].value = e.target.value;
                    } else {
                        tmp_values[index].value = '';
                    }
                }
                console.log('tmp_values____________', tmp_values);
                setFormValues(tmp_values);

            }
        }, 1000);
        return () => {
            clearTimeout(timer);
            console.log('_________clearTimeout_Formsortinig_____________');
        };
    };

    const onChangeCompo = (e: any) => {
        console.log(' onChangeCompo___target______________', e);

        if ((e && e.id !== undefined) || (e.target && e.target.id !== undefined)) {
            const name = e.id !== undefined ? e.id : e.target.id;
            const value = e.value !== undefined ? e.value : e.target.value;

            setValues({
                ...values,
                [name]: value
            });

            console.log('values_onChangeCompo________', values);
        }
    };

    useEffect(() => {

        console.log("values____________________", values);
        console.log("clickedCpt____________________", clickedCpt);
        if (clickedCpt[0] !== undefined) {
            let o = values;
            console.log("o____________________", o);
            let formarr = [...formValues];
            if (clickedCpt[0].type === 'grid' && o['header_object'] !== undefined) {
                const h = o['header_object'][0];
                const f = o['col_array'];
                const s = o['select_array'];
                const c = o['const_array'];
                console.log("h____________________", h);
                console.log("s____________________", s);
                console.log("c____________________", c);

                let tmp_url = "";
                if (h.treeGrid === true) {
                    if (h.url === 'select' || h.url === '/common/selectTreeCommon') {
                        tmp_url = '/common/selectTreeCommon';
                    } else if (h.url === 'save&select'|| h.url === '/common/saveSelectCommonRP') {
                        tmp_url = '/common/saveSelectCommonRP';
                    } else if (h.url === 'save&select'|| h.url === '/common/saveCommon') {
                        tmp_url = '/common/saveCommon';
                    }
                } else {
                    if (h.url === 'select'|| h.url === '/common/selectCommon') {
                        tmp_url = '/common/selectCommon';
                    } else if (h.url === 'save&select'|| h.url === '/common/saveSelectCommon') {
                        tmp_url = '/common/saveSelectCommon';
                    } else if (h.url === 'save&select'|| h.url === '/common/saveCommon') {
                        tmp_url = '/common/saveCommon';
                    }
                }

//                 console.log("h___onDblClickUrl_____",h.onDblClickUrl);
// console.log("h___onDblClickSetItem_____",h.ondblClickRow.url, h.ondblClickRow.setItem);

                h.onDblClickUrl= h.onDblClickUrl === undefined || h.onDblClickUrl === "" ? h.ondblClickRow.url : h.onDblClickUrl;
                h.onDblClickSetItem= h.onDblClickSetItem === undefined || h.onDblClickSetItem === "" ? h.ondblClickRow.setItem : h.onDblClickSetItem;
                // h.onDblClickSetItem !== undefined && h.onDblClickSetItem !== "" ? h.onDblClickSetItem: h.ondblClickRow.setItem;


                console.log("h___onDblClickUrl222_____",h.onDblClickUrl);
                const gridobj = {
                    'label': clickedCpt[0].label,
                    'type': 'grid',
                    'value':
                        JSON.stringify(
                            {
                                'card': h.card
                                ,'gridId': h.gridId
                                ,'url': tmp_url
                                ,'width': h.width
                                ,'ondblClickRow': {
                                    'url': h.onDblClickUrl,
                                    'setItem': h.onDblClickSetItem,
                                    'action': 'goPage'
                                }
                                , 'toppager': true
                                , 'search': h.search
                                ,'add': h.add
                                ,'edit': h.edit
                                ,'del': h.del
                                ,'loadonce': true
                                ,'shrinkToFit': true
                                ,'source': h.source
                                ,'rowNum': h.rowNum
                                ,'editModal': h.editModal
                                ,'treeGrid': h.treeGrid
                                ,'leaf_field': 'is_leaf'
                                ,'level_field': 't_level'
                                ,'parent_id_field': h.parent_id_field
                                ,'colModel': f
                                ,'select_params': s
                                ,'const_params':  c
                            }
                            , undefined, 4)

                }

                const itemToFind = formarr.find(function (item) {
                    return item.label === clickedCpt[0].label
                })
                const idx = formarr.indexOf(itemToFind)
                if (idx > -1) formarr.splice(idx, 1)

                formarr.push(gridobj);

                // formarr[clickedCpt[0].label]=gridobj;

                console.log("formarr____________", formarr);
                // ...clickedCpt[0].label:setGrid
            }

            if (clickedCpt[0].type === 'cardProps') {


                console.log("cardProps___o____________",o);

                // const h = o['header_object'][0];
                const f = o['col_array'];

                let name = "";
                let span = "";
                for(let i=0;i<o.col_array.length;i++){
                    name=name+o.col_array.name === undefined? "" : o.col_array.name +",";
                    span=span+o.col_array.span+",";
                }

                console.log("name____________",name);
                name=name.substring(0, name.length-1);
                span=span.substring(0, span.length-1);
                console.log("fffffffffffff____________",f);

                const gridobj = {
                    'label': clickedCpt[0].label,
                    'type': 'cardProps',
                    'value':
                        JSON.stringify(

                            {...f}

                        , undefined, 4)
                }

                console.log("gridobj22222___________",gridobj);
                formarr.push(gridobj);
                // formarr[clickedCpt[0].label] = gridobj;
                // formarr.push(gridobj);
            }

            if (clickedCpt[0].type === 'button') {

                const h = o.count;

                const f = [{'name': o.name, 'span': o.span}];

                const gridobj = {
                    'label': clickedCpt[0].label,
                    'type': 'grid',
                    'value':
                        JSON.stringify([
                            {
                                'gridId': h.gridId
                                , 'url': h.url
                                , 'width': h.width
                                , 'search': h.search
                                , 'add': h.add
                                , 'edit': h.edit
                                , 'del': h.del
                                , 'source': h.source
                                , 'rowNum': h.rowNum
                                , 'editModal': h.editModal
                                , 'colModel': f
                            }
                        ], undefined, 4)
                }

                formarr[clickedCpt[0].label] = gridobj;
                // formarr.push(gridobj);
            }

            if (clickedCpt[0].type === 'text') {
                const h = o['header_object'][0];
                const f = o['validation_array'];
                console.log("text_f_______________", f)

                let v = [];
                let o = {};

                for (let i = 0; i < f.length; i++) {
                    if (f.value) o = {'type': f[i].type, 'value': f[i].value, 'message': f[i].message}
                    else o = {'type': f[i].type, 'message': f[i].message}
                    v.push(o);
                }

                let gridobj =
                    {
                        "card": h.card,
                        "validation": v
                    }

                formarr[clickedCpt[0].label] = gridobj;
                // formarr.push(gridobj);
            }
            setFormValues(formarr);
        }
    }, [values]);

    const handleChange = (e: any, index: number) => {
        if (e.form_json) {
            setFormValues(eval(e.form_json))
            console.log("formjson_________", eval(e.form_json));
        } else {
            setFormValues([])
        }
        ;
    };

    const handleAddField = (e: { preventDefault: () => void }) => {
        e.preventDefault();

        const values = [...formValues];
        values.push({
            label: inputRef.current.value || 'label',
            type: selectRef.current.value || 'text',
            value: ''
        });
        // values.sort((a, b) => (a.order > b.order ? 1 : -1));
        setFormValues(values);
        setToggle(false);
    };

    const addBtnClick = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        setToggle(true);
    };

    const handleDeleteField = (e: any, index: number) => {
        const values = [...formValues];
        values.splice(index, 1);
        setFormValues(values);
    };

    const screen10 = useFullScreenHandle();
    const screen20 = useFullScreenHandle();

    const handleClick = (obj_label: string, obj_type: string, obj_value: string) => {
        console.log('handleClick_______', obj_label, obj_type);  //hide, grid


        getCompo(obj_type)
            .then((response) => {

                // setValues({});

                const arr_str = JSON.stringify(response.rows[0].compo_json);
                let arr = eval(response.rows[0].compo_json);

                console.log('handleClick_getCompo2_____________________', arr);

                if (response.rows !== undefined) {
                    //set ltv value
                    setCompoValues(arr);

                    let arr1ltv_tmp = formValues.filter((fv) => fv.label === obj_label);
                    setClickedCpt(arr1ltv_tmp);

                    let evalue = eval('(' + arr1ltv_tmp[0].value + ')');
                    if (obj_type === 'grid') {

                        console.log('grid_evalue____________________', evalue);
                        evalue.treeGrid = evalue.treeGrid === true ? evalue.treeGrid : false;

                        let header_object = [evalue];
                        let col_array = evalue.colModel !== undefined ? evalue.colModel : [];

                        let select_array = [];
                        if (evalue.select_params !== "" && evalue.select_params !== undefined) {
                            evalue.select_params.map(({name, value}, index) => {
                                    select_array.push({"name": name, "value": value});
                                }
                            )
                            /*
                            for (let [key, value] of Object.entries(evalue.select_params)) {
                                select_array.push({"name": key, "value": value});
                            }
                            */
                        }

                        let const_array = [];
                        if (evalue.const_params !== "" && evalue.const_params !== undefined) {
                            evalue.const_params.map(({name, value}, index) => {
                                    const_array.push({"name": name, "value": value});
                                }
                            )
                        }

                        evalue={};
                        evalue.header_object=header_object;
                        evalue.col_array=col_array;

                        if(select_array !== '[]') evalue.select_array=select_array;
                        if(const_array !== '[]') evalue.const_array=const_array;
                        setValues(evalue);

                    } else if (obj_type === 'cardProps') {
                        let col_array = [];

                        console.log('evalueevalueevalue00000_______________', evalue);
                        for(let i=0; i<evalue.length; i++){
                            col_array.push({'name':evalue[i].name, 'span':evalue[i]});
                            // col_array.push(evalue);
                        }
                        //
                        // evalue={};
                        // evalue.col_array=evalue;

                        console.log('col_array___________', col_array);
                        setValues(col_array);

                    }

                    // console.log('evalue222__________________', evalue);


                } else {
                    setCompoValues([]);
                    setValues([]);
                }
            })
            .catch((error) => {

            });
    }

    return (

        <div>
            <ContentHeader title={title}/>
            <section className="content">
                <div className="card-deck container-fluid ">


                    <div className="card col-sm-6" style={{height: 800 + 'em'}}>
                        <div className="card-header">
                            <h3 className="card-title">Title</h3>

                            <div className="card-tools">
                                <button
                                    type="button"
                                    className="btn btn-tool"
                                    title="Expand screen"
                                    onClick={screen10.enter}
                                >
                                    <FontAwesomeIcon icon={faExpandArrowsAlt}/>
                                </button>
                            </div>
                        </div>
                        <div className="card-body">


                            <FullScreen handle={screen10}>
                                {screen10.active && (
                                    <button type="button" onClick={screen10.exit}>
                                        <FontAwesomeIcon icon={faArrowsAlt}/>
                                    </button>
                                )}

                                <form className="form-horizontal">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <label htmlFor="Name" className="col-sm-2 col-form-label">
                                                Menu Name
                                            </label>
                                            <div className="col-sm-9">
                                                <BasicSingle
                                                    options={selectMenus}
                                                    handleChange={handleChange}
                                                    defaultValue=""
                                                    selectMenuRef={selectMenuRef}
                                                />

                                            </div>
                                        </div>
                                    </div>

                                    {
                                        formValues.map((obj, index) => (
                                            <div
                                                onClick={(e) => handleClick(obj.label, obj.type, obj.value)}

                                            >

                                                <Input
                                                    key={crypto.randomUUID()}
                                                    objValue={obj}
                                                    onChange={onChangeInput}

                                                    // onChange={}
                                                    index={index}
                                                    deleteField={handleDeleteField}

                                                    hidden={true}
                                                    draggable
                                                    onDragStart={(e) => dragStart(e, index)}
                                                    onDragEnter={(e) => dragEnter(e, index)}
                                                    onDragEnd={drop}
                                                    onDragOver={(e) => e.preventDefault()}
                                                />

                                            </div>

                                        ))}

                                    {!toggle ? (
                                        <div className="offset-sm-6">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={addBtnClick}
                                            >
                                                Add new
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="dialog-box">
                                            <input type="text" placeholder="label" ref={inputRef}/>
                                            <select ref={selectRef} className="form-control">
                                                <option value="approval">approval</option>
                                                <option value="button">button</option>
                                                <option value="cardProps">cardProps</option>
                                                <option value="calendar">calendar</option>
                                                <option value="comment">comment</option>
                                                <option value="date">date</option>
                                                <option value="dept">dept</option>
                                                <option value="deptMulti">deptMulti</option>
                                                <option value="editor">editor</option>
                                                <option value="email">email</option>
                                                <option value="file">file</option>
                                                <option value="grid">grid</option>
                                                <option value="hidden">hidden</option>
                                                <option value="includePage">includePage</option>
                                                <option value="number">number</option>
                                                <option value="password">password</option>
                                                <option value="radio">radio</option>
                                                <option value="selectSingle">selectSingle</option>
                                                <option value="selectMulti">SelectMulti</option>
                                                <option value="taskGantt">taskGantt</option>
                                                <option value="text">text</option>
                                                <option value="textarea">textarea</option>
                                                <option value="user">user</option>
                                            </select>

                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={handleAddField}
                                            >
                                                Add
                                            </button>
                                        </div>
                                    )}

                                    <div className="offset-sm-10 col-sm-1">
                                        <button
                                            type="submit"
                                            onClick={saveHandleSubmit}
                                            className="btn btn-danger"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </FullScreen>

                        </div>
                    </div>

                    <div className="card col-sm-6">
                        <div className="card-header">
                            <h3 className="card-title">View</h3>
                            <div className="card-tools">
                                <button
                                    type="button"
                                    className="btn btn-tool"
                                    title="Expand screen"
                                    onClick={screen20.enter}
                                >
                                    <FontAwesomeIcon icon={faExpandArrowsAlt}/>
                                </button>
                            </div>
                        </div>


                        <div className="card-body">
                            <FullScreen handle={screen20}>
                                {screen20.active && (
                                    <button type="button" onClick={screen20.exit}>
                                        <FontAwesomeIcon icon={faArrowsAlt}/>
                                    </button>
                                )}

                                {compoValues.map((obj, index) => (
                                    <VisualForm
                                        key={index}
                                        ltv={obj}
                                        compoValues={compoValues}
                                        // selectedItem={selectedItem[obj.label]}
                                        // clickedCpt={formValues.filter((fv) => fv.label === selectedItem)}
                                        handleChange={onChangeCompo}
                                        changedValues={values}
                                        index={index}
                                        deleteField={handleDeleteField}
                                        dbValue={values[obj.label]}  //header_object, col_array...
                                    />
                                ))}

                            </FullScreen>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
export default Cmpc200i;

export function getMultiSelectUrl() {
}


export function gw(m: any) {

}

function customRowNumber() {

}


function setAppvOpt(elem) {

}