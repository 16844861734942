import {useFormContext} from 'react-hook-form';
import i18n from '@app/utils/i18n';
import {useEffect, useRef, useState} from 'react';
import {ErrorMessage} from '../ErrorMessage';
import {CustomInputProps} from './types';

export const CustomTextarea = ({
                                   seq,
                                   type,
                                   label,
                                   dbValue,

                                   ...props
                               }: CustomInputProps) => {
    const {
        register,
        formState: {errors}
    } = useFormContext();

    console.log("CustomTextarea_props=====", props);

    const [initFocus, setInitFocus] = useState(false);
    const id = `${label}`;
    const {ref} = register(id);
    const error = errors[label]?.message as string | undefined;
    const rows = props.value.rows !== undefined ? props.value.rows : '4';

    let fl2 = i18f(`views.${props.p.prog_id.toLowerCase()}.${label}`);
    let fl = i18f(`views.common.${label}`);
    if (fl2 !== `views.${props.p.prog_id.toLowerCase()}.${label}`) fl = fl2;
    let is_required = false;

    try{
        if (props.validationSchema !== null && props.validationSchema.fields !==null && eval(`props.validationSchema.fields.${label}`) !== undefined) {
            is_required = eval(`props.validationSchema.fields.${label}`).exclusiveTests.required;
            // console.log(`${label}_validationschema___`,is_required);
        }
    }catch(e){
    }

    useEffect(() => {
        if(dbValue !== undefined) {
            ref.current.value = dbValue || '';
            // if(!initFocus) ref.current.focus();
            // console.log('dbValue_is_set______________',dbValue);
        }
    });
    useEffect(() => {
        if(!initFocus && dbValue !== undefined) {
            ref.current.value = dbValue || '';
            // console.log('______intiFocus_is_set______________');
            setInitFocus(true);
        }
    }, [initFocus]);

    let lastUpdate = 0 ;
    return (
        <div className="form-group">
            <div className="input-group">
                {label && (
                    <label className="col-sm-3 col-form-label text-center" htmlFor={id}>
                        {fl}
                        {is_required && (
                            <span className="ml-1" style={{color: 'teal'}}>
                              *
                            </span>
                    )}
                    </label>
                )}
                <div className="col-sm-9 conainter-fluid">
                    <div>
            <textarea
                className="form-control"
                {...register(label, {

                    onChange: (e) => {
                        lastUpdate = Date.now();
                        const timer = setTimeout(function () {
                            const diff = Date.now() - lastUpdate;
                            if (diff > 2000) {
                                props.handleChange(e);
                            }
                        }, 2000);
                    }
                    ,onBlur: (e) => {
                        props.handleChange(e);
                    }

                    // , value: {dbValue}
                })}
                id={label}
                // key={dbValue}
                defaultValue={dbValue || ''}
                readOnly={props.readonly}
                rows={rows}

                ref={(e) => {
                    ref(e);
                    ref.current = e; // you can still assign to ref
                }}
            />
                        <ErrorMessage error={error}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

function i18f(m: string) {
    const {t} = i18n;
    return t(m);
}
