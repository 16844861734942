import React, {useEffect, useRef, useState} from 'react';

import {PfButton} from '@profabric/react-components';
import i18n from '@app/utils/i18n';
import StockChipGroup from '@app/pages/common/addStock/stockChipGroup/StockChipGroup';
import MyDropzone from '@app/pages/common/dropzone/MyDropzone';
import JqGrid from '../../common/jqgrid/JqGrid';

export default function Input({objValue, onChange, index, deleteField, ...props}) {

    const textareaStyle = {
        boder: '1px solid black',
        resize: 'vertical',
        minHeight: '100px'
    };

    const autoHeight = (event) => {
        // setValue(event.target.value);
        event.target.style.height = 'auto'; // Reset the height to recalculate the actual scrollHeight
        // event.target.style.height = `${event.target.scrollHeight+10}px`; // Set the height based on the scrollHeight
        const sh = JSON.parse(`${event.target.scrollHeight + 10}`);
        if (sh > 600)
            event.target.style.height = `600px`; // Set the height based on the scrollHeight
        else event.target.style.height = `${event.target.scrollHeight + 10}px`;
    };


    const {label, type, value} = objValue;

    console.log('Input====', objValue, "label=",label, "value=",value, " props====",props);

    const getProps = (label: any, type: any, value: any) => ({
        button: (
            <textarea
                key={label}
                className="form-control"
                // type="textarea"
                id={label}

                defaultValue={value || ''}
                placeholder="value"
                onFocus={(e) => {
                    autoHeight(e);
                }}
                onBlur={(e) => {
                    onChange(e, index);
                    autoHeight(e);
                }}
            />
        ),
        editor: (
            <textarea
                key={label}
                className="form-control"
                // type="textarea"
                id={label}

                defaultValue={value || ''}
                placeholder="value"
                onFocus={(e) => {
                    autoHeight(e);
                }}
                onBlur={(e) => {
                    onChange(e, index);
                    autoHeight(e);
                }}
            />
        ),
        approval: (
            <textarea
                key={label}
                className="form-control"
                id={label}
                placeholder="value"
                onFocus={(e) => {
                    autoHeight(e);
                }}
                onBlur={(e) => {
                    onChange(e, index);
                    autoHeight(e);
                }} defaultValue={value || ''}
            />
        ),
        user: (
            <textarea
                key={label}
                className="form-control"
                // type="textarea"
                id={label}
                defaultValue={value || ''}
                placeholder="value"
                onFocus={(e) => {
                    autoHeight(e);
                }}
                onBlur={(e) => {
                    onChange(e, index);
                    autoHeight(e);
                }}/>
        ),
        text: (
            <textarea
                key={label}
                className="form-control"
                // type="textarea"
                id={label}
                defaultValue={value || ''}
                placeholder="value"
                onFocus={(e) => {
                    autoHeight(e);
                }}
                onBlur={(e) => {
                    onChange(e, index);
                    autoHeight(e);
                }}/>
        ),
        hidden: (
            <input
                key={label}
                className="form-control"
                type="text"
                id={label}
                defaultValue={value || ''}
                placeholder="value"
                onFocus={(e) => {
                    autoHeight(e);
                }}
                onBlur={(e) => {
                    onChange(e, index);
                    autoHeight(e);
                }}/>
        ),
        textarea: (
            <textarea
                key={label}
                // style={textareaStyle}
                className="form-control"
                // type="textarea"
                id={label}
                defaultValue={value || ''}
                placeholder="value"
                onFocus={(e) => {
                    autoHeight(e);
                }}
                onBlur={(e) => {
                    onChange(e, index);
                    autoHeight(e);
                }}/>
        ),
        number: (
            <textarea
                key={label}
                className="form-control"
                // type="textarea"
                id={label}
                defaultValue={value || ''}
                placeholder="value"
                onFocus={(e) => {
                    autoHeight(e);
                }}
                onBlur={(e) => {
                    onChange(e, index);
                    autoHeight(e);
                }}/>
        ),
        date: (
            <textarea
                key={label}
                className="form-control"
                // type="textarea"
                id={label}
                defaultValue={value || ''}
                placeholder="value"
                onFocus={(e) => {
                    autoHeight(e);
                }}
                onBlur={(e) => {
                    onChange(e, index);
                    autoHeight(e);
                }}/>
        ),
        selectSingle: (
            <textarea
                key={label}
                className="form-control"
                // type="textarea"
                id={label}
                defaultValue={value || ''}
                placeholder="value"
                onFocus={(e) => {
                    autoHeight(e);
                }}
                onBlur={(e) => {
                    onChange(e, index);
                    autoHeight(e);
                }}/>
        ),
        selectMulti: (
            <textarea
                key={label}
                className="form-control"
                // type="textarea"
                id={label}
                defaultValue={value || ''}
                placeholder="value"
                onFocus={(e) => {
                    autoHeight(e);
                }}
                onBlur={(e) => {
                    onChange(e, index);
                    autoHeight(e);
                }}/>
        ),
        grid: (
            <textarea
                key={label}
                // style={textareaStyle}
                className="form-control"
                // type="textarea"
                id={label}
                defaultValue={value || ''}
                placeholder="value"
                onFocus={(e) => {
                    autoHeight(e);
                }}
                onBlur={(e) => {
                    onChange(e, index);
                    autoHeight(e);
                }}/>
        ),
        deptMulti: (
            <textarea
                key={label}
                // style={textareaStyle}
                className="form-control"
                // type="textarea"
                id={label}
                defaultValue={value || ''}
                placeholder="value"
                onFocus={(e) => {
                    autoHeight(e);
                }}
                onBlur={(e) => {
                    onChange(e, index);
                    autoHeight(e);
                }}/>
        ),
        comment: (
            <textarea
                key={label}
                className="form-control"
                // type="text"
                id={label}
                defaultValue={value || ''}
                placeholder="value"
                onFocus={(e) => {
                    autoHeight(e);
                }}
                onBlur={(e) => {
                    onChange(e, index);
                    autoHeight(e);
                }}/>
        ),
        taskGantt: (
            <textarea
                key={label}
                className="form-control"
                // type="text"
                id={label}
                defaultValue={value || ''}
                placeholder="value"
                onFocus={(e) => {
                    autoHeight(e);
                }}
                onBlur={(e) => {
                    onChange(e, index);
                    autoHeight(e);
                }}/>
        ),
        file: (
            <textarea
                key={label}
                className="form-control"
                // type="textarea"
                id={label}
                defaultValue={value || ''}
                placeholder="value"
                onFocus={(e) => {
                    autoHeight(e);
                }}
                onBlur={(e) => {
                    onChange(e, index);
                    autoHeight(e);
                }}/>
        ),
        includePage: (
            <textarea
                key={label}
                className="form-control"
                id={label}
                defaultValue={value || ''}
                placeholder="value"
                onFocus={(e) => {
                    autoHeight(e);
                }}
                onBlur={(e) => {
                    onChange(e, index);
                    autoHeight(e);
                }}/>
        ),
        calendar: (
            <textarea
                key={label}
                className="form-control"
                id={label}
                defaultValue={value || ''}
                placeholder="value"
                onFocus={(e) => {
                    autoHeight(e);
                }}
                onBlur={(e) => {
                    onChange(e, index);
                    autoHeight(e);
                }}/>
        ),
        radio: (
            <textarea
                key={label}
                className="form-control"
                id={label}
                defaultValue={value || ''}
                placeholder="value"
                onFocus={(e) => {
                    autoHeight(e);
                }}
                onBlur={(e) => {
                    onChange(e, index);
                    autoHeight(e);
                }}/>
        )
        , cardProps: (
            <textarea
                key={label}
                className="form-control"
                id={label}
                defaultValue={value || ''}
                placeholder="value"
                onFocus={(e) => {
                    autoHeight(e);
                }}
                onBlur={(e) => {
                    onChange(e, index);
                    autoHeight(e);
                }}/>
        )
        , default:
            <textarea
                key={label}
                className="form-control"
                id={label}
                defaultValue={value || ''}
                placeholder="value"
                onFocus={(e) => {
                    autoHeight(e);
                }}
                onBlur={(e) => {
                    onChange(e, index);
                    autoHeight(e);
                }}/>
    });

    return (
        <div
            className="form-group"
            draggable={props.draggable}
            onDragStart={props.onDragStart}
            onDragEnd={props.onDragEnd}
            onDragEnter={props.onDragEnter}
            onDragOver={props.onDragOver}

            style={{backgroundColor: 'lightblue'}}
        >
            <div className="input-group"
                 // onClick={(e) => props.handleClick(type)}
            >
                <label htmlFor={label} className="col-sm-2 col-form-label">
                    {label} <br/><span style={{fontSize: 9}}>({type})</span>
                </label>
                {
                    // !props.hidden &&
                    <div className="col-sm-9">{getProps(label, type, value)[type]}</div>
                }
                <div
                    className={`input-group-append ${props.hidden ? 'col-md-9' : ''}`}
                    style={{'justifyContent': 'flex-end'}}
                >

                          <span
                              onClick={(e) => deleteField(e, index)}
                              className="input-group-text"
                          >
                            X
                          </span>

                </div>
            </div>
        </div>
    );
}
