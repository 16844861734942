import React, {useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import {uploadToServer} from '@app/utils/APIUtils';
import ShowImage from './ShowImage';
import DropBox from './DropBox';

// export default function MyDropzone() {
const MyDropzone = (prop) => {
  const [images, setImages] = useState([]);
  const user = useSelector((state: any) => state.auth.currentUser);

  const onDrop = useCallback((acceptedFiles: any[]) => {
    console.log('________MyDropzone_onDrop________________', acceptedFiles);

    // acceptedFiles.map((file, index) => {
    //   const reader = new FileReader();
    //   reader.onload = function (e) {
    //     setImages((prevState) => [
    //       ...prevState,
    //       {id: index, src: e.target.result}
    //     ]);
    //   };
    //   reader.readAsDataURL(file);
    //   return file;
    // });

    // const formData = new FormData();
    // const fileObjects = acceptedFiles.map((file, i) => {
    //   formData.append(`file-${i}`, file, file.name);
    // });
    // formData.append('corpCd', user.corp_cd);
    // formData.append('userId', user.user_id);
    // formData.append('ajaxuploadpath', 'common');
    // try {
    //   uploadToServer(formData)
    //     .then((res) => {
    //       prop.handleChange({
    //         id: 'fileInfo',
    //         value: res
    //       });
    //     })
    //     .catch((err) => console.log('error=', err));
    // } catch (e) {
    //   console.log('uploadfile_error=', e);
    // }
  }, []);

  // const uploadFiles = () => {
  //   const formData = new FormData();
  //   const fileObjects = acceptedFiles.map((file, i) => {
  //     formData.append(`file-${i}`, file, file.name);
  //   });
  //   formData.append('corpCd', user.corp_cd);
  //   formData.append('userId', user.user_id);
  //   formData.append('ajaxuploadpath', 'common');
  //   try {
  //     uploadToServer(formData)
  //       .then((res) => {
  //         handleChange({
  //           id: 'fileInfo',
  //           value: res
  //         });
  //       })
  //       .catch((err) => console.log('error=', err));
  //   } catch (e) {
  //     console.log('uploadfile_error=', e);
  //   }
  // };
  return (
    <div>
      <DropBox
        onDrop={onDrop}
        id={prop.id}
        handleChange={prop.handleChange}
        defaultValue={prop.defaultValue}
      />
      {/* <ShowImage images={images} /> */}
    </div>
  );
};

export default MyDropzone;
