import {useFormContext} from 'react-hook-form';
import i18n from '@app/utils/i18n';
import React, {useEffect, useRef, useState} from 'react';
import {ErrorMessage} from '../ErrorMessage';
import {CustomInputProps} from './types';
// import '../../../../public/common';

export const CustomSelectDept = ({
  type,
  label,
  // handleChange
  dbValue,

  ...props
}: CustomInputProps) => {
  const {
    register,
    formState: {errors}
  } = useFormContext();
  console.log('CustomSelectDept_props=', props);
  const error = errors[label]?.message as string | undefined;

  // const id = `${name}-${props.type}-${label}`;
  const id = `${label}`;

  const {ref} = register(id);

  const fl = i18f(`views.common.${label}`);

  let defaultValue = '';

  if (props.defaultValue !== undefined && props.defaultValue !== '') {
    defaultValue = JSON.parse(props.defaultValue);

    // console.log('SelectUser_defaultValue====', defaultValue);
  }

  const [initValue, setInitValue] = useState(defaultValue);
  const userChange = (e: any, index: number) => {
    // console.log('e.target====', e.target);
    // console.log('props.userInfo==============', props.userInfo);

    rpms.getDept(e.target, JSON.parse(props.deptInfo), setUserInfoFunc);
  };

  function setUserInfoFunc(e) {
    // console.log('e=', e);
    let up = {
      id: props.id,
      value: {user_id: e.user_id, record_nm: e.record_nm}
    };

    props.handleChange(up);
    setInitValue({user_id: e.user_id, record_nm: e.record_nm});

    ref.current.value = e.record_nm;
  }

  return (
    <div className="form-group">
      <div className="input-group">
        {label && (
          <label className="col-sm-3 col-form-label" htmlFor={id}>
            {fl}
          </label>
        )}
        <div className="col-sm-9 conainter-fluid">
          <div>
            <input
              className="form-control"
              {...register(label, {
                onChange: (e) => {
                  userChange(e);
                }
              })}
              name={label}
              ref={(e) => {
                ref(e);
                ref.current = e; // you can still assign to ref
              }}

              readonly={props.readonly}
              defaultValue={initValue ? initValue.record_nm : defaultValue.name}
            />

            <ErrorMessage error={error} />
          </div>
        </div>
      </div>
      {/*<hr className="bg-gray-400 my-4"/>*/}
    </div>
  );
};

function i18f(m: string) {
  const {t} = i18n;
  return t(m);
}
