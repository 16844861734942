import React, {useEffect, useState} from 'react';

// import axios from 'axios';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import i18n from '@app/utils/i18n';
import axios from 'axios';
import {CustomInputProps} from '@pages/common/inputs/types';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

// import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
// import {useFormContext} from "react-hook-form";
// import {ErrorMessage} from "@pages/common/ErrorMessage";

const Editor = ({label, handleChange, ...props}: CustomInputProps) => {
  // (1)
  /*
    const {
        register,
        formState: {errors}
    } = useFormContext<any>();


    const {ref} = register(label);
    */
  const user = useSelector((state: any) => state.auth.currentUser);
  const {state} = useLocation();

  // const attId = props.id;
  console.log('Editor_props############################', props);
  let defaultValue = props.defaultValue === undefined ? '' : props.defaultValue;

  const [editValue, setEditValue] = useState(defaultValue);
  // let error = errors[label]?.message as string | undefined;

  // console.log("defaultValue===================================",defaultValue);
  useEffect(() => {
    console.log('editValue====================================', editValue);
    // const obj = {id: label, value:editValue};
    label === undefined ? '' : label;
    handleChange({id: label, value: editValue});
  }, [editValue]);

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message.indexOf('ResizeObserver') > -1) {
        console.log('____________ResizeObserver______________', e.message);
        e.stopImmediatePropagation();
        // prevent the browser's console error message
        e.preventDefault();
      }
    });
  }, []);

  const [flag, setFlag] = useState(false);
  // const imgLink = "http://localhost:3000"
  const imgLink = '';

  const id = `${label}`;

  const tmplabel =
    props.ltvp !== undefined ? props.ltvp.p.prog_id.toLowerCase() : '';
  const fl2 = i18f(`views.${tmplabel}.${label}`);
  let fl = i18f(`views.common.${label}`);
  fl = fl2.indexOf(label) > -1 ? fl : fl2;

  // console.log('fl2.indexOf(label)=======', fl2.indexOf(label));

  const customUploadAdapter = (loader) => {
    // (2)
    return {
      upload() {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          loader.file.then((file) => {
            formData.append('name', file.name);
            formData.append('file', file);

            formData.append('corp_cd', user.corp_cd);
            formData.append('user_id', user.user_id);
            formData.append('upload_path', state.name);

            axios
              .post('/file/uploadEditorImage', formData)
              .then((res) => {
                if (!flag) {
                  console.log('editor_uploadfile_res=======', res);
                  setFlag(true);
                  // setImage(res.data.filename);
                }
                // const img_path= `${imgLink}/upload/${res.data[0].server_path}/${res.data[0].server_file_name}`;
                const img_path = `/upload/${res.data[0].server_path}/${res.data[0].server_file_name}`;
                console.log('img_path====', img_path);
                resolve({
                  default: img_path
                });
              })
              .catch((err) => reject(err));
          });
        });
      }
    };
  };

  function uploadPlugin(editor) {
    // (3)
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return customUploadAdapter(loader);
    };
  }

  let lastUpdate;

  return (
    <div className="form-group">
      <div className="input-group">
        {label && (
          <label className="col-sm-3 col-form-label text-center" htmlFor={id}>
            {fl}
          </label>
        )}
        <div className="col-sm-9 conainter-fluid">
          <div>
            <CKEditor
              editor={ClassicEditor}
              config={{
                extraPlugins: [uploadPlugin]
              }}
              data={defaultValue}
              /*
                                                    {...register(label, {
                                                        onChange: (e) => {
                                                            handleChange(e);
                                                        }
                                                    })}
                                                    */

              onChange={(e, editor) => {
                const data = editor.getData();
                // const obj = {id: label, value:data};
                console.log('Editor_onChange==', data);

                if (defaultValue !== undefined && data !== undefined) {
                  // handleChange(obj)
                  setEditValue(data);
                }
              }}
              /*
                            ref={(e) => {
                                ref(e);
                                ref.current = e;
                            }}
*/
              onReady={(editor) => {
                // editor.ui.view.editable.element.style.minHeight = "400px";
                editor.editing.view.change((writer) => {
                  writer.setStyle(
                    'height',
                    '200px',
                    editor.editing.view.document.getRoot()
                  );
                });
                console.log('Editor_is_ready==', editor);
              }}
              onBlur={(event, editor) => {
                // console.log('Editor_Blur==', editor);
                //  handleChange({id: label, value:editor.getData()});
              }}
              onFocus={(event, editor) => {
                // console.log('Editor_onFocus==', editor);
              }}
            />
            {/*
                        <ErrorMessage error={error} />
                        */}
          </div>
        </div>
      </div>
      {/*<hr className="bg-gray-400 my-4"/>*/}
    </div>
  );
};

export default Editor;

function i18f(m: string) {
  const {t} = i18n;
  return t(m);
}
