import React, {useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import {uploadToServer} from '@app/utils/APIUtils';
import {useFormContext} from 'react-hook-form';
import ShowImage from './ShowImage';
import DropBoxForm from './DropBoxForm';
import {CustomInputProps} from '../inputs/types';

// export default function MyDropzone() {
const MyDropzoneForm = ({
  // type,
  // label, 
  // dbValue,

  ...props
}: CustomInputProps) => {
  const {
    register,
    formState: {errors}
  } = useFormContext();

  // console.log('MyDropzoneForm#################', props);
  const [images, setImages] = useState([]);
  // const user = useSelector((state: any) => state.auth.currentUser);

  const [defaultValue, setDefaultValue] = useState([]);



  const isDarkMode = useSelector((state: any) => state.ui.darkMode);
  // console.log('__________________isDarkMode__________________', isDarkMode);
  // const darkMode = useSelector((state: any) => state.ui.darkMode);
  const onDrop = useCallback((acceptedFiles: any[]) => {
    //  console.log('________MyDropzoneForm_onDrop________________', acceptedFiles);
     
  // const constDefaultValue           = useCallback(() => setDefaultValue(onDrop),    [onDrop]  ); 
    
  }, []);

    // console.log('________MyDropzoneForm_onDrop2________________',onDrop, 'defaultValue====',defaultValue);
   

  return (
    <div>
      <DropBoxForm
        onDrop={onDrop}
        isDarkMode={isDarkMode}
        label={props.label}
        id={props.id}
        handleChange={props.handleChange}
        defaultValue={props.defaultValue}
        dbValue={props.dbValue}

        readonly={props.readonly}
      //   isDragActive={false}
      //   isDragAccept={false}
      // isFocused={false}
      // isDragReject={false}

      />
      {/* <ShowImage images={images} /> */}
    </div>
  );
};

export default MyDropzoneForm;
