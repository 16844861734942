import React, {useEffect, useRef, useState} from 'react';

import {useSelector} from 'react-redux';
import {useDropzone} from 'react-dropzone';
import styled from 'styled-components';
// import './index.css';
import './dropzone.css';

import {toast} from 'react-toastify';
import {API_BASE_URL} from '@app/constants';
import {useLocation} from 'react-router-dom';
import {uploadToServer} from '../../../utils/APIUtils';

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return '#eeeeee';
};
const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-width: 2px;
  border-radius: 10px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: black;
  font-weight: bold;
  font-size: 1.4rem;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

// async function myCustomFileGetter(event) {
//   console.log(
//     'event.dataTransfer.files==',
//     event.dataTransfer.files,
//     ' event.target.files==',
//     event.target.files
//   );
//   const files = [];
//   const fileList = event.dataTransfer
//     ? event.dataTransfer.files
//     : event.target.files;

//   for (let i = 0; i < fileList.length; i++) {
//     const file = fileList.item(i);

//     Object.defineProperty(file, 'myProp', {
//       value: true
//     });
//     files.push(file);
//   }

//   console.log('myCustomFileGetter_files==', files);
//   return files;
// }

// function DropBox({onDrop, handleChange}) {

function downloadFile(fileId: string) {
  // alert('fileId=========' + fileId);
  const url = `${API_BASE_URL}/file/get/downloadFile?svr_file_id=${fileId}`;
  const download = document.createElement('a');

  download.href = url;
  download.setAttribute('download', fileId);
  download.setAttribute('type', 'application/json');
  download.click();
}

function DropBox(props) {
  const {onDrop, handleChange} = props;
  const attId = props.id;
  const [files, setFiles] = useState({});

  const {state} = useLocation();
  // console.log('dropbox_state==', state, 'attId==', attId);
  console.log('dropbox_props==', props);
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    open,
    isDragAccept,
    isFocused,
    isDragReject
  } = useDropzone({
    // accept: 'image/*',
    accept: '*',
    onDrop,
    noClick: true,
    noKeyboard: true,
    maxSize: 10 * 1024 * 1024 //10 MBs
    // getFilesFromEvent: (event) => myCustomFileGetter(event)
    // initialFiles: eval(props.defaultValue)
  });

  const user = useSelector((state: any) => state.auth.currentUser);
  let data = eval(props.defaultValue) || [];
  // console.log('file_data=========', data);
  let f2 = [];
  f2 = data.map((key, i) => ({
    name: data[i].local_file_name,
    size: data[i].file_size,
    type: data[i].file_extension,
    fileGubunCd: data[i].file_gubun_cd
  }));

  const [selectedFiles, setSelectedFiles] = useState(f2);
  const [errorMessage, setErrorMessage] = useState('');
  const [validFiles, setValidFiles] = useState([]);
  const [unsupportedFiles, setUnsupportedFiles] = useState([]);
  const [skipCount, setSkipCount] = useState(true);

  useEffect(() => {
    if (skipCount) setSkipCount(false);
    if (!skipCount) {
      console.log('__________________Before_uploadFiles_________________');
      uploadFiles();
    }
  }, [selectedFiles]);

  // console.log('_____________selectedFiles____________', selectedFiles);
  const dragOver = (e) => {
    // console.log('______________dragOver_________________');
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    // console.log('______________dragLeave_________________');
    e.preventDefault();
  };

  const fileDrop = (e) => {
    // console.log('fileDrop________________', e);
    e.preventDefault();
    const {files} = e.dataTransfer;

    // console.log('fileDrop_files_______________', files);
    if (files.length) {
      if (handleFiles(files)) {
        console.log('selectedfiles_______________', selectedFiles);
        // uploadFiles();
      }

      // console.log('setSelectedFile111_________', selectedFiles);
    }

    // console.log('fileDrop_setFiles______________', files);
  };

  const handleFiles = (files) => {
    // console.log('handleFiles=', files, 'files_0_name=', files[0].name);
    try {
      for (let i = 0; i < files.length; i++) {
        if (validateFile(files)) {
          // console.log('________validateFile[0]name__________', files[i].name);
          const tempFile = [
            {
              name: files[i].name,
              size: files[i].size,
              type: files[i].type
            }
          ];

          // console.log('tempFile__________', tempFile);
          setSelectedFiles((prevArray) =>
            // console.log('prevArray=', prevArray, 'tempFile=', tempFile);
            [...prevArray, ...tempFile]
          );
          // console.log('setSelectedFiles000_________', selectedFiles);
        } else {
          // console.log('________setUnsupportedFiles__________', files[i]);
          setUnsupportedFiles((prevArray) => [...prevArray, files[i]]);
        }
      }
    } catch (e) {
      toast.error('Error in uploading files...');
      return false;
    }
    return true;
  };

  const validateFile = (file) => {
    // const validTypes = [
    //   'image/jpeg',
    //   'image/jpg',
    //   'image/png',
    //   'image/gif',
    //   'image/x-icon'
    // ];
    // if (validTypes.indexOf(file.type) === -1) {
    //   return false;
    // }
    return true;
  };

  const fileSize = (size) => {
    if (size === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return `${parseFloat((size / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
  };

  const fileType = (fileName) => {
    return (
      fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) ||
      fileName
    );
  };

  const removeFile = (name) => {
    const selectedFileIndex = selectedFiles.findIndex((e) => e.name === name);
    selectedFiles.splice(selectedFileIndex, 1);
    setSelectedFiles([...selectedFiles]);

    acceptedFiles.splice(selectedFileIndex, 1);
  };
  const [imageSent, setImageSent] = useState([]);

  const uploadFiles = () => {
    const formData = new FormData();
    selectedFiles.map((file, i) => {
      if (file === undefined) {
        return;
      }
      // console.log(`data[${i}].name====`, file.name, 'file0name=', file.name);
      const variables = [
        // {
        //   file,
        //   name: file.name
        // }
      ];
      formData.append(
        `file-${i}`,
        new Blob([JSON.stringify(variables)], {type: 'application/json'}),
        file.name
      );
      // formData.append(`file-${i}`, file, file.name);
    });
    formData.append('corpCd', user.corp_cd);
    formData.append('userId', user.user_id);
    formData.append('upload_path', state.name);

    if (state.name === 'PJTRegistration') {
      formData.append('foreignKey', state.params.proj_cd);
    }

    try {
      uploadToServer(formData)
        .then((res) => {
          handleChange({
            id: attId,
            value: res
          });
        })
        .catch((err) => console.log('error=', err));
    } catch (e) {
      console.log('uploadfile_error=', e);
    }
  };

  let droppedFiles = selectedFiles.map((data, i) => {
    console.log('data[' + i + ']=====', data);
    if (data === undefined) {
      return;
    }
    return (
      <div className="file-status-bar" key={`d${i}`}>
        <div>
          <div className="file-type-logo" />
          <span className="file-name">{data.name}</span>
          <span className="file-size">({fileSize(data.size)})</span>
          <div className="file-remove" onClick={() => removeFile(data.name)}>
            X
          </div>
        </div>
      </div>
    );
  });

  // let droppedFiles = acceptedFiles.map((data, i) => {
  //   return (
  //     // (data = props.defaultValue ? props.defaultValue : data, i) => (
  //     <div className="file-status-bar" key={`d${i}`}>
  //       <div>
  //         <div className="file-type-logo" />
  //         <span className={`file-name ${data.invalid ? 'file-error' : ''}`}>
  //           {data.name}
  //         </span>
  //         <span className="file-size">({fileSize(data.size)})</span>{' '}
  //         {data.invalid && (
  //           <span className="file-error-message">({errorMessage})</span>
  //         )}
  //         <div className="file-remove" onClick={() => removeFile(data.name)}>
  //           X
  //         </div>
  //       </div>
  //     </div>
  //   );
  // });

  // let data = eval(props.defaultValue);
  // let initialFiles = Object.keys(data).map((key, i) => {
  //   return (
  //     <div className="file-status-bar" key={`i${i}`}>
  //       <div>
  //         <div className="file-type-logo" />
  //         <span className="file-name">
  //           <a
  //             href="#"
  //             onClick={() => downloadFile(data[i].file_id)}
  //             target="_blank"
  //           >
  //             {data[i].local_file_name}
  //           </a>
  //         </span>
  //         <span className="file-size">({fileSize(data[i].file_size)})</span>
  //         <div
  //           className="file-remove"
  //           onClick={() => removeFile(data[i].local_file_name)}
  //         >
  //           X
  //         </div>
  //       </div>
  //     </div>
  //   );
  // });

  return (
    <>
      {' '}
      <section
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
      >
        <Container
          className="dropbox"
          {...getRootProps({isDragAccept, isFocused, isDragReject})}
          // onClick={open}
        >
          <input
            {...getInputProps({
              // onChange: handleChange
            })}
          />
          {/* <DropzoneText files={files} /> */}
          Drag & drop files
          {/* <div className="upload-btn" onClick={() => uploadFiles()}>
            Upload Click
          </div> */}
        </Container>
      </section>
      <aside>
        <div className="file-display-container">
          {droppedFiles}
          {/* {[...droppedFiles, ...initialFiles]} */}
        </div>
      </aside>
    </>
  );
}
export default DropBox;
