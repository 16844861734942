import {useFormContext} from 'react-hook-form';
import i18n from '@app/utils/i18n';
import {useEffect, useRef, useState} from 'react';
import {ErrorMessage} from '../ErrorMessage';
import {CustomInputProps} from './types';

export const CustomDateInput = ({
seq,
type,
  label,
   defaultValue,
  ...props
}: CustomInputProps) => {
  const {
    register,
    formState: {errors}
  } = useFormContext();

  const id = `${label}`;
  const {ref} = register(id);
  const error = errors[label]?.message as string | undefined;
  const fl = i18f(`views.common.${label}`);

  // console.log(  "dbValue===",defaultValue);
  if(defaultValue !== undefined)  defaultValue = defaultValue.substring(0, 10);

  // const [formDate, setFormDate] = useState(sdbValue);
  // console.log("seq=====",seq, "dbValue===",defaultValue);

  useEffect( () => { 
  ref.current.value = defaultValue||'';
  
});

  return (
    <div className="form-group">
      <div className="input-group">
        {label && (
          <label className="col-sm-3 col-form-label text-center" htmlFor={id}>
            {fl}
          </label>
        )}
        <div className="col-sm-9 conainter-fluid">
          <div>
            <input
              type="date"
              className="form-control "
              {...register(label, {
                onChange: (e) => {
                  props.handleChange(e);
                }
              })}
              key={seq}
              defaultValue={defaultValue || ''}
              id={label}
              readOnly={props.readonly}
              ref={(e) => {
                ref(e);
                ref.current = e; // you can still assign to ref
              }}
            />

            <ErrorMessage error={error} />
          </div>
        </div>{' '}
      </div>
      {/*<hr className="bg-gray-400 my-4"/>*/}
    </div>
  );
};

function i18f(m: string) {
  const {t} = i18n;
  return t(m);
}
