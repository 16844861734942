import {useFormContext} from 'react-hook-form';
import i18n from '@app/utils/i18n';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {ErrorMessage} from '../../common/ErrorMessage';
import {CustomInputProps} from './types';
import {randomUUID} from 'crypto';
import {CommentsForm} from "@pages/common/comment/CommentsForm";

export const CustomInput = ({

// export default React.memo(CustomInput)({
                                seq,
                                type,
                                label,
                                dbValue,
                                ...props
                            }: CustomInputProps) => {
    const {
        register,
        formState: {errors}
    } = useFormContext<any>();

    const id = `${label}`;
    const {ref} = register(id);

    console.log('CustomGenericInput_props############################', dbValue, props);
    console.log('formState===============================', errors);

    const [initFocus, setInitFocus] = useState(false);
    let error = errors[label]?.message as string | undefined;
    const [initValue, setInitValue] = useState(dbValue);

    let fl2 = i18f(`views.${props.p.prog_id.toLowerCase()}.${label}`);
    let fl = i18f(`views.common.${label}`);
    if (fl2 !== `views.${props.p.prog_id.toLowerCase()}.${label}`) fl = fl2;
    let is_required = false;

    try {
        if (props.validationSchema !== null && props.validationSchema.fields !== null && eval(`props.validationSchema.fields.${label}`) !== undefined) {
            is_required = eval(`props.validationSchema.fields.${label}`).exclusiveTests.required;
        }
    } catch (e) {
        console.log(`${label}_no_validationschema___`)
    }

/*
    useEffect(() => {
        if(dbValue !== undefined) {
            ref.current.focus();
            if(!initFocus) ref.current.value = dbValue || '';

            console.log('dbValue_is_set______________',dbValue);
        }
    });

    useEffect(() => {
        if(!initFocus && dbValue !== undefined) {
            console.log('______intiFocus_is_set______________');
            setInitFocus(true);
        }
    }, [initFocus]);
*/

    useEffect(() => {
        // register(id);
        console.log('initFocus___________',initFocus);
        if(!initFocus && dbValue !== undefined) {
            ref.current.focus();
            console.log('______intiFocus_is_set______________');
            setInitFocus(true);
        }
    });

    return (
        <div className="form-group">
            <div className="input-group">
                {label && (
                    <label className="col-sm-3 col-form-label text-center" htmlFor={id}>
                        {fl}
                        {is_required && (
                            <span className="ml-1" style={{color: 'teal'}}>
                              *
                            </span>
                        )}
                    </label>
                )}

                <div className="col-sm-9">
                    <div>
                        <input
                            // type="text"
                            autoFocus
                            className="form-control "
                            {...register(label, {
                                onChange: (e) => {
                                    props.handleChange(e);
                                }
                                ,
                                onFocus: (e) => {
                                    props.handleChange(e);
                                }
                                , value: {dbValue}
                            })}
                            readOnly={props.readonly}
                            id={label}
                            // label_ko={fl}
                            key={seq}
                            //   key={crypto.randomUUID()}
                            ref={(e) => {
                                ref(e);
                                ref.current = e;
                            }}
                            defaultValue={dbValue || ''}
                        />
                        <ErrorMessage error={error}/>
                    </div>
                </div>
            </div>
            {/*<hr className="bg-gray-400 my-4"/>*/}
        </div>
    );
};

// export default CustomInput=React.memo(CustomInput);


function i18f(m: string) {
    const {t} = i18n;
    return t(m);
}
