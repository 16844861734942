import {useFormContext} from 'react-hook-form';
import i18n from '@app/utils/i18n';
import React, {useEffect, useRef, useState} from 'react';
import {ErrorMessage} from '../ErrorMessage';
import {CustomInputProps} from './types';
// import '../../../../public/common';

export const CustomSelectUser = ({
  type,
  label,
  // handleChange
  dbValue,

  ...props
}: CustomInputProps) => {
  const {
    register,
    formState: {errors}
  } = useFormContext();
  console.log('CustomSelectUser_props=', props);
  console.log('CustomSelectUser_dbValue=', dbValue);
  const error = errors[label]?.message as string | undefined;

  const id = `${label}`;
  const {ref} = register(id);


  let fl2 = i18f(`views.${props.p.prog_id.toLowerCase()}.${label}`);
  let fl = i18f(`views.common.${label}`);
  if (fl2 !== `views.${props.p.prog_id.toLowerCase()}.${label}`) fl = fl2;
  let is_required = false;

  try{
    if (props.validationSchema !== null && props.validationSchema.fields !==null && eval(`props.validationSchema.fields.${label}`) !== undefined) {
      is_required = eval(`props.validationSchema.fields.${label}`).exclusiveTests.required;
    }
  }catch(e){
    console.log(`${label}_no_validationschema___`)
  }


  let defaultValue = '';

  if (dbValue !== undefined && dbValue !== '') {
    console.log('dbValue====', dbValue);
    try {
    
      defaultValue = JSON.parse(dbValue);
    } catch (e) {
      defaultValue =dbValue;
    }
  }

  const [initValue, setInitValue] = useState();
  const [initFocus, setInitFocus] = useState(false);
  const userChange = (e: any, index: number) => {
    rpms.getUser(e.target, JSON.parse(props.userInfo), setUserInfoFunc);
  };

  function setUserInfoFunc(e){
    let up = {
      id: props.id,
      value: {user_id: e.user_id, record_nm: e.record_nm}
    };

    props.handleChange(up);
    setInitValue({user_id: e.user_id, record_nm: e.record_nm});
    ref.current.value = e.record_nm;
  }


  useEffect( () => {
    if(defaultValue !== undefined) {
      ref.current.value = defaultValue.record_nm||'';
      if(!initFocus) ref.current.focus();

console.log("____defaultValue___setFocus____");
    }else{
      ref.current.value = initValue.record_nm||'';
      // if(!initFocus) ref.current.focus();
      console.log("____defaultValue___setFocus222222____");

    }
  });


  useEffect( () => {
    if(!initFocus && dbValue !== undefined) {
      ref.current.focus();
      setInitFocus(true);
    }
  },[initFocus]);


  console.log(
    'initValue====',  initValue,  'defaultValue.record_nm====',  defaultValue.record_nm
  );
  return (
    <div className="form-group">
      <div className="input-group">
        {label && (
          <label className="col-sm-3 col-form-label text-center" htmlFor={id}>
            {fl}
            {is_required && (
                <span className="ml-1" style={{color: 'teal'}}>
                  *
                </span>
            )}
          </label>
        )}
        <div className="col-sm-9 conainter-fluid">
          <div>
            <input
              className="form-control "
              {...register(label, {
                onChange: (e) => {
                  userChange(e);
                }
              })}
              name={label}
              ref={(e) => {
                ref(e);
                ref.current = e; // you can still assign to ref
              }}
              defaultValue={
                defaultValue !== undefined ? defaultValue.record_nm : initValue.record_nm
              }

              readOnly={props.readonly}
              // defaultValue={initValue.user_nm}
            />

            <ErrorMessage error={error} />
          </div>
        </div>
      </div>
      {/*<hr className="bg-gray-400 my-4"/>*/}
    </div>
  );
};

function i18f(m: string) {
  const {t} = i18n;
  return t(m);
}
