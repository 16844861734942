/* eslint-disable */

import React, {
  useImperativeHandle,
  forwardRef,
  useEffect,
  useState
} from 'react';
import {API_BASE_URL} from '../../../constants';
import './jquery-comments.css';

import {toast} from 'react-toastify';

import {
  getComments,
  postComments,
  putComment,
  deleteComment,
  commentsFile,
  upvoteComment
} from '../../../utils/APIUtils';
import {ErrorMessage} from '../ErrorMessage';
import i18n from '@app/utils/i18n';
import {CustomInputProps} from '../inputs/types';
import {useFormContext} from 'react-hook-form';

type commentsType = {
  commentsItem?: Array<commentsType>;
};

let $comments: any;
let commentsId: any;

let userId: any;
let progId: any;
let progNo: any;
let userInfo: any;

// export const CommentsForm = forwardRef((props, ref) => {
export const CommentsForm = ({
  type,
  label,
  // handleChange
  dbValue,
  ...props
}: CustomInputProps) => {
  const {
    register,
    formState: {errors}
  } = useFormContext();
  console.log('CommentsForm_props============', props);
  const cm = props.p;

  userId = props.commentsItem.userInfo.user_id;
  userInfo = props.commentsItem.userInfo;
  progId = props.commentsItem.prog_id;
  progNo = props.commentsItem.prog_no;


  useEffect(() => {
    commentsInit();
  }, [props]);

  function commentsInit() {
    const $ = window.$;

    // $comments.comments({
    $('#comments-container').comments({
      profilePictureURL: '/img/users/nobody_comment.png',
      currentUserId: userId,
      roundProfilePictures: true,
      textareaRows: 1,
      enableEditing: true,
      enableDeleting: true,
      enableUpvoting: true,
      enableAttachments: true,
      enableHashtags: true,
      enablePinging: true,
      fieldMappings: {
        id: 'id',
        parent: 'parent',
        created: 'created',
        modified: 'modified',
        content: 'content',
        file: 'file',
        fileURL: 'file_url',
        fileMimeType: 'file_mime_type',
        pings: 'pings',
        creator: 'creator',
        fullname: 'fullname',
        profileUrl: 'profile_url',
        profilePictureURL: 'profile_picture_url',
        isNew: 'is_new',
        createdByAdmin: 'created_by_admin',
        createdByCurrentUser: 'created_by_current_user',
        upvoteCount: 'upvote_count',
        userHasUpvoted: 'user_has_upvoted'
      },
      getComments: function (success, error) {
        setTimeout(function () {
          let url = `/comments/getComments`;
          getComments(userInfo, progId, progNo)
            .then((commentsArray: any) => {
              success(commentsArray);
            })
            .catch((error: any) => {
              toast.error(error.message || 'getComments fail error');
            });
        }, 500);
      },
      postComment: function (data, success, error) {
        setTimeout(function () {
          var jsonData = JSON.stringify(data);
          postComments(jsonData, progId, progNo, userInfo)
            .then((response: any) => {
              success(response);
            })
            .catch((error: any) => {
              toast.error(error.message || 'postComment fail error');
            });
        }, 500);
      },
      deleteComment: function (data, success, error) {
        // console.log('deleteComments_____________________');
        setTimeout(function () {
          let url = `/comments/deleteComment`;
          deleteComment(data.id, progId, progNo, userInfo)
            .then((commentsArray: any) => {
              success(commentsArray);
            })
            .catch((error: any) => {
              toast.error(error.message || 'Delete fail error');
            });
        }, 500);
      },
      jwhFileClick: function (fileId: string) {
        const url = `${API_BASE_URL}/file/get/downloadFile?svr_file_id=${fileId}`;
        const download = document.createElement('a');

        download.href = url;
        download.setAttribute('download', fileId);
        download.setAttribute('type', 'application/json');
        download.click();
      },
      upvoteComment: function (data: string, success, error) {
        setTimeout(function () {
          let id = data.id;
          let upvote = '';
          if (!data.user_has_upvoted || data.user_has_upvoted == false) {
            upvote = 'true';
          } else {
            upvote = 'false';
          }
          upvoteComment(id, progId, progNo, userInfo, upvote)
            .then((response: any) => {
              success(response);
            })
            .catch((error: any) => {
              toast.error(error.message || 'upvoteComment fail error');
            });
        }, 500);
      },
      uploadAttachments: function (commentArray, success, error) {
        // console.log('uploadAttachments_____________________');
        setTimeout(function () {
          var responses = 0;
          var successfulUploads = [];
          var serverResponded = function () {
            responses++;
            if (responses == commentArray.length) {
              if (successfulUploads.length == 0) {
                error();
              } else {
                success(successfulUploads);
              }
            }
          };

          $(commentArray).each(function (index, commentJSON) {
            var formData = new FormData();
            $(Object.keys(commentJSON)).each(function (index, key) {
              var value = commentJSON[key];
              if (key == 'file') formData.append(key, value);
              if (key == 'file_url') formData.append(key, value);
              formData.append('progId', `${progId}`);
              formData.append('progNo', `${progNo}`);
              formData.append('userInfo', `${JSON.stringify(userInfo)}`);
            });

            commentsFile(commentJSON.id, formData)
              .then((comment: any) => {
                successfulUploads.push(comment);
                serverResponded();
                // console.log('commentsFile_comment====', comment);
                // fileClick(comment);
              })
              .catch((error: any) => {
                serverResponded();
                toast.error(error.message || 'upvoteComment fail error');
              });
          });
        }, 500);
      },

      // putComment is not used in any place
      putComment: function (data, success, error) {
        // console.log('putComments_____________________');
        setTimeout(function () {
          let url =
            `/comments/postComment?progId=${progId}&progNo=${progNo}&id=` +
            data.id;
          putComment(data, url)
            .then((response: any) => {
              success(response);
            })
            .catch((error: any) => {
              toast.error(error.message || 'putComment error');
            });
        }, 500);
      }
    });
  }

  const commentsArray = [];
  const usersArray = [{}];
  // console.log('commentsForm_before_return_______________________________');
  // return (
  //   <div className="row col-md-12">
  //     <div className="col-md-12" id="comments-container" />
  //   </div>
  // );
  const id = `${label}`;

  const fl = i18f(`views.common.${label}`);

  return (
    <div className="form-group">
      <div className="input-group">
        {label && (
          <label className="col-sm-3 col-form-label text-center" htmlFor={id}>
            {fl}
          </label>
        )}
        <div className="col-sm-9 conainter-fluid">
          <div>
            <div className="row col-md-12">
              <div className="col-md-12" id="comments-container" />
            </div>
            {/* <input
              className="form-control"
              {...register(label, {
                onChange: (e) => {
                  props.handleChange(e);
                }
              })}
              {...props}
              id={label}
            />
             */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CommentsForm);

function i18f(m: string) {
  const {t} = i18n;
  return t(m);
}
