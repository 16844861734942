import {useFormContext} from 'react-hook-form';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import Select from 'react-select';
import {getCode} from '@app/utils/APIUtils';
import {toast} from 'react-toastify';
import i18n from '@app/utils/i18n';
import {useSelector} from 'react-redux';
import {ErrorMessage} from '../ErrorMessage';
import {CustomInputProps} from './types';
import './CustomSelectSingle.css';

const Checkbox = ({children, ...props}: JSX.IntrinsicElements['input']) => (
    <label style={{marginRight: '1em'}}>
        <input type="checkbox" {...props} />
        {children}
    </label>
);

export const CustomSelectSingle = ({
                                       name,
                                       // label,
                                       dbValue,

                                       options,
                                       ...props
                                   }: CustomInputProps) => {
    const {
        register,
        formState: {errors}
    } = useFormContext();

    console.log('CustomSelectSingle_props=', props);
    const error = errors[name]?.message as string | undefined;
    // const id = `${name}-${props.type}-${label}`;
    const [isClearable, setIsClearable] = useState(true);
    const [isSearchable, setIsSearchable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRtl, setIsRtl] = useState(false);
    const [selectedValue, setSelectedValue] = useState(props.defaultValue);
    const [selectOptions, setSelectOptions] = useState([]);
    const [selectItems, setSelectItems] = useState([{
        upperCodeCd: '',
        corpCd: '',
        active: '',
        optional: '',
        defaultValue: '',
        isDisabled: ''
    }
    ]);
    // console.log('selectedValue=================', selectedValue);

    useEffect(() => {
        try {
            const eParams = JSON.parse(props.value);
            // console.log('eParams2=', eParams);
            setSelectItems(eParams);
            // $(".react-select-container").removeAttr(".col-sm-9");

            console.log('eParams.upperCodeCd=', eParams.upperCodeCd);
            if (eParams.upperCodeCd !== undefined) {
                getCode(
                    eParams.corpCd,
                    eParams.upperCodeCd,
                    eParams.active === undefined ? 'Y' : eParams.active,
                    eParams.optional === undefined ? 'Y' : eParams.optional
                )
                    .then((response) => {
                        // console.log('Form_getCode_selectSingle=', response);
                        const arr = response.data;
                        setSelectOptions(response.data);
                    })
                    .catch((error) => {
                        toast.error('Error loading sidebar menu!');
                    });
            }
        } catch (exception) {
            console.log('exception___________', exception);
        }
    }, []); // props.handleChange

    const darkMode = useSelector((state: any) => state.ui.darkMode);

    const readonly = props.readonly;
    // console.log('customSelectSingle_readonly=', readonly);
    let bgcolor = '#fff';

    if (darkMode) bgcolor = '#343a40'   //343634
    else if (readonly) bgcolor = '#e9ecef'  //#e9ecef

    const customStyles = useMemo(
        () => ({
            option: (defaultStyles, state) => ({
                ...defaultStyles,
                // border: darkMode ? 'red' : 'blue',
                color: darkMode ? '#a0a0a0' : '#212529',
                // color: darkMode ? '#343a40' : '#212529',

                // opacity: 0.8,
                // background:  bgcolor
            }),

            control: (defaultStyles) => ({
                ...defaultStyles
                , background: bgcolor
                // ,height:'20px' //--> doesn't work
            })
        }),
        []
    );

    // console.log("bgcolor=====================",bgcolor);

    const userChange = (e: any, index: number) => {
        // console.log('e.target====', e.target);
        // console.log('props.userInfo==============', props.userInfo);

        rpms.getUser(e.target, JSON.parse(props.userInfo), setUserInfoFunc);
    };

    function setSelect(e) {
        console.log('e=', e);
        let up = {
            id: props.id,
            value: e.value
        };

        props.handleChange(up);
        // setInitValue({user_id: e.user_id, user_nm: e.user_nm});

        // ref.current.value = e.user_nm;
    }

    //
    // console.log("selectsingle_props============",props);
    // console.log("ltvp============",props.ltvp);

    const lb = props.ltvp.p.prog_id;

    const tmplabel = (lb !== undefined ? lb : '').toLowerCase();

    // const ls = `views.${tmplabel}.${props.label}`;
    // const fl2 = i18f(`views.${tmplabel}.${props.label}`);

    // console.log("ls============",ls);
    const fl2 = i18f(`views.${tmplabel}.${props.label}`);
    let fl = i18f(`views.common.${props.label}`);
    fl = fl2.indexOf(props.label) === -1 ? fl2 : fl;

    return (
        <div className="form-group">
            <div className="input-group">
                {/*
        <div className="col-sm-12 conainter-fluid row">
        */}
                {props.label && (
                    <label className=" col-sm-3 col-form-label text-center" htmlFor={props.label}>
                        {fl}
                    </label>
                )}

                <div className="col-sm-9 conainter-fluid">

                    <Select
                        value={selectOptions.filter(function (option) {
                            return option.value === props.defaultValue;
                        })}
                        className="react-select-container "
                        classNamePrefix="react-select"

                        isDisabled={props.readonly}
                        // readonly={props.readonly}
                        // isDisabled={isDisabled}
                        options={selectOptions}
                        // className="basic-single col-sm-10 conainter-fluid "
                        // classNamePrefix="select"
                        isLoading={isLoading}
                        // isClearable={isClearable}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        onChange={(e) => {
                            setSelectedValue(e.value);
                            setSelect(e);
                        }}
                        ref={props.selectMenuRef}
                        styles={customStyles}
                    />
                    {/*
          <div
            style={{
              color: 'hsl(0, 0%, 40%)',
              display: 'inline-block',
              fontSize: 12,
              fontStyle: 'italic',
              marginTop: '1em'
            }}
          ></div> */}

                    <ErrorMessage error={error}/>

                </div>
            </div>
        </div>


    );
};

function i18f(m: string) {
    const {t} = i18n;
    return t(m);
}
