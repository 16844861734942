/* eslint-disable react/react-in-jsx-scope */
import {
  Gantt,
  Task,
  EventOption,
  StylingOption,
  ViewMode,
  DisplayOption
} from 'gantt-task-react';
import 'gantt-task-react/dist/index.css';
// import './index.css';
import {useState, useEffect} from 'react';
import {useFormState} from 'react-hook-form';
import {toast} from 'react-toastify';
import {updateTaskSchedules} from '@app/utils/APIUtils';
import styles from './tooltip.module.css';
import {getStartEndDateForProject, initTasks} from './helper';
import {ViewSwitcher} from './view-switcher';
// import {Tooltip} from './tooltip.tsx.bk';

export default function GanttGroup(props) {
  // console.log('GanttGroup###########', props);
  const [view, setView] = useState<ViewMode>(ViewMode.Day);
  // const [tasks, setTasks] = useState<Task[]>(initTasks(props));

  const tmpTasks = initTasks(props);
  const [tasks, setTasks] = useState(tmpTasks);

  const [isChecked, setIsChecked] = useState(true);
  let columnWidth = 65;
  if (view === ViewMode.Year) {
    columnWidth = 350;
  } else if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  // console.log('tmpTasks==', tmpTasks);
  useEffect(() => {
    console.log('__________setTasks_____________',tmpTasks);
    try {
      setTasks(tmpTasks);
    }catch(e){
      console.log('setTasks_error____________',e);
    }
    // handleTaskChange(tmpTasks);
  }, []);
  // });

  const handleTaskChange = (task: Task) => {
    console.log('On date change Id:' + task.id);
    console.log('tasks:' ,tasks,'tmpTasks=',tmpTasks);
    let newTasks = tmpTasks.map((t) => (t.id === task.id ? task : t));
    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);
      const project =
        newTasks[newTasks.findIndex((t) => t.id === task.project)];
      if (
        project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
      ) {
        const changedProject = {...project, start, end};
        newTasks = newTasks.map((t) =>
          t.id === task.project ? changedProject : t
        );
      }
    }

     // console.log('newTasks:', newTasks);
    try {
      setTasks(newTasks);
    }catch(e){
      console.log('setTasks_error2____________',e);
    }
    // setTasks(newTasks);

    updateTaskSchedules(newTasks, 'updateParams')
      .then((response) => {
        const nt = response.rows;

        // console.log('response:', response);
        props.handleChange({id: 'hide', value: nt});
      })
      .catch((error) => {
        toast.error('error in saving project task schedules...');
      });
  };

  const handleTaskDelete = (task: Task) => {
    const conf = window.confirm('Are you sure about ' + task.name + ' ?');
    if (conf) {
      setTasks(tasks.filter((t) => t.id !== task.id));
    }
    return conf;
  };

  const handleProgressChange = async (task: Task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    //console.log('On progress change Id:' + task.id);
  };

  const handleDblClick = (task: Task) => {
    alert('On Double Click event Id:' + task.id);
  };

  const handleClick = (task: Task) => {
    //console.log('On Click event Id:' + task.id);
  };

  const handleSelect = (task: Task, isSelected: boolean) => {
    //console.log(task.name + ' has ' + (isSelected ? 'selected' : 'unselected'));
  };

  const handleExpanderClick = (task: Task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    //console.log('On expander click Id:' + task.id);
  };

  return (
    <div className="Wrapper">
      <ViewSwitcher
        onViewModeChange={(viewMode) => {
          setView(viewMode);
        }}
        onViewListChange={setIsChecked}
        isChecked={isChecked}
      />
      {/* {tasks && ( */}
      <Gantt
        tasks={tmpTasks}
        viewMode={view}
        onDateChange={handleTaskChange}
        onDelete={handleTaskDelete}
        onProgressChange={handleProgressChange}
        onDoubleClick={handleDblClick}
        onClick={handleClick}
        onSelect={handleSelect}
        onExpanderClick={handleExpanderClick}
        // listCellWidth={isChecked ? '155px' : ''}
        listCellWidth={isChecked ? '200px' : ''}
        ganttHeight={600}
        rowHeight={28.5}
        columnWidth={columnWidth}
        TooltipContent={JwhTooltipContent}
      />
      {/* )} */}
    </div>
  );
}

export const JwhTooltipContent: React.FC<{
  task: Task;
  fontSize: string;
  fontFamily: string;
}> = ({task, fontSize, fontFamily}) => {
  const style = {
    fontSize,
    fontFamily
  };
  //console.log('JwhTooltipContent=', task);
  return (
    <div className={styles.tooltipDefaultContainer} style={style}>
      <b style={{fontSize: fontSize + 6}}>{`${
        task.name
      }: ${task.start.getDate()}-${
        task.start.getMonth() + 1
      }-${task.start.getFullYear()} ~ ${task.end.getDate()}-${
        task.end.getMonth() + 1
      }-${task.end.getFullYear()}`}</b>

      <p
        className={styles.tooltipDefaultContainerParagraph}
      >{`Task Code: ${task.id} `}</p>

      {task.end.getTime() - task.start.getTime() !== 0 && (
        <p
          className={styles.tooltipDefaultContainerParagraph}
        >{`Durations: ${~~(
          (task.end.getTime() - task.start.getTime()) /
          (1000 * 60 * 60 * 24)
        )} day(s)`}</p>
      )}

      <p className={styles.tooltipDefaultContainerParagraph}>
        {!!task.progress && `Progress: ${task.progress} %`}
      </p>
    </div>
  );
};
