import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useSelector} from 'react-redux';

const PublicRoute = () => {
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const user = useSelector((state: any) => state.auth.currentUser);
  const isAuthenticated = isLoggedIn || window.localStorage.getItem('accessToken');
  console.log("Public_isLoggedIn==",isLoggedIn);
  console.log("Public_isAuthenticated==",isAuthenticated);
console.log("Public_user==",user === null);
  console.log("Public_user4==",JSON.stringify(user) ==='{}');
  return isAuthenticated && JSON.stringify(user) !== '{}' ? <Navigate to="/" /> : <Outlet />;
};

export default PublicRoute;
