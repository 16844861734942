/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, {useEffect, useState} from 'react';
import {NavLink, useNavigate, useLocation, Location} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {IMenuItem} from '@app/modules/main/menu-sidebar/MenuSidebar';
import {equal} from 'assert';
// import i18n from '@app/utils/i18n';

export const FontAwesomeIcon = (item: any) => {
  const name = JSON.stringify(item);

  if (name.includes('ProjectManagement')) {
    return <i className="nav-icon fas fa-flag-checkered" />;
  }
  if (name.includes('WorkManagement')) {
    return <i className="nav-icon fas fa-calendar-check" />;
  }
  if (name.includes('KnowledgeManagement')) {
    return <i className="nav-icon fas fa-graduation-cap" />;
  }
  if (name.includes('Analysis/Statistic')) {
    return <i className="nav-icon fas fa-solid fa-chart-line" />;
  }
  if (name.includes('SystemManagement')) {
    return <i className="nav-icon fas fa-cog" />;
  }
  return <i className="nav-icon far fa-circle fa-1x " />;
};

const ExpandableMenuItem = ({menuItem}: {menuItem: IMenuItem}) => {
  const [t] = useTranslation();
  const [isMenuExtended, setIsMenuExtended] = useState(false);
  const [isExpandable, setIsExpandable] = useState(false);
  const [isMainActive, setIsMainActive] = useState(false);
  const [isOneOfChildrenActive, setIsOneOfChildrenActive] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuExtended(!isMenuExtended);
  };

  const handleMainMenuAction = () => {
    if (isExpandable) {
      toggleMenu();
      return;
    }
    navigate(
      menuItem.path ? menuItem.path : '/',

      {
        // state: i18n.t(menuItem.name)
        state: menuItem.name
      }
    );
  };

  const calculateIsActive = (url: Location) => {
    setIsMainActive(false);
    setIsOneOfChildrenActive(false);
    if (isExpandable && menuItem && menuItem.children) {
      menuItem.children.forEach((item) => {
        if (item.path === url.pathname) {
          setIsOneOfChildrenActive(true);
          setIsMenuExtended(true);
        }
      });
    } else if (menuItem.path === url.pathname) {
      setIsMainActive(true);
    }
  };

  return (
    <ul className="nav nav-treeview">
      <MenuItem key={menuItem.name + menuItem.path} menuItem={menuItem} />
    </ul>
  );
};

// const SingleMenuItem = ({menuItem}: {menuItem: IMenuItem}) => {
//   return (
//     <NavLink className="nav-link" to={`${menuItem.path}`}>
//       <i className="far fa-circle nav-icon" />
//       <p>{menuItem.name}</p>
//     </NavLink>
//   );
// };

const MenuItem = ({menuItem}: {menuItem: IMenuItem}) => {
  const [t] = useTranslation();
  const [isMenuExtended, setIsMenuExtended] = useState(false);
  const [isExpandable, setIsExpandable] = useState(false);
  const [isMainActive, setIsMainActive] = useState(false);
  const [isOneOfChildrenActive, setIsOneOfChildrenActive] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuExtended(!isMenuExtended);
  };

  const handleMainMenuAction = () => {
    if (isExpandable) {
      toggleMenu();
      return;
    }
    navigate(menuItem.path ? menuItem.path : '/', {
      state: {name: menuItem.name, params: {}}
    });
  };

  const calculateIsActive = (url: Location) => {
    setIsMainActive(false);
    setIsOneOfChildrenActive(false);
    if (isExpandable && menuItem && menuItem.children) {
      menuItem.children.forEach((item) => {
        if (item.path === url.pathname) {
          setIsOneOfChildrenActive(true);
          setIsMenuExtended(true);
        }
      });
    } else if (menuItem.path === url.pathname) {
      setIsMainActive(true);
    }
  };

  useEffect(() => {
    if (location) {
      calculateIsActive(location);
    }
  }, [location, isExpandable, menuItem]);

  useEffect(() => {
    if (!isMainActive && !isOneOfChildrenActive) {
      setIsMenuExtended(false);
    }
  }, [isMainActive, isOneOfChildrenActive]);

  useEffect(() => {
    setIsExpandable(
      Boolean(menuItem && menuItem.children && menuItem.children.length > 0)
    );
  }, [menuItem]);

  return (
    <li className={`nav-item${isMenuExtended ? ' menu-open' : ''}`}>
      <a
        className={`nav-link${
          isMainActive || isOneOfChildrenActive ? ' active' : ''
        }`}
        role="link"
        onClick={handleMainMenuAction}
        style={{cursor: 'pointer'}}
      >
        <FontAwesomeIcon items={menuItem.name} />
        <p>{t<string>(`menusidebar.label.${menuItem.name}`)}</p>
        {isExpandable ? <i className="right fas fa-angle-left" /> : null}
      </a>

      {isExpandable &&
        menuItem &&
        menuItem.children &&
        menuItem.children.map((item) => {
          const menu = [];
          if (
            Array.isArray(menuItem.children) &&
            menuItem.children.length > 0
          ) {
            menu.push(
              <ExpandableMenuItem key={item.name + item.path} menuItem={item} />
            );
            // } else {
            //   menu.push(
            //     <SingleMenuItem key={item.name + item.path} menuItem={item} />
            //   );
          }
          return menu.concat();
        })}
    </li>
  ); // return
};

export default MenuItem;
