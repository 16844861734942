import React from 'react';
// import 'gantt-task-react/dist/index.css';
import './index.css';
import {ViewMode} from 'gantt-task-react';

type ViewSwitcherProps = {
  isChecked: boolean;
  onViewListChange: (isChecked: boolean) => void;
  onViewModeChange: (viewMode: ViewMode) => void;
};
export const ViewSwitcher: React.FC<ViewSwitcherProps> = ({
  onViewModeChange,
  onViewListChange,
  isChecked
}) => {
  return (
    <div className="ViewContainer">
      <div className="Button" onClick={() => onViewModeChange(ViewMode.Hour)}>
        Hour
      </div>
      {/*       
      <div
        className="Button"
        onClick={() => onViewModeChange(ViewMode.QuarterDay)}
      >
        Quarter of Day
      </div>
        
      <div
        className="Button"
        onClick={() => onViewModeChange(ViewMode.HalfDay)}
      >
        Half of Day
      </div>
       */}
      <div className="Button" onClick={() => onViewModeChange(ViewMode.Day)}>
        Day
      </div>
      <div className="Button" onClick={() => onViewModeChange(ViewMode.Week)}>
        Week
      </div>
      <div className="Button" onClick={() => onViewModeChange(ViewMode.Month)}>
        Month
      </div>
      <div className="Button" onClick={() => onViewModeChange(ViewMode.Year)}>
        Year
      </div>
      {/* <div
        className="Button"
        onClick={() => onViewModeChange(ViewMode.QuarterYear)}
      >
        Q Year
      </div> */}
      <div className="Switch">
        <label className="Switch_Toggle">
          <input
            type="checkbox"
            defaultChecked={isChecked}
            onClick={() => onViewListChange(!isChecked)}
          />
          <span className="Slider" />
        </label>
        Show Task List
      </div>
    </div>
  );
};
