import React, {useEffect} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import {ToastContainer} from 'react-toastify';
import Main from '@modules/main/Main';
import Login from '@modules/login/Login';
import Register from '@modules/register/Register';
import ForgetPassword from '@modules/forgot-password/ForgotPassword';
import RecoverPassword from '@modules/recover-password/RecoverPassword';
import {useWindowSize} from '@app/hooks/useWindowSize';
import {calculateWindowSize} from '@app/utils/helpers';
import {useDispatch, useSelector} from 'react-redux';
import {setWindowSize} from '@app/store/reducers/ui';

import Dashboard from '@pages/Dashboard';
import Blank from '@pages/Blank';
import Profile from '@pages/profile/Profile';
import Cmpc100i from '@pages/cm/pc/Cmpc100i';
import Cmpc200i from '@pages/cm/pc/Cmpc200i';
import Cmpc000i from '@pages/cm/pc/Cmpc000i';

import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import DyPage from './pages/pj/rg/DyPage';
import {useScript} from 'usehooks-ts';
import DMain from "@pages/ma/in/DMain";
import WorkSpace from "@modules/workspace/WorkSpace";

const App = () => {

    console.log("window.location.hostname==", window.location.hostname);
    const windowSize = useWindowSize();
    const screenSize = useSelector((state: any) => state.ui.screenSize);
    const dispatch = useDispatch();

    const user = useSelector((state: any) => state.auth.currentUser);
    const status = useScript(
        '/common.js',
        {
            removeOnUnmount: false,
        }
    );

    useEffect(() => {
        const size = calculateWindowSize(windowSize.width);
        if (screenSize !== size) {
            dispatch(setWindowSize(size));
        }
    }, [windowSize]);

    if (status === 'ready') {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/login" element={<PublicRoute/>}>
                        <Route path="/login" element={<Login/>}/>
                    </Route>
                    <Route path="/register" element={<PublicRoute/>}>
                        <Route path="/register" element={<Register/>}/>
                    </Route>
                    <Route path="/forgot-password" element={<PublicRoute/>}>
                        <Route path="/forgot-password" element={<ForgetPassword/>}/>
                    </Route>
                    <Route path="/recover-password" element={<PublicRoute/>}>
                        <Route path="/recover-password" element={<RecoverPassword/>}/>
                    </Route>
                    <Route path="/workspace" element={<PublicRoute/>}>
                        <Route path="/workspace" element={<WorkSpace/>}/>
                    </Route>

                    <Route path="/" element={<PrivateRoute/>}>
                        <Route path="/" element={< Main/>}>
                            <Route path="/" element={<DMain/>}/>
                            <Route path="/PJ/RG/:id" element={<DyPage/>}/>
                            <Route path="/CM/CD/:id" element={<DyPage/>}/>
                            <Route path="/CO/AP/:id" element={<DyPage/>}/>
                            <Route path="/CO/RP/:id" element={<DyPage/>}/>
                            <Route path="/CO/IS/:id" element={<DyPage/>}/>
                            <Route path="/CM/PJ/:id" element={<DyPage/>}/>
                            <Route path="/CM/PC/CMPC100I" element={<Cmpc100i/>}/>
                            <Route path="/CM/PC/CMPC200I" element={<Cmpc200i/>}/>
                            <Route path="/CM/PC/CMPC000I" element={<Cmpc000i/>}/>
                            <Route path="/CM/PC/:id" element={<DyPage/>}/>
                            <Route path="/CM/UC/:id" element={<DyPage/>}/>
                            <Route path="/CR/MG/:id" element={<DyPage/>}/>
                        </Route>

                    </Route>
                </Routes>
                <ToastContainer
                    autoClose={3000}
                    draggable={false}
                    position="top-right"
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnHover
                />
            </BrowserRouter>
        )
    }
    ;
};

export default App;
