import React, {useRef, useState, useEffect} from 'react';

import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {PfButton} from '@profabric/react-components';

import i18n from '@app/utils/i18n';
import StockChipGroup from '@app/pages/common/addStock/stockChipGroup/StockChipGroup';
import BasicSingle from '@app/pages/common/selectBox/BasicSingle';
import {getCode} from '@app/utils/APIUtils';
import Multi from '@app/pages/common/selectBox/Multi';
import SelectUser from '@app/pages/common/selectUser/SelectUser';
import MyDropzone from '@app/pages/common/dropzone/MyDropzone';
import Comments from '@app/pages/common/comment/Comments';
// import DatePicker from 'react-datepicker';
import {Controller, useForm} from 'react-hook-form';
import {ErrorMessage, useField} from 'formik';
import JqGrid, {jqItems} from '../../common/jqgrid/JqGrid';
// import 'react-datepicker/dist/react-datepicker.css';
import GanttGroup from '@app/pages/common/gantt_task/GanttGroup';
import { API_BASE_URL } from '@app/constants';
import DyPageInc from '@pages/pj/rg/DyPageInc';
import FullCalendar from '@fullcalendar/react';
import FullCalendarForm from '@app/pages/common/fullcalendar/FullCalendarForm';
import { CustomRadioGroup } from '@app/pages/common/inputs/CustomRadioGroup';
import Editor from "@pages/common/ckeditor/Editor";
import {JqGridForm} from "@pages/common/jqgrid/JqGridForm";
import {JqGridNew} from "@pages/common/jqgrid/JqGridNew";
// import '../../../../public/common';

interface Holding {
  name: string;
  code: string;
  // weight: number;
}

interface StockListLayoutProp {
  holdings: Holding[];
  onStockChange(name: string, value: number): void;
  onDelete(name: string): void;
  onAdd(name: string, code: string): void;
}

export default function InputReal({
  objValue,
  handleChange,
  index,
  deleteField,
  dbValue
}) {
  // console.log('dbValue=============', dbValue);
  const [halfWidth, SetHalfWidth] = useState(false);
  // const {
  //   register,
  //   watch,
  //   formState: {errors}
  // } = useForm();
  // console.log('watch_________________________________', watch());

  const textareaStyle = {
    boder: '1px solid black',
    resize: 'vertical'
  };
  const gridRef = useRef();

  const commentsRef = useRef();

  // const dateRef = useRef();

  const {label, type, value} = objValue;


  // const eltvp = eval(objValue);

  const pageWidth = 370;

  const {state} = useLocation();
  const user = useSelector((state: any) => state.auth.currentUser);

  const testHoldings = [{name: user.dept_nm, code: user.dept_cd}];
  const [holdings, setHoldings] = React.useState<Holding[]>(testHoldings);
  const [postParams, setPostParams] = useState({
    userInfo: user
  });

  const location = useLocation();
  const pageName = location.pathname.split('/').reverse()[0];
  const projId = state.params.proj_id;
  // console.log('state=', state, 'projId====', projId);
  // console.log('pageName_____', pageName);
  const [commentsItem, setCommentsItem] = useState([
    {
      // commentId: 'comments1',
      prog_id: pageName,
      prog_no: projId,
      ...postParams
    }
  ]);

  const onStockChange = (name: string, value: number) => {
    console.log('_____________onStockChange________________');
    setHoldings((holdings) =>
      holdings.map((holding) =>
        holding.name !== name
          ? holding
          : {name: holding.name, code: holding.code, weight: value}
      )
    );
  };

  useEffect(() => {
    // console.log('holdings________________', holdings, objValue);
    if (objValue.type === 'deptMulti') {
      handleChange({id: objValue.label, value: holdings});
    }
  }, [holdings]);

  const onAdd = (name: string, code: string) => {
    if (
      holdings.find((item: Holding) => item.name === name || item.code === code)
    )
      return;
    setHoldings((holdings) => holdings.concat({name, code}));
  };

  const onDelete = (name: string) => {
    setHoldings((holdings) =>
      holdings.filter((holding) => holding.name !== name)
    );
  };

  const [commonModuleOption, SetCommonModuleOption] = useState({
    colModel: [
      {
        name: 'column1',
        width: pageWidth * (30 / 100),
        align: 'center',
        hidden: false
      }
    ]
  });

  const [jqItems, SetJqItems] = useState([
    {
      // gridId: `grid${index}`,
      order: 30,
      gridId: `grid${index}`,
      // url: null,
      width: pageWidth,
      insertRow: true,
      shrinkToFit: false,
      toppager: false,
      search: false,
      download: false,
      reorder: false,
      pager: false,
      postParams: null,
      ...commonModuleOption
    }
  ]);

  const [selectItems, setSelectItems] = useState([
    {
      upperCodeCd: '',
      corpCd: '',
      active: '',
      optional: '',
      defaultValue: '',
      isDisabled: ''
    }
  ]);

  const [selectOptions, setSelectOptions] = useState([
    {value: 'chocolate', label: 'Chocolate'},
    {value: 'strawberry', label: 'Strawberry'},
    {value: 'vanilla', label: 'Vanilla'}
  ]);

  const [userInfo, setUserInfo] = useState({user_id: '', record_nm: ''});

  function setParams(ov: object, type: string) {
    //  console.log('setParams_type=============', type);
    if (type === 'selectSingle' || type === 'selectMulti') {
      const eParams = JSON.parse(ov.value);
      setSelectItems(eParams);

      getCode(
        selectItems.corpCd,
        selectItems.upperCodeCd,
        selectItems.active === undefined ? 'Y' : eParams.active,
        selectItems.optional === undefined ? 'Y' : eParams.optional
      )
        .then((response) => {
          const arr = response.data;
          setSelectOptions(response.data);
        })
        .catch((error) => {
          if (error.status === 401) {
            console.error('Error loading sidebar menu!');
          }
        });
    } else if (type === 'grid') {
      //  console.log(' ov.value #################################', ov.value);
      const eParams = eval('('+ov.value.value+')');
      if (eParams !== undefined) {
        console.log('InputReal_grid#########################', eParams);
        SetJqItems(eParams);
      }
    } else if (type === 'text') {
      // SetHalfWidth(eval(ov.value));
    } else if (type === 'user') {
      setUserInfo(ov.value);
    } else if (type === 'file') {
      console.log('=======file_set======');
    }
  }

  useEffect(() => {
    try {
    //  console.log('InputReal=====', objValue, 'type=====', type);

      setParams(objValue, type);
    } catch (exception) {
      console.log('exception___________', exception);
    }
  }, [handleChange]);

  const [formDate, setFormDate] = useState(
    dbValue ? dbValue[`${label}`] : ''
  );

  // const {errors} = formState;
  // const Error = ({children}) => <p style={{color: 'red'}}>{children}</p>;

  const errorMessage = {
    color: 'red',
    position: 'absolute',
    fontSize: '11px'
  };

  const getPropsReal = (label: any, type: any, value: any) => ({
    button: (
      <>
        <div className="text-right mr-2 mb-2 mt-2">
            <button className="btn btn-outline-primary btn-sm ml-1" type="submit" >
              {/* {labelButtonSubmit} */}
             Submit
             </button>
        </div>
        
      </>

    ),    
    user: (
      <>
        <SelectUser
          key={label}
          className={`form-control ${halfWidth ? 'col-md-6' : ''}`}
          type="text"
          placeholder="value"
          id={label}
          name={label}
          handleChange={handleChange}
          userInfo={objValue.value}
          defaultValue={dbValue ? dbValue[`${label}`] : ''}
        />
        {/* <ErrorMessage component="div" name={label} style={errorMessage} /> */}
      </>
    ),
    text: (
      <>
        <input
          key={label}
          className={`form-control ${halfWidth ? 'col-md-6' : ''}`}
          type="text"
          id={label}
          name={label}
          placeholder="value"
          onChange={handleChange}
          defaultValue={dbValue ? dbValue[`${label}`] : ''}
          // {...register(`${label}`, {required: true})}
          // {...register(`${label}`, {
          //   required: 'id is required',
          //   validate: (value) =>
          //     value.includes('nico') ? 'no nico allowed' : true
          // })}

          // {...register(`${label}`, {
          //   required: '필수 응답 항목입니다.',
          //   minLength: {
          //     value: 8,
          //     message: '비밀번호는 8자 이상이여야 합니다,'
          //   }
          // })}
        />

        {/* <ErrorMessage component="div" name={label} style={errorMessage} /> */}
      </>
    ),
    hidden: (
      <input
        key={label}
        className={`form-control ${halfWidth ? 'col-md-6' : ''}`}
        type="hidden"
        id={label}
        placeholder="value"
        // onChange={handleChange}
        defaultValue={dbValue ? dbValue[`${label}`] : ''}
        // defaultValue={dbValue[`${label}`]}
      />
    )
    ,approval: (
      <textarea
        key={label}
        className="form-control"
        // type="textarea"
        id={label}
        // value={value || ''}
        placeholder="value"
        onChange={handleChange}
        defaultValue={dbValue ? dbValue[`${label}`] : ''}
      />
    )
    ,editor: (
        <Editor
            key={label}
            id={label}
            handleChange={handleChange}
            // label={label}

            defaultValue={dbValue ? dbValue[`${label}`] : ''}
        />
    )
    ,textarea: (
      <textarea
        key={label}
        className="form-control"
        // type="textarea"
        id={label}
        // value={value || ''}
        placeholder="value"
        onChange={handleChange}
        defaultValue={dbValue ? dbValue[`${label}`] : ''}
      />
    ),
    date: (
      <input
        className="form-control"
        key={label}
        type="date"
        id={label}
        required
        onChange={handleChange}
        defaultValue={formDate}
      />

    ),
    number: (
      <input
        className="form-control"
        key={label}
        type="number"
        id={label}
        placeholder="value"
        onChange={handleChange}
        defaultValue={dbValue ? dbValue[`${label}`] : ''}
      />
    ),
    selectSingle: (
      <BasicSingle
        key={label}
        options={selectOptions}
        id={label}
        // value={value || ''}
        placeholder="value"
        handleChange={handleChange}
        defaultValue={dbValue ? dbValue[`${label}`] : ''}
      />
    ),
    selectMulti: (
      <Multi
        key={label}
        options={selectOptions}
        id={label}
        value={value || ''}
        placeholder="value"
        handleChange={handleChange}
        // onChange={(e) => onChangeForm(e, index)}
      />
    ),
    grid: (
      <JqGrid
        key={label}
        jqItems={jqItems}
        gridLabel={label}
        handleChange={handleChange}

        // p={eltvp.p}
        ref={gridRef}
        defaultValue={dbValue ? dbValue[`${label}`] : ''}
      />
    ),
    BasicSingle: (
      <BasicSingle 
       
       options={selectOptions} 
       key={label}
       
       defaultValue="strawberry" />
    ),
    // deptMulti: (
    //   <StockChipGroup
    //     key={label}
    //     stockList={holdings}
    //     // onChange={onStockChange}
    //     handleChange={handleChange}
    //     onDelete={onDelete}
    //     onAdd={onAdd}
    //   />
    // ),
    taskGantt: (
      <GanttGroup
        className="form-control"
        key={label}
        type="text"
        id={label}
        // value={value || ''}
        tasks=""
        placeholder="value"
        onChange={(e) => onChange(e, index)}
      />
    ),
    file: (
      <MyDropzone
        id={label}
        key={label}
        handleChange={handleChange}
        defaultValue={dbValue ? dbValue[`${label}`] : ''}
      />
    ),
    comment: 
    <Comments commentsItem={commentsItem} 
    key={label}

    ref={commentsRef} />
    ,
    includePage: (
        <DyPageInc
            key={label}
            type="text"
            id={label}
            placeholder="value"
            onChange={(e) => onChange(e, index)}
        />
    ),
    calendar: (
        <FullCalendarForm
            key={label}
            type="text"
            id={label}
            placeholder="value"
            onChange={(e) => onChange(e, index)}
        />
    ),
    radio: (
        <input
          className="form-control"
          key={label}
          type="radio"
          id={label}
          // value={value || ''}
          placeholder="value"
          onChange={handleChange}
          defaultValue={dbValue ? dbValue[`${label}`] : ''}
      />
    )
    ,cardProps: (
        <input
            className="form-control"
            key={label}
            type="text"
            id={label}
            // value={value || ''}
            placeholder="value"
            onChange={handleChange}
            defaultValue={dbValue ? dbValue[`${label}`] : ''}
        />
    )
    ,default:
        <input
            className="form-control"
            key={label}
            type="text"
            id={label}
            // value={value || ''}
            placeholder="value"
            onChange={handleChange}
            defaultValue={dbValue ? dbValue[`${label}`] : ''}
        />

  });

  const fl = i18f(`views.common.${label}`);

  // function taskCdCheck(elem) {
  //   $(elem).keyup(function () {
  //     const regExp = '[^-.0-9]';
  //     const val1 = elem.value;
  //     // const num = new Number(val1);
  //     //									if(isNaN(num))	{toastr.error("Please enter only number.");element.value="";}
  //     if (val1.match(regExp)) {
  //       toast.error('Only number and dot can be typed<br> ex. 1.2.1 ');
  //       elem.value = '';
  //     }
  //   });
  // }

  return (
    <div className="form-group">
      <div className="input-group">
        {label === 'hide' || type === 'hidden' ? (
          <div className="col-sm-12 conainter-fluid">
            {getPropsReal(label, type, value)[type]}
          </div>
        ) : (
          <>
            <label htmlFor={label} className="col-sm-2 col-form-label">
              {fl}
            </label>
            <div className="col-sm-10 conainter-fluid">
              {getPropsReal(label, type, value)[type]}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export function i18f(m: string) {
  const {t} = i18n;
  return t(m);
}

 