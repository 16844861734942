import React, {useEffect, useMemo, useRef, useState} from 'react';

import Select from 'react-select';
import {useSelector} from "react-redux";

const Checkbox = ({children, ...props}: JSX.IntrinsicElements['input']) => (
  <label style={{marginRight: '1em'}}>
    <input type="checkbox" {...props} />
    {children}
  </label>
);

export default (props) => {
  console.log('BasicSingle_props==', props);
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const [selectedValue, setSelectedValue] = useState(props.defaultValue);

    const darkMode = useSelector((state: any) => state.ui.darkMode);

    const readonly = props.readonly;
    // console.log('customSelectSingle_readonly=', readonly);
    let bgcolor = '#fff';

    if (darkMode) bgcolor = '#343a40'   //343634
    else if (readonly) bgcolor = '#e9ecef'  //#e9ecef

    const customStyles = useMemo(
        () => ({
            option: (defaultStyles, state) => ({
                ...defaultStyles,
                // border: darkMode ? 'red' : 'blue',
                color: darkMode ? '#a0a0a0' : '#212529',
                // color: darkMode ? '#343a40' : '#212529',

                // opacity: 0.8,
                // background:  bgcolor
            }),

            control: (defaultStyles) => ({
                ...defaultStyles
                , background: bgcolor
                // ,height:'20px' //--> doesn't work
            })
        }),
        []
    );

  return (
    <>
      <Select
        value={props.options.filter(function (option) {
          return option.value === selectedValue;
        })}
        isDisabled={isDisabled}
        options={props.options}
        // className="basic-single"
        className="react-select-container "
        classNamePrefix="select"
        isLoading={isLoading}
        // isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        onChange={(e) => {
          setSelectedValue(e.value);
          props.handleChange(e);
        }}
        ref={props.selectMenuRef}
        styles={customStyles}
      />

      <div
        style={{
          color: 'hsl(0, 0%, 40%)',
          display: 'inline-block',
          fontSize: 12,
          fontStyle: 'italic',
          marginTop: '1em'
        }}
      >
        {/* 
        <Checkbox
          checked={isClearable}
          onChange={() => setIsClearable((state) => !state)}
        >
          Clearable
        </Checkbox>
        <Checkbox
          checked={isSearchable}
          onChange={() => setIsSearchable((state) => !state)}
        >
          Searchable
        </Checkbox>
        <Checkbox
          checked={isDisabled}
          onChange={() => setIsDisabled((state) => !state)}
        >
          Disabled
        </Checkbox>
        <Checkbox
          checked={isLoading}
          onChange={() => setIsLoading((state) => !state)}
        >
          Loading
        </Checkbox>
        <Checkbox checked={isRtl} onChange={() => setIsRtl((state) => !state)}>
          RTL
        </Checkbox>
         */}
      </div>
    </>
  );
};
